<template>
  <div :ref="modalId" class="modal fade" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header text-bold text-center">
          提示
        </div>
        <div class="modal-body row text-break">
          <i class="col-4 fas fa-exclamation fa-5x align-self-center text-center"></i>
          <div id="modalHintContent" class="col-8 align-self-center text-bold text-secondary">
            <div>{{data.title}}
              <div>{{data.orderId}}</div>
            </div>
            <div>
              {{data.description}}
            </div>
          </div>
        </div>
        <div class="modal-footer border-0">
          <button type="button" class="btn btn-info" data-dismiss="modal">取消</button>
          <button type="button" class="btn btn-primary" id="modalHintConfrimed" @click="getPaymentPage()">確定</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import ModalList from "@/const/modal";
import { getAotterPayPaymentPageUrl } from "@/service/api/internal/me/transaction";

export default {
  name: "TransactionPendingModal",
  data(){
    return {
      modalId: ModalList.TRANSACTION_PENDING_MODAL,
      data: {
        title:'',
        orderId:'',
        description:''
      }
    }
  },
  methods: {
    show(data){
      data = data ? data : {}
      this.data.title = data.title ? data.title : ''
      this.data.orderId = data.orderId ? data.orderId : ''
      this.data.description = data.description ? data.description : ''
      $(this.$refs[this.modalId]).modal('show')
    },
    async getPaymentPage(){
      await getAotterPayPaymentPageUrl(this.data.orderId)
          .then(redirectVO =>{
            let url = redirectVO.redirect
            document.location.href = url
          })
    }
  }
}
</script>

<style scoped>

</style>
