<template>
  <div :ref=modalId class="modal fade">
    <div class="modal-dialog modal-confirm">
      <div class="modal-content">
        <div class="border-0 d-flex justify-content-center modal-header">
          <div class="">
            <i class="fa fa-10x fa-check-circle text-success"></i>
          </div>
        </div>
        <div class="border-0 modal-body">
          <div id="modal-check-title" class="modal-title text-center">{{title}}</div>
          <p id="modal-check-content" class="text-bold text-center">{{ content }}<br>{{url}}</p>
        </div>
        <div class="border-0 modal-footer">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import ModalList from "@/const/modal"

export default {
  name: "CheckModal",
  data(){
    return {
      modalId: ModalList.CHECK_MODAL,
      title: '',
      content: '',
      url: '',
    }
  },
  methods: {
    show(data){
      this.url = data && data.url ? data.url : ''
      this.title = data && data.title ? data.title : ''
      this.content = data && data.content ? data.content : ''
      $(this.$refs[this.modalId]).modal('show')

      window.setTimeout(()=>{
        $(this.$refs[this.modalId]).modal('hide')
      },1000)
    },
  }
}
</script>

<style scoped>

</style>
