<template>
  <div>
    <check-modal :ref="ModalList().CHECK_MODAL"/>
    <default-cover-modal :ref="ModalList().DEFAULT_COVER_MODAL"/>
    <croppie-modal :ref="ModalList().CROPPIE_MODAL"/>
    <cropper-modal :ref="ModalList().CROPPER_MODAL"/>
    <promotion-modal :ref="ModalList().PROMOTION_MODAL"/>
    <promotion-reject-modal :ref="ModalList().PROMOTION_REJECT_MODAL"/>
    <promotion-approve-modal :ref="ModalList().PROMOTION_APPROVE_MODAL"/>
    <promotion-update-confirm-modal :ref="ModalList().PROMOTION_UPDATE_CONFIRM_MODAL"/>
    <preview-modal :ref="ModalList().PREVIEW_MODAL"/>
    <loading-modal :ref="ModalList().LOADING_MODAL"/>
    <success-modal :ref="ModalList().SUCCESS_MODAL"/>
    <fail-modal :ref="ModalList().FAIL_MODAL"/>
    <post-delete-modal :ref="ModalList().POST_DELETE_MODAL"/>
    <transaction-result-modal :ref="ModalList().TRANSACTION_RESULT_MODAL"/>
    <transaction-pending-modal :ref="ModalList().TRANSACTION_PENDING_MODAL"/>
    <client-create-modal :ref="ModalList().CLIENT_CREATE_MODAL"/>
    <client-edit-modal :ref="ModalList().CLIENT_EDIT_MODAL"/>
    <client-info-modal :ref="ModalList().CLIENT_INFO_MODAL"/>
    <client-delete-modal :ref="ModalList().CLIENT_DELETE_MODAL"/>
    <client-id-regenerate-confirm-modal :ref="ModalList().CLIENT_ID_REGENERATE_CONFORM_MODAL"/>
    <user-preview-modal :ref="ModalList().USER_PREVIEW_MODAL"/>
    <update-admin-role-modal :ref="ModalList().UPDATE_ADMIN_ROLE_MODAL"/>
    <update-user-state-modal :ref="ModalList().UPDATE_USER_STATE_MODAL"/>
    <update-user-identify-verify-modal :ref="ModalList().UPDATE_USER_IDENTIFY_VERIFY_MODAL"/>
    <manual-selected-modal :ref="ModalList().MANUAL_SELECTED_MODAL"/>
    <manual-selected-delete-modal :ref="ModalList().MANUAL_SELECTED_DELETE_MODAL"/>
    <site-config-confirm-modal :ref="ModalList().SITE_CONFIG_CONFIRM_MODAL"/>
  </div>

</template>

<script>
import { mapMutations, mapState } from "vuex"
import $ from 'jquery'
import { utilMixin } from "@/mixins/utilMixin";
import CheckModal from '@/components/modal/CheckModal'
import DefaultCoverModal from '@/components/modal/DefaultCoverModal'
import CroppieModal from '@/components/modal/CroppieModal'
import CropperModal from '@/components/modal/CropperModal'
import PromotionModal from '@/components/modal/PromotionModal'
import PromotionRejectModal from '@/components/modal/PromotionRejectModal'
import PromotionApproveModal from '@/components/modal/PromotionApproveModal'
import PromotionUpdateConfirmModal from '@/components/modal/PromotionUpdateConfirmModal'
import PreviewModal from '@/components/modal/PreviewModal'
import LoadingModal from '@/components/modal/LoadingModal'
import SuccessModal from '@/components/modal/SuccessModal'
import FailModal from '@/components/modal/FailModal'
import PostDeleteModal from '@/components/modal/PostDeleteModal'
import TransactionResultModal from '@/components/modal/TransactionResultModal'
import TransactionPendingModal from '@/components/modal/TransactionPendingModal'
import ClientCreateModal from "@/components/modal/ClientCreateModal"
import ClientEditModal from "@/components/modal/ClientEditModal"
import ClientInfoModal from "@/components/modal/ClientInfoModal"
import ClientDeleteModal from "@/components/modal/ClientDeleteModal"
import ClientIdRegenerateConfirmModal from "@/components/modal/ClientIdRegenerateConfirmModal"
import UserPreviewModal from "@/components/modal/UserPreviewModal"
import UpdateAdminRoleModal from "@/components/modal/UpdateAdminRoleModal"
import UpdateUserStateModal from "@/components/modal/UpdateUserStateModal"
import UpdateUserIdentifyVerifyModal from "@/components/modal/UpdateUserIdentifyVerifyModal"
import ManualSelectedModal from "@/components/modal/ManualSelectedModal"
import ManualSelectedDeleteModal from "@/components/modal/ManualSelectedDeleteModal"
import SiteConfigConfirmModal from "@/components/modal/SiteConfigConfirmModal"

export default {
  name: "ModalGroup",
  mixins: [utilMixin],
  components: {
    CheckModal,
    DefaultCoverModal,
    CroppieModal,
    CropperModal,
    PromotionModal,
    PromotionRejectModal,
    PromotionApproveModal,
    PromotionUpdateConfirmModal,
    PreviewModal,
    LoadingModal,
    SuccessModal,
    FailModal,
    PostDeleteModal,
    TransactionResultModal,
    TransactionPendingModal,
    ClientCreateModal,
    ClientEditModal,
    ClientInfoModal,
    ClientDeleteModal,
    ClientIdRegenerateConfirmModal,
    UserPreviewModal,
    UpdateAdminRoleModal,
    UpdateUserStateModal,
    UpdateUserIdentifyVerifyModal,
    ManualSelectedModal,
    ManualSelectedDeleteModal,
    SiteConfigConfirmModal
  },
  data(){
    return {
      currentModalId: null
    }
  },
  mounted(){
    this.init()
  },
  methods: {
    ...mapMutations(['consumeModal','consumeManualHideModal']),
    init(){
      this.modalIdList.forEach(modalId => {
        $(this.$refs[modalId].$el).on('show.bs.modal',() => {
          this.currentModalId = modalId
        })

        $(this.$refs[modalId].$el).on('hidden.bs.modal', () => {
          this.currentModalId = null
        })
      })
    },
    showModal(modalId,data){
      let payload = data ? data : {}

      if(this.$refs[modalId] && this.$refs[modalId].show){
        this.$refs[modalId].show(payload)
      }
    },
    hideModal(modalId){
      if(this.$refs[modalId] && this.$refs[modalId].hide){
        this.$refs[modalId].hide()
      }
    }
  },
  computed: {
    ...mapState(['modalQueue', 'manualHideModal', 'manualHideModalPayload']),
    modalIdList(){
      let list = []
      Object.keys(this.$refs).forEach( modalId => {
        list.push(modalId)
      })
      return list
    },
    isModalOpen(){
      return this.currentModalId != null
    },
  },
  watch: {
    manualHideModal(manualHideModal){
      if(manualHideModal && this.currentModalId){
        this.consumeManualHideModal()
        this.hideModal(this.currentModalId)
      }
    },
    modalQueue(modalQueue){
      if(!this.isModalOpen && modalQueue.length > 0){
        let info = modalQueue[0]
        this.showModal(info.modalId,info.payload)
        this.consumeModal()
      }
    },
    isModalOpen(isModalOpen){
      if(!isModalOpen && this.modalQueue.length > 0){
        let info = this.modalQueue[0]
        this.showModal(info.modalId,info.payload)
        this.consumeModal()
      }
    }
  }
}
</script>

<style scoped>

</style>
