import axios from "@/plugins/axios";

// post 相關的 api
let baseUrl = '/'

if(process.env.VUE_APP_baseUrl) {
    baseUrl = process.env.VUE_APP_baseUrl
}

baseUrl += 'api/internal/admin/post'

// find all post list
export function findAllPostList(query){
    return axios.get(`${baseUrl}/list?${query}`).then(res=>res.data)
}

// find a post
export function adminGetPostById(id){
    return axios.get(`${baseUrl}/${id}`).then(res=>res.data)
}

// delete a post
export function deletePost(id, state){
    return axios.delete(`${baseUrl}/${id}?state=${state}`)
}
