import Vue from 'vue'
import VueRouter from 'vue-router'
import PublicPostList from '../views/PublicPostList.vue'
import PublicPostContent from '../views/PublicPostContent.vue'
import PublicPolicy from '../views/PublicPolicy.vue'
import UserManagement from '../views/UserManagement.vue'
import PostEdit from '../components/internal/user/PostEdit.vue'
import PostList from '../components/internal/user/PostList.vue'
import UserInfo from '../components/internal/user/UserInfo.vue'
import PromotionList from '../components/internal/user/PromotionList.vue'
import TransactionList from '../components/internal/user/TransactionList.vue'
import AdminManagement from '../views/AdminManagement.vue'
import UserList from '../components/internal/admin/UserList.vue'
import RSSList from '../components/internal/admin/RSSList.vue'
import PinPost from '../components/internal/admin/PinPost.vue'
import SelectedPost from '../components/internal/admin/SelectedPost.vue'
import SpecialSelectedPost from '../components/internal/admin/SpecialSelectedPost.vue'
import HotTag from '../components/internal/admin/HotTag.vue'
import AllPostList from '../components/internal/admin/AllPostList.vue'
import AllPromotionList from '../components/internal/admin/AllPromotionList.vue'
import PromotionReviewing from '../components/internal/admin/PromotionReviewing.vue'
import SiteConfig from '../components/internal/admin/SiteConfig.vue'
import PromotionReport from '../views/PromotionReport.vue'
import HelpGuide from '../views/HelpGuide.vue'
import NotFound from '../views/NotFound.vue'
import Const from "@/const";

const mode = 'history'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'PublicPostList_Latest',
    component: PublicPostList,
    meta: {
      type: "latest",
    }
  },
  {
    path: '/category/:main',
    name: 'PublicPostList_MainCategory',
    caseSensitive: true,
    component: PublicPostList,
    meta: {
      type: "category",
    }
  },
  {
    path: '/category/:main/sub/:sub',
    name: 'PublicPostList_Main_SubCategory',
    caseSensitive: true,
    component: PublicPostList,
    meta: {
      type: "subCategory",
    }
  },
  {
    path: '/search',
    name: 'PublicPostList_SEARCH',
    caseSensitive: true,
    component: PublicPostList,
    meta: {
      type: "search",
    }
  },
  {
    path: '/tag',
    name: 'PublicPostList_TAG',
    caseSensitive: true,
    component: PublicPostList,
    meta: {
      type: "tag"
    }
  },
  {
    path: '/publisher',
    name: 'PublicPostList_PUBLISHER',
    caseSensitive: true,
    component: PublicPostList,
    meta: {
      type: "publisher"
    }
  },
  {
    path: '/post/:id',
    name: 'PublicPostContent',
    caseSensitive: true,
    component: PublicPostContent,
    // path: '/post/63a151c4b00e6e2e9b1c4da0'
    // path: '/post/63a151c4b00e6e2e9b1c4da0/Post_Title'
    children: [
      {
        path: ':title',
        caseSensitive: true,
        component: PublicPostContent
      },
    ],
  },
  {
    path: '/policy/:type',
    name: 'PublicPolicy',
    caseSensitive: true,
    component: PublicPolicy
    // path: '/policy/publish'
    // path: '/policy/tou'
    // path: '/policy/privacy'
  },
  {
    path: '/me/',
    name: 'UserManagement',
    caseSensitive: true,
    component: UserManagement,
    // path: '/me/post-edit'
    // path: '/me/info'
    // path: '/me/post-list'
    // path: '/me/promotion-list'
    // path: '/me/transaction-list'
    children: [
      {
        path: 'post-edit',
        component: PostEdit,
        name: 'PostEdit',
        meta: {
          label: 'me',
          tab: 'postEdit'
        }
      },
      {
        path: 'post-list',
        component: PostList,
        name: 'PostList',
        meta: {
          label: 'me',
          tab: 'postList'
        }
      },
      {
        path: 'info',
        component: UserInfo,
        name: 'UserInfo',
        meta: {
          label: 'me',
          tab: 'info'
        }
      },
      {
        path: 'promotion-list',
        component: PromotionList,
        name: 'PromotionList',
        meta: {
          label: 'me',
          tab: 'promotionList'
        }
      },
      {
        path: 'transaction-list',
        component: TransactionList,
        name: 'TransactionList',
        meta: {
          label: 'me',
          tab: 'transactionList'
        }
      },
    ],
    beforeEnter: (_to,_from,next) => {
      let role = Const.ROLE_NORMAL
      fetch(`/login/check?role=${role}`)
          .then(res => {
            if(res.redirected){
              document.location = res.url
            } else {
              next()
            }
          })
    }
  },
  {
    path: '/admin/',
    name: 'AdminManagement',
    caseSensitive: true,
    component: AdminManagement,
    // path: '/admin/user-list'
    // path: '/admin/rss-list'
    // path: '/admin/pin'
    // path: '/admin/selected'
    // path: '/admin/special-selected'
    // path: '/admin/tag'
    // path: '/admin/all-post-list'
    // path: '/admin/all-promotion-list'
    // path: '/admin/promotion-review'
    // path: '/admin/site-config'
    children: [
      {
        path: 'user-list',
        component: UserList,
        name: 'UserList',
        meta: {
          label: 'admin',
          tab: 'userList'
        }
      },
      {
        path: 'rss-list',
        component: RSSList,
        name: 'RSSList',
        meta: {
          label: 'admin',
          tab: 'rssList'
        }
      },
      {
        path: 'pin-post',
        component: PinPost,
        name: 'PinPost',
        meta: {
          label: 'admin',
          tab: 'pinPost'
        }
      },
      {
        path: 'selected-post',
        component: SelectedPost,
        name: 'SelectedPost',
        meta: {
          label: 'admin',
          tab: 'selectedPost'
        }
      },
      {
        path: 'special-selected-post',
        component: SpecialSelectedPost,
        name: 'SpecialSelectedPost',
        meta: {
          label: 'admin',
          tab: 'specialSelectedPost'
        }
      },
      {
        path: 'hot-tag',
        component: HotTag,
        name: 'HotTag',
        meta: {
          label: 'admin',
          tab: 'hotTag'
        }
      },
      {
        path: 'all-post-list',
        component: AllPostList,
        name: 'AllPostList',
        meta: {
          label: 'admin',
          tab: 'allPostList'
        }
      },
      {
        path: 'all-promotion-list',
        component: AllPromotionList,
        name: 'AllPromotionList',
        meta: {
          label: 'admin',
          tab: 'allPromotionList'
        }
      },
      {
        path: 'promotion-reviewing',
        component: PromotionReviewing,
        name: 'PromotionReviewing',
        meta: {
          label: 'admin',
          tab: 'allPromotionList'
        }
      },
      {
        path: 'site-config',
        component: SiteConfig,
        name: 'SiteConfig',
        meta: {
          label: 'admin',
          tab: 'siteConfig'
        }
      },
    ],
    beforeEnter: (_to,_from,next) => {
      let role = Const.ROLE_ADMIN
      fetch(`/login/check?role=${role}`)
          .then(res => {
            if(res.redirected){
              document.location = res.url
            } else {
              next()
            }
          })
    }
  },
  {
    path: '/promotion-report',
    name: 'PromotionReport',
    caseSensitive: true,
    component: PromotionReport
  },
  {
    path: '/help-guide',
    name: 'HelpGuide',
    caseSensitive: true,
    component: HelpGuide
  },
  { path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  }
]



const router = new VueRouter({
  routes,
  mode
})

export default router
