var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:_vm.modalId,staticClass:"modal fade",attrs:{"role":"dialog","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header pb-0 text-bold"},[_c('div',{staticClass:"mb-3",staticStyle:{"font-size":"1.3rem"}},[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"modal-body"},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"form-validate"},[(_vm.isTagType)?[_c('validation-provider',{attrs:{"name":"tag","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"tag"}},[_c('small',{staticClass:"m-1 text-red"},[_vm._v("*")]),_vm._v("標籤")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tag),expression:"tag"}],staticClass:"form-control",attrs:{"id":"tag","type":"text","placeholder":"ex: 振興券","required":""},domProps:{"value":(_vm.tag)},on:{"input":function($event){if($event.target.composing){ return; }_vm.tag=$event.target.value}}}),_c('div',{staticClass:"col-sm-12 text-danger has-error"},[_vm._v(_vm._s(errors[0]))]),_c('div',{staticClass:"mt-1"},[_vm._v("熱門標籤： "),_vm._l((_vm.hotTagList),function(tag,index){return [_c('span',{key:index},[_vm._v(_vm._s(tag.name)+_vm._s(_vm.isShowComma(index)?'、':''))])]})],2)])])]}}],null,true)})]:[_c('validation-provider',{attrs:{"name":"postId","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"postId"}},[_c('small',{staticClass:"m-1 text-red"},[_vm._v("*")]),_vm._v("新聞稿ID")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.postId),expression:"postId"}],staticClass:"form-control",attrs:{"id":"postId","type":"text","placeholder":"ex: 62d764dbf998a880cdd9d1a9","required":""},domProps:{"value":(_vm.postId)},on:{"input":function($event){if($event.target.composing){ return; }_vm.postId=$event.target.value}}}),_c('div',{staticClass:"col-sm-12 text-danger has-error"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)})],_c('div',{staticClass:"form-row"},[_c('validation-provider',{attrs:{"name":"startTime","rules":("required" + (_vm.isCreateMode ? '|afterNow' : '')),"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"startTime"}},[_c('small',{staticClass:"m-1 text-red"},[_vm._v("*")]),_vm._v("開始時間")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formatStartTime),expression:"formatStartTime"}],staticClass:"form-control",attrs:{"id":"startTime","type":"datetime-local","min":_vm.minStartTime,"required":""},domProps:{"value":(_vm.formatStartTime)},on:{"input":function($event){if($event.target.composing){ return; }_vm.formatStartTime=$event.target.value}}}),_c('div',{staticClass:"col-sm-12 text-danger has-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"endTime","rules":"required|isAfter:@startTime","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"endTime"}},[_c('small',{staticClass:"m-1 text-red"},[_vm._v("*")]),_vm._v("結束時間")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formatEndTime),expression:"formatEndTime"}],staticClass:"form-control",attrs:{"id":"endTime","type":"datetime-local","min":_vm.minEndTime,"required":""},domProps:{"value":(_vm.formatEndTime)},on:{"input":function($event){if($event.target.composing){ return; }_vm.formatEndTime=$event.target.value}}}),_c('div',{staticClass:"col-sm-12 text-danger has-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('div',{staticClass:"modal-footer border-0"},[_c('button',{staticClass:"btn btn-info",attrs:{"type":"button"},on:{"click":_vm.hide}},[_vm._v("取消")]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","disabled":invalid},on:{"click":_vm.save}},[_vm._v("存檔")])])],2)]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }