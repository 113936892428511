<template>

<div :ref="modalId" class="modal fade" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="text-center m-2">
          <i class="fa fa-8x fa-exclamation-circle text-danger"></i>
        </div>
        <div class="text-bold text-center text-secondary">
          Opps! 有東西出錯了，請再試試看
        </div>
        <div v-if="data.content" class="text-bold text-center text-secondary">{{ data.content }}</div>
      </div>
      <div class="modal-footer border-0">
        <button type="button" class="btn btn-danger" @click="hide">確定</button>
      </div>
    </div>
  </div>
</div>

</template>

<script>
import $ from "jquery";
import ModalList from "@/const/modal";

export default {
  name: "FailModal",
  data(){
    return {
      modalId: ModalList.FAIL_MODAL,
      data: {
        content: ''
      },
    }
  },
  methods: {
    show(data){
      data = data ? data : {}
      this.data.content = data.content ? data.content : ''

      $(this.$refs[this.modalId]).modal('show')
    },
    hide(){
      $(this.$refs[this.modalId]).modal('hide')
    },
  }
}
</script>

<style scoped>

</style>
