<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-position col-9-size col-6-size">
          <span class="h4">新聞稿管理</span>
        </div>
        <div class="col-position col-3-size col-6-size">
          <button @click="editDefaultCover()" type="button" class="btn btn-info" >設定預設封面</button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="form-inline my-2 my-lg-0" style="justify-content: center">
        <input class="form-control mr-sm-2 w-size" placeholder="輸入標題關鍵字" v-model="search.title">
        <button class="btn btn-outline-success m-position" @click="searchByQuery(getUserPostList, {page: 1, ...search})">搜尋新聞稿</button>
        <button class="btn btn-danger m-position" @click="resetSearch">重設搜尋</button>
      </div>
      <div class="mt-2 d-flex flex-wrap flex-md-nowrap justify-content-end">
        <div class="d-flex">
          <div class="text-secondary align-self-center" id="resultCount">
            共<span class="mr-1 ml-1">{{totalData}}</span>筆
          </div>

          <div class="ml-3">
            <select name="state" class="pr-4 pl-2 border-0 font-weight-bold text-primary mr-2"
                    v-model="search.state" @change="searchByQuery(getUserPostList, {page: 1, ...search})">
              <option value="">所有文章</option>
              <option value="TEMP">草稿</option>
              <option value="PUBLISHED">已刊登</option>
              <option value="WITHDREW">已下架</option>
              <option value="REMOVED">撤文</option>
            </select>
          </div>
        </div>
      </div>
      <div class="table-outter wrapper">
        <table v-if="currentPageDataList.length > 0" id="dataTable" class="table table-hover table-responsive vertical-middle text-center mt-4">
          <thead>
          <tr>
            <th scope="col" class="col-75" width="12%"></th>
            <th scope="col" class="col-100" width="12%">封面</th>
            <th scope="col" class="col-200" width="30%">標題</th>
            <th scope="col" class="col-75">狀態</th>
            <th scope="col" class="col-100" width="15%">編輯時間</th>
            <th scope="col" class="col-50">編輯</th>
            <th scope="col" class="col-50">檢視</th>
            <th scope="col" class="col-50">詳情</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(post, postIndex) in currentPageDataList">
            <tr :key="`${post.id}-1`">
              <!-- 推廣 -->
              <td class="col-75">
                <template v-if="post.state == 'PUBLISHED'">
                  <a class="btn btn-block promotion-btn btn-sm"
                     @click="checkIsAnyTransactionExists(post)">
                     <span>推廣</span>
                  </a>
                </template>
              </td>
              <!-- 封面 -->
              <td class="col-100"><img :src="post.cover ? post.cover : ''" class="img-fluid"></td>
              <!-- 標題 -->
              <td class="col-200">{{post.title}}</td>
              <!-- 狀態 -->
              <td class="col-75">{{getPostStateName(post.state)}}</td>
              <!-- 編輯時間 -->
              <td class="col-100">{{ post.modifyTime | timeString('YYYY-MM-DD') }}<br>{{ post.modifyTime | timeString('hh:mm:ss') }}</td>
              <!-- 編輯 -->
              <td class="col-50">
                <template v-if="!isDeletePost(post.state)">
                  <router-link :to="{ path: '/me/post-edit', query: { id: post.id } }"
                               @click.native="scrollToTop">
                    <i class="icon-edit"></i>
                  </router-link>
                </template>
              </td>
              <!-- 檢視 -->
              <td class="col-50">
                <i class="icon-eye pointIcon" @click="showModal({modalId: ModalList().PREVIEW_MODAL, payload: { type: 'userCurrentPost', postId: post.id }})"></i>
              </td>
              <!-- 詳情 -->
              <td class="col-50"><i class="icon-monitor pointIcon" @click="toggleDetail(`collapse-${postIndex}`, post)"></i></td>
            </tr>
            <tr :key="`${post.id}-2`" :ref="`collapse-${postIndex}`" class="collapse" data-parent="#dataTable">
              <td colspan="8">
                <div class="m-1">
                  <!-- 下架/刪除 & 短網址 -->
                  <div class="mb-3 text-right" v-show="!isDeletePost(post.state)">
                    <a class="mr-2 text-primary pointIcon" @click="showDeletePostModal(post.id, post.title, post.state)">{{post.state == 'PUBLISHED'? '下架' : '刪除'}}
                      <i class="ml-1" :class="post.state == 'PUBLISHED' ? 'icon-arrow-down-right' : 'icon-trash'"></i></a>
                    <a v-if="post.state == 'PUBLISHED'" class="mr-2 text-primary pointIcon" @click="copyLink(post.shortUrl)">短網址<i class="ml-1 icon-copy"></i></a>
                  </div>
                  <!-- 刊登時間 -->
                  <div class="mb-2 text-left" v-if="post.publishTime">
                    <div class="text-primary">刊登時間</div>
                    <div>{{post.publishTime | timeString('YYYY-MM-DD hh:mm:ss')}}</div>
                  </div>
                  <!-- 分類 -->
                  <div class="mb-2 text-left">
                    <div class="text-primary">分類</div>
                    <template v-if="post.categoryList">
                      <div v-for="category in post.categoryList" :key="category.id">
                        <div>
                          <span class="mr-2">{{category.categoryZh}}</span>
                          <template v-for="(subCategory, index) in category.subCategory">
                          <span class="text-black-50" :key="subCategory.id">{{subCategory.industryZh}}
                            <span class="text-black-50" v-if="index != (category.subCategory.length-1)"> , </span>
                          </span>
                          </template>
                        </div>
                      </div>
                    </template>
                  </div>
                  <!-- 公開資訊 -->
                  <div class="mb-2 text-left">
                    <div class="text-primary">公開資訊</div>
                    <label>{{post.showContact? '是' : '否'}}</label>
                  </div>
                  <!-- 紀錄 -->
                  <div v-if="recordList.length > 0" class="mb-2">
                    <div class="text-primary text-left">紀錄</div>
                    <div class="row mb-2">
                      <div class="col"><span>編輯時間</span><span></span></div>
                      <div class="col"><span>編輯欄位</span><span></span></div>
                      <div class="col"><span>檢視</span><span></span></div>
                    </div>
                    <div v-for="record in recordList" :key="record.id">
                      <div class="row mb-2">
                        <div class="col">{{record.createTime | timeString('YYYY-MM-DD hh:mm:ss')}}</div>
                        <div class="col">
                          <template v-for="(field, index) in record.field">
                            <span :key="index">{{getPostFieldName(field)}}<span v-if="index != record.field.length-1">,</span>
                            </span>
                          </template>
                        </div>
                        <div class="col"> <i class="icon-eye pointIcon" @click="showModal({modalId: ModalList().PREVIEW_MODAL, payload: { type: 'recordPost', postId: post.id, recordId: record.id }})"></i></div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
        <div v-else id="result-hint" class="d-flex justify-content-center">
          <template v-if="isEmptyList">尚無資料</template>
          <template v-else><loading/></template>
        </div>
      </div>
      <!-- Pagination -->
      <ul v-if="currentPageDataList.length > 0" class="pagination justify-content-center">
        <li class="page-item">
          <a class="page-link" @click="currentPage != 1 ? getPreviousPage(currentPage, getUserPostList):''"><i class="fa fa-angle-left"></i></a>
        </li>
        <template v-for="page in range">
          <li class="page-item" :class="{'active': page === currentPage}" :key="page">
            <a class="page-link" @click="currentPage != page ? toTargetPage(page, getUserPostList):''" style="cursor: default;">{{page}}</a>
          </li>
        </template>
        <li class="page-item">
          <a class="page-link" @click="currentPage != totalPage ? toNextPage(currentPage, totalPage, getUserPostList):''"><i class="fa fa-angle-right"></i></a>
        </li>
      </ul>
      <!-- end: Pagination -->
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import ModalList from "@/const/modal";
import Loading from "@/components/common/Loading";
import { mapGetters, mapMutations } from "vuex";
import { utilMixin } from "@/mixins/utilMixin";
import { findAllPost, findPostRecordList, deletePost } from '@/service/api/internal/me/post';
import { getPendingPromotion } from '@/service/api/internal/me/transaction';

export default {
  name: "PostList",
  mixins: [utilMixin],
  components: {
    Loading
  },
  data(){
    return{
      search:{
        title: '',
        state: ''
      },
      recordList: []
    }
  },
  computed:{
    ...mapGetters(['getPostStateName', 'getPostFieldName']),
  },
  mounted() {
    this.initSearchParam()
    this.getUserPostList(this.currentPage)
  },
  methods:{
    ...mapMutations(['showModal']),
    initSearchParam(){
      let search = JSON.parse(JSON.stringify(this.search))
      this.search = this.initSearchData(search)
      if(!this.search.state){
        this.search.state= ''
      }
    },
    editDefaultCover(){
      this.showModal({
        modalId: ModalList.DEFAULT_COVER_MODAL
      })
    },
    async getUserPostList(page) {
      let currentPage = this.checkPageValid(page, this.currentPage, this.totalPage)
      this.currentPage = currentPage
      let query = this.getSearchQuery(this.currentPage, this.show, this.search)
      let response = await findAllPost(query)
      this.currentPageDataList = response.data
      this.totalPage = response.totalPage
      this.totalData = response.totalElement
      if(this.currentPageDataList.length == 0){
        this.isEmptyList = true
      }
    },
    resetSearch(){
      let state = this.search.state
      this.search = {}
      this.search.state = state
      this.search.title = ""
      if(window.location.search) {
        this.searchByQuery(this.getUserPostList, {page: 1, ...this.search})
      }
    },
    async checkIsAnyTransactionExists(post){
      await getPendingPromotion(post.id).then(res =>{
        switch (res.status){
          case 204: {
            this.showModal({
              modalId: ModalList.PROMOTION_MODAL,
              payload: {
                promotion: {
                  postId: post.id,
                  name: post.title,
                  text: post.summary,
                  imgMain: post.cover
                }
              }
            })
            break;
          }
          case 200:
            return res.data
        }
      }).then( transaction =>{
        if(transaction){
          this.showModal({modalId: ModalList.TRANSACTION_PENDING_MODAL,
            payload: {
              title: '本文章有尚未完成付款的推廣訂單：',
              orderId: transaction.orderId,
              description: '您前一次操作並未完成流程。按下確認後，系統將為您檢查訂單狀態。若仍為有效，則會引導您繼續前往付款。若已失效，請您返回本頁，重新下單。'
            }})
        }
      })
    },
    toggleDetail(refId, post){
      if(this.$refs[refId]){
        $(this.$refs[refId]).collapse('toggle')
        if(post.state == "PUBLISHED"){
          this.getPostRecordList(post.id)
        }
      }
    },
    showDeletePostModal(id, title, state){
      this.showModal({
        modalId: ModalList.POST_DELETE_MODAL,
        payload: {
          postId: id,
          title: title,
          state: state,
          delete: this.deletePost
        }})
    },
    copyLink(link){
      const input = document.createElement('input')
      document.body.appendChild(input)
      input.value = link
      input.select()
      document.execCommand('copy')
      document.body.removeChild(input)
      this.showModal({
        modalId: ModalList.CHECK_MODAL,
        payload: {
          title: '成功',
          content: '已複製短網址',
          url: link
        }
      })
    },
    async getPostRecordList(postId){
      await findPostRecordList(postId).then(list =>{
        this.recordList = list.data
      })
    },
    async deletePost(data){
      await deletePost(data.postId, data.state).then(res =>{
        if(res.status == 204){
          // update current post list
          let page = parseInt(this.$route.query.page ? this.$route.query.page : 1)
          this.getUserPostList(page)
          this.showSuccessModal({ maxCountDownSec: 3, content: `已成功刪除新聞稿 ${data.title}` })
        }else {
          this.showErrorModal()
        }
      })
    },
    isDeletePost(state){
      switch (state) {
        case 'PUBLISHED':
        case 'TEMP':
          return false
        case 'WITHDREW':
        case 'REMOVED':
          return true
      }
    }
  }
}
</script>

<style scoped>
.pointIcon{
  cursor: pointer;
  color: rgb(24 135 255)
}

/* start of new version of promotion-btn */
.promotion-btn{
  background: linear-gradient(90deg, #6A11CB 0%, #2575FC 100%);
  box-shadow: 2px 2px 0px rgba(15, 70, 119, 0.4) !important;
  color: white !important;
  transition: linear .2s !important;
  position: relative;
  border: none !important;
}

.promotion-btn > span {
  position: relative;
  z-index: 3;
}

.promotion-btn > .icon-megaphone {
  position: relative;
  z-index: 3;
  display: inline-flex;
  width: 20px !important;
  height: 18px!important;
  margin: 3px 6px 0 0;
  transition: cubic-bezier(0.39, 0.58, 0.57, 1) .3s;
}

.promotion-btn:before {
  content:'';
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #2575FC 0%, #4744E4 25.52%, #6A11CB 100%);
  opacity: 0;
  transition: cubic-bezier(0.39, 0.58, 0.57, 1) .3s;
  border-radius: inherit;
}

.promotion-btn:hover{
  box-shadow: none!important;
  color:#FFDA55!important;
  transition: cubic-bezier(0.39, 0.58, 0.57, 1) .3s;
}

.promotion-btn:hover > img.icon-megaphone{
  filter: invert(0%) sepia(52%) saturate(7129%) hue-rotate(333deg) brightness(96%) contrast(93%);
  transition: cubic-bezier(0.39, 0.58, 0.57, 1) .3s;
}

.promotion-btn:hover:before {
  opacity: 1;
  transition: cubic-bezier(0.39, 0.58, 0.57, 1) .3s;
}
/* end of new version of promotion-btn */

.table-outter {
  overflow-x: scroll;
}

.col-200 {
  min-width: 200px;
}

.col-100 {
  min-width: 100px;
}

.col-75 {
  min-width: 75px;
}

.col-50 {
  min-width: 50px;
}

.col-position{
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (max-width: 576px) {
  .col-6-size {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .m-position{
    margin-top: 0.5rem!important;
    margin-bottom: 0.5rem!important;
  }
}

@media only screen and (min-width: 576px) {
  .col-9-size {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-3-size {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .w-size {
    width: 50% !important;
  }

  .m-position{
    margin-bottom: 0 !important;
  }
}

</style>
