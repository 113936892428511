import axios from '../../plugins/axios.js';

// Public 相關的 api

let baseUrl = '/'

if(process.env.VUE_APP_baseUrl) {
    baseUrl = process.env.VUE_APP_baseUrl
}

baseUrl += 'api/public'


// get category list
export function getCategoryList() {
    return axios.get(`${baseUrl}/category`).then(res=>res.data)
}

// get publish post list
// url could be /api/public/post
//              /api/public/post/search
//              /api/public/post/tag
//              /api/public/post/category/{primaryCategory}
//              /api/public/post/category/{primaryCategory}/sub/{subCategory}
export function findAllPublishedByUrl(url) {
    return axios.get(url).then(res=>res.data)
}

// get the special selected Post
export function findSpecialSelectedPost(page) {
    return axios.get(`${baseUrl}/post/specialSelected?page=${page}`).then(res=>res.data)
}

// get Post by postId
export function findPost(id) {
    return axios.get(`${baseUrl}/post/${id}`)
}

// get the previous or next Post
export function findPreviousOrNextPost(id, target) {
    return axios.get(`${baseUrl}/post/${id}/${target}`).then(res=>res.data)
}

// get the pin Post list
export function findPinPosts() {
    return axios.get(`${baseUrl}/post/pin`).then(res=>res.data)
}

// get the promoted Post list
export function findPromotedPosts() {
    return axios.get(`${baseUrl}/post/promoted`).then(res=>res.data)
}

// get the selected Post list
export function findSelectedPosts() {
    return axios.get(`${baseUrl}/post/selected`).then(res=>res.data)
}

// get the latest Post list
export function findLatestPosts() {
    return axios.get(`${baseUrl}/post/last`).then(res=>res.data)
}

// get all policy
export function findAllPolicy() {
    return axios.get(`${baseUrl}/site/all-policy`).then(res=>res.data)
}

// get policy by policy name
export function findPolicy(policy) {
    return axios.get(`${baseUrl}/site/${policy}`).then(res=>res.data)
}

// get contactEmail
export function findContactEmail() {
    return axios.get(`${baseUrl}/site/contact-email`).then(res=>res.data)
}

//get hot tag list
export function getHotTagList(day, limit) {
    return axios.get(`${baseUrl}/tag/hot?day=${day}&limit=${limit}`).then(res=>res.data)
}

// get publisher user meta
export function getUserMeta(userId) {
    return axios.get(`${baseUrl}/user?userId=${userId}`).then(res=>res.data)
}
