import axios from "@/plugins/axios";

// ManualSelected 相關的 api
let baseUrl = '/'

if(process.env.VUE_APP_baseUrl) {
    baseUrl = process.env.VUE_APP_baseUrl
}

baseUrl += 'api/internal/admin'

// add new manual select
export function createManualSelected(manualType, dto){
    return axios.post(`${baseUrl}/${manualType}`, dto, { headers: {'Content-Type': 'application/json'} })
}

// find manual selected list
export function findManualSelectedList(manualType, query){
    return axios.get(`${baseUrl}/${manualType}/list?${query}`).then(res=>res.data)
}

// update manual selected
export function updateManualSelected(manualType, id, dto){
    return axios.put(`${baseUrl}/${manualType}/${id}`, dto, { headers: {'Content-Type': 'application/json'} })
}

// update all active manual selected order
export function updateAllManualSelectedOrder(manualType){
    return axios.put(`${baseUrl}/${manualType}/order`)
}

// delete manual selected
export function deleteManualSelected(manualType, id){
    return axios.delete(`${baseUrl}/${manualType}/${id}`)
}

// get recommend tag list
export function getRecommendTagList(){
    return axios.get(`${baseUrl}/recommend-tag-list`).then(res=>res.data)
}
