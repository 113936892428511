<template>
  <div class="card">
    <div class="card-header">
      <span class="h4">{{postId?'編輯':'新增'}}新聞稿</span>
      <p class="text-muted">在發布文章之前，請先填寫您的新聞稿
        <router-link to="/me/info" @click.native="scrollToTop">主要公開聯繫資訊</router-link>，方便新聞稿刊登時留下聯繫方式。
      </p>
    </div>
    <div v-if="isFinishUserMeta" class="card-body">
      <template v-if="userMeta.identifyVerify || postId">
        <validation-observer v-slot="{invalid}">
          <div>
            <!-- 選擇分類 -->
            <div class="form-row">
              <div class="form-group col-md-12">
                <validation-provider ref="categoryValidator" name="category" rules="category" v-slot="{ errors }">
                  <label for="category"><small class="m-1 text-red">*</small> 選擇分類
                    <span v-if="categoryLimitMsgShow" class="text-red text-bold m-2">{{categoryLimitMsg}}</span>
                    <span class="text-red text-bold m-2">{{ errors[0] }}</span>
                  </label>
                  <input class="d-none" type="text" :value="isCategoryValid">
                </validation-provider>
                <div id="category" class="pl-3">
                  <template v-for="category in categoryList">
                    <div :id="category.id" class="pb-2" :key="category.id">
                      <div><i class="fa fa-tag m-2"></i>{{category.categoryZh}}</div>
                      <template v-for="subCategory in category.subCategory">
                        <button :id="subCategory.id" type="button" class="btn-sm mr-1"
                                :key="subCategory.id" @click="toggleCategory(subCategory.id, )"
                                :class="categorySelected(subCategory.id)?'btn-info':'btn-nostyle'"
                        >{{subCategory.industryZh}}</button>
                      </template>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="form-row">
              <!-- 新聞稿標題 -->
              <div class="form-group col-md-12">
                <validation-provider name="title" rules="title|required" v-slot="{ errors }">
                  <label for="title"><small class="m-1 text-red">*</small> 新聞稿標題
                    <span class="text-red text-bold m-2">{{ errors[0] }}</span>
                  </label>
                  <input v-model="data.title" id="title" type="text" class="form-control"
                         name="title" placeholder="請輸入標題">
                </validation-provider>
              </div>
              <!-- 新聞稿摘要 -->
              <div class="form-group col-md-12">
                <validation-provider name="summary" rules="summary|required" v-slot="{ errors }">
                  <label for="summary"><small class="m-1 text-red">*</small> 新聞稿摘要
                    <span class="text-red text-bold m-2">{{ errors[0] }}</span>
                  </label>
                  <textarea v-model="data.summary" id="summary" type="text" class="form-control"
                            name="summary" placeholder="在分享單位的內容清單中，有時候只會顯示摘要"
                            style="width: 100%; min-height: 60px;"></textarea>
                </validation-provider>
              </div>
              <!-- 關鍵字 -->
              <div class="form-group col-md-12">
                <label>填入關鍵字</label>
                <label>請按「Enter」鍵，新增標籤，長度不可小於 2，不包含空白及特殊符號，數量至多為 5</label>
                <vue-tags-input
                    placeholder="輸入Tag..."
                    class="w-100"
                    v-model="tag"
                    :tags="tags"
                    :validation="validation"
                    :autocomplete-items="filteredItems"
                    @tags-changed="checkTagValid"
                />
                <small class="form-text text-muted">可以幫助新聞稿在搜尋引擎最佳化(SEO)，讓新聞稿更容易在網路中被搜尋到。</small>
              </div>
              <!-- 新聞稿內容 -->
              <div class="form-group col-md-12">
                <validation-provider name="content" rules="content|required" v-slot="{ errors }">
                  <label><small class="m-1 text-red">*</small> 新聞稿內容
                    <span class="text-red text-bold m-2">{{ errors[0] }}</span>
                  </label>
                  <ckeditor
                      v-model="data.content"
                      :config="editorConfig"
                  ></ckeditor>
                </validation-provider>
              </div>
              <!-- 設定封面圖片 -->
              <div class="form-group col-md-12">
                <label class="form-label w-100">設定封面圖片（當新聞稿分享到社群網站時所顯示的圖片）</label>
                <label>
                  <input type="radio" value="firstPicture" v-model="coverType" class="ml-3 mr-2"/>
                  <span class="text-muted">
                  使用您新聞中的第一張圖片作爲封面圖片。
                </span>
                </label>
                <label v-if="isDefaultExist">
                  <input type="radio" value="defaultCover" v-model="coverType" class="ml-3 mr-2"/>
                  <span class="text-muted">
                  使用您的預設封面圖片，或<span class="pointIcon" @click="editDefaultCover()">點此</span>更改您的預設封面圖片
                </span>
                </label>
                <label>
                  <input type="radio" value="uploadCover" v-model="coverType" class="ml-3 mr-2"/>
                  <span class="text-muted">另外上傳新聞稿封面圖片</span>
                  <small class="ml-2">建議大小 1200 * 628 (5 MB 內)</small>
                </label>
                <validation-provider name="cover" :rules="coverType == 'uploadCover'?'required':''" v-slot="{ errors }">
                  <span class="text-red text-bold m-2">{{ errors[0] }}</span>
                  <file-uploader
                      v-show="coverType == 'uploadCover'"
                      v-model="data.cover"
                      :spec="{
                          sizeLimit: 5000,
                          title: '文章封面圖',
                          isCrop: true,
                          autoCropWidth: 1200,
                          autoCropHeight: 628,
                          fixedNumber: [1200, 628],
                          frameWidth: 60
                    }"
                      :is-edit-mode="true"
                      @show-cropper-modal="showModal({ modalId: ModalList().CROPPER_MODAL, payload: $event})"/>
                </validation-provider>
              </div>
            </div>
            <div class="line"></div>
            <!-- 公開聯絡資訊 -->
            <div class="form-group">
              <label class="form-label w-100">公開聯絡資訊：</label>
              <div class="text-muted">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">發佈單位: {{userMeta.publisher}}</li>
                  <li class="list-group-item">新聞聯絡人: {{userMeta.contactName}}</li>
                  <li class="list-group-item">聯絡電話: {{userMeta.contactPhone}}</li>
                  <li class="list-group-item">連絡信箱: {{userMeta.contactEmail}}</li>
                  <li v-if="userMeta.contactFacebook || userMeta.contactTwitter || userMeta.contactInstagram" class="list-group-item">社群連結:
                    <template v-if="userMeta.contactFacebook">
                      <a target="_blank" :href="'https://facebook.com/'+userMeta.contactFacebook">FaceBook</a>,
                    </template>
                    <template v-if="userMeta.contactTwitter">
                      <a target="_blank" :href="'https://twitter.com/'+userMeta.contactTwitter">Twitter</a>,
                    </template>
                    <template v-if="userMeta.contactInstagram">
                      <a target="_blank" :href="'https://www.instagram.com/'+userMeta.contactInstagram">Instagram</a>
                    </template>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4" v-if="data.state == 'TEMP'">
                <button @click="saveTemp(invalid)" :disabled="invalid || isSaving" class="btn btn-lg btn-block btn-secondary m-t-30 mt-3">儲存草稿</button>
              </div>
              <div :class="data.state == 'TEMP'? 'col-md-8' : 'col-md-12'">
                <button @click="saveAndPublish(invalid)" :disabled="invalid || isSaving" class="btn btn-lg btn-block m-t-30 mt-3">儲存並發佈</button>
              </div>
            </div>
          </div>
        </validation-observer>
      </template>
      <template v-else>
        <div class="w-100">
          <div class="d-flex justify-content-center">
            <h3>
              <i class="icon-alert-circle"></i> 系統維護
            </h3>
          </div>
          <div class="d-flex justify-content-center">
            <h3>新增文章功能暫時關閉中，請耐心等待</h3>
          </div>
        </div>
        <div class="w-100 mt-4 mb-4">
          <div class="d-flex justify-content-center">
            <h5> 或 </h5>
          </div>
        </div>
        <div class="w-100">
          <div class="d-flex justify-content-center">
            <h3>
              來信申請帳號身份驗證
            </h3>
          </div>
          <div class="d-flex justify-content-center mt-3">
            <div>
              <div>收件信箱：<a :href="mailto">service@aotter.net</a></div>
              <div>信件標題：[帳號驗證申請] NewsBuffet：貴單位名稱</div>
              <div>
                注意事項：
                <ol class="ml-4">
                  <li>目前僅開放公司單位進行申請</li>
                  <li>請先至您的「資料設定頁」填寫完整對外公開資訊</li>
                  <li>請以您的公司組織 email 來信（非 Gmail、Yahoo 等公開免費信箱），並確保此 email 與您註冊本平台使用之 email 相符</li>
                  <li>
                    請來信提供
                    <ul class="ml-4">
                      <li>貴公司名稱、統一編號</li>
                      <li>聯絡人姓名、email、聯絡電話</li>
                      <li>是否曾在本平台發布過新聞稿</li>
                      <li>其他可輔佐之證明資訊</li>
                    </ul>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>

</template>

<script>

import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import RuleGroup from "@/rules/ruleGroup";
import ModalList from "@/const/modal";
import FileUploader from "@/components/common/FileUploader";
import VueTagsInput from '@johmun/vue-tags-input';
import { mapMutations } from "vuex";
import { utilMixin } from "@/mixins/utilMixin";
import { getMeta } from '@/service/api/internal/me/user';
import { getTagAutoCompleteList, getPostById, getDefaultCover, createPost, updatePost } from '@/service/api/internal/me/post';
import { uploadPostRelativeImage } from '@/service/api/internal/me/media';
import { getCategoryList } from '@/service/api/public';

Object.keys(RuleGroup).forEach((rule) => {
  extend(rule, RuleGroup[rule]);
});

export default {
  name: "PostEdit",
  mixins: [utilMixin],
  components:{
    ValidationObserver,
    ValidationProvider,
    FileUploader,
    VueTagsInput,
  },
  data(){
    return{
      isDefaultExist: false,
      coverType: 'firstPicture',
      isFinishUserMeta: false,
      isSaving: false,
      tag: '',
      tags:[],
      categoryValidState: false,
      categoryLimit: 3,
      tagsLimit: 5,
      categoryLimitMsgShow: false,
      categoryList:[],
      userMeta:{},
      data: {
        state: 'TEMP',
        category:[],
        tag:[],
        cover: null,
        defaultCover: null,
        showContact: true,
        content:''
      },
      editorConfig: {
        toolbar: [
          { name: 'document', items: [ 'Source' ] },
          { name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
          '/',
          { name: 'styles', items: [ 'Format', 'FontSize', 'Styles' ] },
          { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
          { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
          { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
          { name: 'paragraph', items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
          '/',
          { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote' ] },
          { name: 'insert', items: [ 'Image', 'Embed', 'HorizontalRule', 'Iframe' ] },
          { name: 'tools', items: [ 'Maximize', 'ShowBlocks' ] },
        ],
        extraPlugins: 'uploadimage,colorbutton,colordialog,autolink,autoembed,embed,justify,font,editorplaceholder',
        editorplaceholder: '請輸入內容。為避免圖片失效，不建議直接複製貼上圖片，且圖片大小需為 5 MB 以內',
        height: 360,
        image_prefillDimensions: false,
        image2_disableResizer: true,
        filebrowserImageUploadUrl: '/api/internal/me/media/ckupload',
        imageUploadUrl: '/api/internal/me/media/ckupload',
        // Colors from Redactor
        colorButton_colors:
            'ffffff,000000,eeece1,1f497d,4f81bd,c0504d,9bbb59,8064a2,4bacc6,f79646,ffff00,'+
            'f2f2f2,7f7f7f,ddd9c3,c6d9f0,dbe5f1,f2dcdb,ebf1dd,e5e0ec,dbeef3,fdeada,fff2ca,'+
            'd8d8d8,595959,c4bd97,8db3e2,b8cce4,e5b9b7,d7e3bc,ccc1d9,b7dde8,fbd5b5,ffe694,'+
            'bfbfbf,3f3f3f,938953,548dd4,95b3d7,d99694,c3d69b,b2a2c7,b7dde8,fac08f,f2c314,'+
            'a5a5a5,262626,494429,17365d,366092,953734,76923c,5f497a,92cddc,e36c09,c09100,'+
            '7f7f7f,0c0c0c,1d1b10,0f243e,244061,632423,4f6128,3f3151,31859b,974806,7f6000',
        colorButton_colorsPerRow: 11,
        colorButton_enableMore: true,
        embed_provider: '//iframely.aotter.net/oembed?url={url}&callback={callback}&api_key=WAZZUP_BRO_LOL'
      },
      autocompleteItems: [],
      validation: [
          {
            classes: 'max-length',
            rule: tag => tag.text.length < 2,
            disableAdd: true
          },
        {
          classes: 'no-special-characters',
          rule: /^[\u4E00-\u9FA5A-Za-z0-9]+$/,
          disableAdd: true
        }
      ],
      mailto: `mailto:service@aotter.net?subject=[帳號驗證申請] NewsBuffet：貴單位名稱&body=您好，請留下您的 (1) 貴公司名稱、統一編號 (2) 聯絡人姓名、email、聯絡電話 (3) 是否曾在本平台發布過新聞稿 (4) 其他可輔佐之證明資訊，謝謝。`
    }
  },
  watch:{
    isCategoryValid(){
      this.$nextTick(this.$refs.categoryValidator.validate)
    },
    coverType(newValue){
      this.data.defaultCover = newValue == 'defaultCover'
    }
  },
  computed:{
    categoryLimitMsg(){
      return `至多只能選擇 ${this.categoryLimit} 個分類`
    },
    isCategoryValid(){
      return this.data.category.length > 0
    },
    filteredItems() {
      return this.autocompleteItems.filter(i => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
      });
    },
    postId(){
      return this.$route.query.id
    },
    isNotDefaultCover(){
      return !this.data.defaultCover
    },
    isCoverNeedUpload(){
      return this.data.cover instanceof File
    }
  },
  created() {
    this.getAllCategoryList()
    this.getUserMetaData()
    this.getAutocompleteItems()
    this.checkIsDefaultCoverExist()
    if(this.postId){
      this.getPost(this.postId)
    }
  },
  methods:{
    ...mapMutations(['showModal','hideModal']),
    async getAllCategoryList(){
      await getCategoryList().then(categoryList =>{
        this.categoryList = categoryList
      })
    },
    categorySelected(id){
      let selected = false
      if(this.data.category.includes(id)){
        selected = true
      }
      return selected
    },
    async getUserMetaData(){
      let res = await getMeta()
      if(res.status == 200){
        this.userMeta = res.data
        this.isFinishUserMeta = true
      }
    },
    async getAutocompleteItems(){
      await getTagAutoCompleteList().then(autocompleteItems =>{
        this.autocompleteItems = autocompleteItems
      })
    },
    async getPost(id){
      await getPostById(id).then(post =>{
        this.data = post
        this.data.showContact = true
        if(post.tag){
          this.tags = this.getTagList()
        }
        this.convertToCategoryList()
      })
    },
    getTagList(){
      let tagList = []
      this.data.tag.map(tag => {
        tagList.push({text: tag})
      })
      return tagList
    },
    async checkIsDefaultCoverExist(){
      let res = await getDefaultCover()
      if(res.status == 200){
        this.isDefaultExist = true
      }
    },
    convertToCategoryList(){
      let list = []
      this.data.category.forEach(category =>{
        category.subCategory.forEach( subCategory =>{
          list.push(subCategory.id)
        })
      })
      this.data.category = list
    },
    toggleCategory(id){
      let currentCategoryList = this.data.category
      let isChecked = currentCategoryList.includes(id)
      this.categoryLimitMsgShow = false
      if(isChecked){
        const index = currentCategoryList.indexOf(id)
        this.data.category.splice(index, 1)
      } else {
        if(currentCategoryList.length < 3){
          this.data.category.push(id)
        }else {
          this.categoryLimitMsgShow = true
        }
      }
    },
    checkTagValid(newTags){
      let tagsLength = newTags.length
      if(tagsLength >= 6){
        let deleteCount =  tagsLength - this.tagsLimit
        newTags.splice(this.tagsLimit, deleteCount)
      }else if(tagsLength > 0){
        let lastIndex = tagsLength - 1
        if(!newTags[lastIndex].tiClasses.includes('ti-valid')){
          newTags.splice(lastIndex, 1)
        }
      }
      let data = []
      newTags.forEach(tag =>{
        data.push(tag.text)
      })
      this.data.tag = data
    },
    editDefaultCover(){
      this.showModal({modalId: ModalList.DEFAULT_COVER_MODAL})
    },
    async saveTemp(invalid){
      if(!invalid){
        this.isSaving = true
        if(this.isNotDefaultCover && this.isCoverNeedUpload){
          await this.uploadCover(this.saveTemp)
        } else {
          if(this.postId){
            await updatePost(this.postId, JSON.stringify(this.data))
                .then(res =>{
                  this.isSaving = false
                  if(res.status == 200){
                    this.showSuccessModal({
                      maxCountDownSec: 3,
                      content: `已成功更新文章：${this.data.title}`,
                      redirectPath: '/me/post-list'
                    })
                  }else{
                    this.showErrorModal()
                  }
                })
          }else {
            await createPost(JSON.stringify(this.data))
                .then(res =>{
                  this.isSaving = false
                  if(res.status == 200){
                    this.showSuccessModal({
                      maxCountDownSec: 3,
                      content: `已成功儲存文章：${this.data.title}`,
                      redirectPath: '/me/post-list'
                    })
                  }else{
                    this.showErrorModal()
                  }
                })
          }
        }
      }
    },
    async saveAndPublish(invalid){
      if(!invalid){
        this.isSaving = true
        if(this.isNotDefaultCover && this.isCoverNeedUpload){
          await this.uploadCover(this.saveAndPublish)
        } else {
          if(this.postId){
            await updatePost(this.postId, JSON.stringify({...this.data, state : 'PUBLISHED'}))
                .then(res =>{
                  this.isSaving = false
                  if(res.status == 200){
                    this.showSuccessModal({
                      maxCountDownSec: 3,
                      content: `已成功更新發佈文章：${this.data.title}`,
                      redirectPath: '/me/post-list'
                    })
                  }else{
                    this.showErrorModal()
                  }
                })
          }else {
            await createPost(JSON.stringify({...this.data, state : 'PUBLISHED'}))
                .then(res =>{
                  this.isSaving = false
                  if(res.status == 200){
                    this.showSuccessModal({
                      maxCountDownSec: 3,
                      content: `已成功發布文章：${this.data.title}`,
                      redirectPath: '/me/post-list'
                    })
                  }else{
                    this.showErrorModal()
                  }
                })
          }
        }
      }
    },
    async uploadCover(callback){
      const formData = new FormData()
      formData.append('file', this.data.cover)

      await uploadPostRelativeImage('cover', formData).then(data => {
        if(data){
          this.data.cover = data.url
          callback()
        }
      })
    }
  }
}
</script>

<style>
.pointIcon{
  cursor: pointer;
  color: rgb(24 135 255)
}

.btn-nostyle{
  background-color: #efefef;
  border-width: 0px
}

.ck-editor__editable {
  min-height: 300px;
}

.ck-editor__editable ol,
.ck-editor__editable ul{
  padding-inline-start: 40px;
}

.ti-icon-close:before {
  font-family: 'icomoon' !important;
  content: "\e955";
}

.vue-tags-input{
  max-width: unset !important;
}
</style>
