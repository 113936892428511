<template>

<div :ref="modalId" class="modal fade" role="dialog" aria-hidden="true" data-keyboard="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="m-2 text-secondary text-center">
          將於
          <span class="text-red text-bold mr-2 ml-2" style="font-size: 1.2rem;">{{ currentCountDownSec }}</span>
          秒內自動關閉提示視窗
        </div>
        <div class="text-center m-2">
          <i class="fa fa-8x fa-check-circle text-success"></i>
        </div>
        <div class="m-3 fa-2x text-bold text-center text-secondary">成功</div>
        <div class="text-bold text-center">{{ data.content }}</div>
      </div>
    </div>
  </div>
</div>

</template>

<script>
import $ from "jquery";
import ModalList from "@/const/modal";

export default {
  name: "SuccessModal",
  data(){
    return {
      modalId: ModalList.SUCCESS_MODAL,
      counter: null,
      currentCountDownSec: 10,
      data: {
        maxCountDownSec: 10,
        content: '',
        redirectPath:''
      }
    }
  },
  mounted() {
    $(this.$refs[this.modalId]).on('shown.bs.modal',() => {
      this.startCountDown()
    })

    $(this.$refs[this.modalId]).on('hide.bs.modal', () => {
      window.clearInterval(this.counter)
    })

    $(this.$refs[this.modalId]).on('hidden.bs.modal', () => {
      if(this.data.redirectPath){
        this.$router.push(this.data.redirectPath)
      }
    })
  },
  methods: {
    show(data){
      data = data ? data : {}
      this.data.content = data.content ? data.content : ''
      this.data.redirectPath = data.redirectPath ? data.redirectPath : ''
      this.data.maxCountDownSec = data.maxCountDownSec ? data.maxCountDownSec : 10
      this.currentCountDownSec = this.data.maxCountDownSec

      $(this.$refs[this.modalId]).modal('show')
    },
    hide(){
      $(this.$refs[this.modalId]).modal('hide')
    },
    startCountDown(){
      this.counter = window.setInterval(()=>{
        this.currentCountDownSec--

        if(this.currentCountDownSec == 0){
          this.hide()
        }
      },1000)
    }
  }
}
</script>

<style scoped>

</style>
