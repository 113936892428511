<template>
  <div :ref="modalId" class="modal fade" role="dialog" aria-hidden="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header text-bold text-center">
          提示
        </div>
        <div class="modal-body row text-break">
          <i class="col-4 fas fa-exclamation fa-5x align-self-center text-center"></i>
          <div id="modalHintContent" class="col-8 align-self-center text-bold text-secondary">
            <div v-if="data.role == 'admin'">是否要撤文？</div>
            <div v-else >{{data.state == 'TEMP' ? '是否要刪除新聞稿？' : '是否要將新聞稿下架？'}}</div>
            <div class="text-primary mt-2">新聞稿編號</div>
            {{data.postId}}
            <div class="text-primary mt-2">新聞稿標題</div>
            <div>{{data.title}}</div>
            <div class="text-red mt-2" v-if="data.role == 'admin'">請注意：一旦撤文後，該篇新聞稿無法再次刊登</div>
            <div class="text-red mt-2" v-else >{{data.state == 'TEMP' ? '請注意：一旦刪除無法恢復' : '請注意：一旦下架後，該篇新聞稿無法再次刊登'}}</div>
          </div>
        </div>
        <div class="modal-footer border-0">
          <button type="button" class="btn btn-info" data-dismiss="modal" @click="hide">取消</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" @click="confirmDelete">確定</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import ModalList from "@/const/modal";
import { mapMutations } from "vuex";

export default {
  name: "PostDeleteModal",
  data(){
    return {
      modalId: ModalList.POST_DELETE_MODAL,
      data: {
        role: '',
        postId: '',
        title: '',
        state:'',
        delete: () => {}
      }
    }
  },
  methods: {
    ...mapMutations({
      showModal:'showModal'
    }),
    show(data){
      data = data ? data : {}
      this.data.role = data.role ? data.role : ''
      this.data.postId = data.postId ? data.postId : ''
      this.data.title = data.title ? data.title : ''
      this.data.state = data.state ? data.state : 'TEMP'
      this.data.delete = data.delete ? data.delete : null

      $(this.$refs[this.modalId]).modal('show')
    },
    hide(){
      $(this.$refs[this.modalId]).modal('hide')
    },
    confirmDelete(){
      this.data.delete(this.data)
    }
  }
}
</script>

<style scoped>
</style>
