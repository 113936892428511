import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import moment from 'moment';
Vue.filter('timeString', function (value, myFormat) {
  return moment(value).format(myFormat || 'YYYY/MM/DD');
});

import Vuex from "vuex";
Vue.use(Vuex);

import VueCroppie from 'vue-croppie';
import 'croppie/croppie.css'
Vue.use(VueCroppie);

import VueCropper from "vue-cropper";
Vue.use(VueCropper);

import VueMeta from "vue-meta";
Vue.use(VueMeta,{
  refreshOnceOnNavigation: true
});

import CKEditor from 'ckeditor4-vue';
Vue.use(CKEditor);

import VueTagsInput from '@johmun/vue-tags-input';
Vue.use(VueTagsInput);

import VueMobileDetection from "vue-mobile-detection";
Vue.use(VueMobileDetection)

import Chakra from '@chakra-ui/vue';
Vue.use(Chakra, chakraConfig)

import VueAxios from 'vue-axios'
import axios from './plugins/axios'
Vue.use(VueAxios, axios)  // for 可以使用 this.axios or this.$http...

import {JsonForms} from "@jsonforms/vue2";
Vue.use(JsonForms)

import AotterUI, { CReset, CThemeProvider, chakraConfig } from '@aotter/aotter-ui';
Vue.use(AotterUI, { global: true })

import VueCompositionApi from '@vue/composition-api';
Vue.use(VueCompositionApi)

import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(CThemeProvider, [h(CReset), h(App)])
}).$mount('#app')
