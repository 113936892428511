<template>
  <div :ref="modalId" class="modal fade" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header text-bold text-center">
          即將開始推廣
        </div>
        <div class="modal-body row text-break">
          <i class="col-4 fas fa-exclamation fa-5x align-self-center text-center"></i>
          <div id="modalHintContent" class="col-8 align-self-center text-bold text-secondary">
            <div>確定以開始日期：{{content}} 開始推廣嗎？</div>
          </div>
        </div>
        <div class="modal-footer border-0">
          <button type="button" class="btn btn-info" @click="hide">取消</button>
          <button type="button" class="btn btn-primary" @click="approvePromotion">確定</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import ModalList from "@/const/modal";
import { utilMixin } from "@/mixins/utilMixin";
import { mapMutations } from "vuex";
import { approvePromotion } from "@/service/api/internal/admin/promotion";

export default {
  name: "PromotionApproveModal",
  mixins: [utilMixin],
  data(){
    return {
      modalId: ModalList.PROMOTION_APPROVE_MODAL,
      id: '',
      content: '',
      promotion: {},
      execFunc: null
    }
  },
  methods: {
    ...mapMutations({
      showModal:'showModal'
    }),
    show(data){
      this.id = data.id ? data.id : ''
      this.content = data.content ? data.content : ''
      this.promotion = data.promotion ? data.promotion : {}
      this.execFunc = data.execFunc ? data.execFunc : null

      $(this.$refs[this.modalId]).modal('show')
    },
    hide(){
      $(this.$refs[this.modalId]).modal('hide')
    },
    async approvePromotion(){
      this.hide()
      await approvePromotion(this.id).then(res =>{
        this.hide()
        if(res.status == 200){
          if(this.execFunc){
            this.execFunc()
            this.showSuccessModal({ maxCountDownSec: 3, content: `已通過推廣 ${this.promotion.name}` })
          }
        }else{
          this.showErrorModal()
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
