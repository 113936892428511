<template>
  <div :ref="modalId" class="modal fade" role="dialog" aria-hidden="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header text-bold text-center">
          提示
        </div>
        <div class="modal-body row text-break">
          <i class="col-4 fas fa-exclamation fa-5x align-self-center text-center"></i>
          <div id="modalHintContent" class="col-8 align-self-center text-bold text-secondary">
            <div>{{title}}</div>
            <div>{{content}}</div>
            <div class="text-primary mt-2">使用者編號</div>
            {{data.userId}}
            <div class="text-primary mt-2">電子信箱</div>
            <div>{{data.email}}</div>
            <div>
              <div class="form-group mt-2">
                <label for="note">管理員備註(必填)</label>
                <textarea class="form-control" id="note" rows="3" v-model="data.note"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0">
          <button type="button"
                  class="btn btn-info"
                  data-dismiss="modal"
                  @click="hide">取消</button>
          <button type="button"
                  class="btn btn-primary"
                  data-dismiss="modal"
                  :disabled="data.note == ''"
                  @click="confirmUpdate">確定</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import ModalList from "@/const/modal";
import { mapMutations } from "vuex";

export default {
  name: "UpdateUserIdentifyVerifyModal",
  data(){
    return {
      modalId: ModalList.UPDATE_USER_IDENTIFY_VERIFY_MODAL,
      title: '',
      content: '',
      data: {
        identifyVerify: '',
        userId: '',
        email: '',
        note: '',
        getList: () => {},
        update: () => {}
      }
    }
  },
  methods: {
    ...mapMutations({
      showModal: 'showModal'
    }),
    show(data){
      data = data ? data : {}
      this.data.identifyVerify = data.identifyVerify ? data.identifyVerify : false
      this.data.userId = data.userId ? data.userId : ''
      this.data.email = data.email ? data.email : ''
      this.data.update = data.update ? data.update : null
      this.data.note = ''
      if(this.data.identifyVerify){
        this.title = "確認是否將通過使用者的身份驗證？"
        this.content = "通過身份驗證後，使用者將可以使用發文功能。"
      }else {
        this.title = "確認是否將取消使用者的身份驗證？"
        this.content = "取消身份驗證後，使用者將無法使用發文功能。"
      }
      $(this.$refs[this.modalId]).modal('show')
    },
    hide(){
      $(this.$refs[this.modalId]).modal('hide')
    },
    confirmUpdate(){
      this.data.update(this.data)
    }
  }
}
</script>

<style scoped>

</style>
