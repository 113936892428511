// Important! not create the same function name or data name in component

import {updateAllManualSelectedOrder, updateManualSelected} from "@/service/api/internal/admin/manualSelected";
import Const from "@/const";
import ModalList from "@/const/modal";
import {mapMutations} from "vuex";

export const adminMixin = {
    methods: {
        ...mapMutations(['showModal']),
        async updateOrder(type, item, newOrder, getListFunc){
            let response = await updateManualSelected(type, item.id, JSON.stringify({order: newOrder}))
            if (response.status > 299) {
                this.showErrorModal()
            }else{
                await getListFunc(this.currentPage)
            }
        },
        async reOrderList(type){
            let response = await updateAllManualSelectedOrder(type)
            if (response.status > 299) {
                this.showErrorModal()
            }
        },
        showManualSelectedModal(type, state, data, getListFunc){
            let payload
            if(state == Const.ACTIVE){
                payload = { type: type, mode: 'edit', ...data, execFunc: getListFunc}
            }else {
                payload = { type: type, mode: 'create', ...data, execFunc: getListFunc}
            }
            this.showModal({ modalId: ModalList.MANUAL_SELECTED_MODAL, payload})
        },
    }
}
