import axios from "@/plugins/axios";

// siteConfig 相關的 api
let baseUrl = '/'

if(process.env.VUE_APP_baseUrl) {
    baseUrl = process.env.VUE_APP_baseUrl
}

baseUrl += 'api/internal/admin/site'

// get site config
export function findSiteConfig(){
    return axios.get(baseUrl).then(res=>res.data)
}

// update site config
export function updateSiteConfig(dto){
    return axios.put(baseUrl, dto, { headers: {'Content-Type': 'application/json'} })
}
