import axios from "@/plugins/axios";

// transaction 相關的 api
let baseUrl = '/'

if(process.env.VUE_APP_baseUrl) {
    baseUrl = process.env.VUE_APP_baseUrl
}

baseUrl += 'api/internal/me/transaction'

// check AotterPay status
export function checkAotterPayStatus(token){
    return axios.get(`${baseUrl}/check?token=${token}`).then(res=>res.data)
}

// find all Transaction
export function findTransactionList(query){
    return axios.get(`${baseUrl}/list?${query}`).then(res=>res.data)
}

// get AotterPay Payment Page Url
export function getAotterPayPaymentPageUrl(orderId){
    return axios.get(`${baseUrl}/${orderId}/pay/page`).then(res=>res.data)
}

// check is any transaction still not pay and check is still available to pay
export function getPendingPromotion(postId){
    return axios.get(`${baseUrl}/pending-promotion?postId=${postId}`)
}
