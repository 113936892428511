<template>
  <div>
    <section id="page-content" class="sidebar-right">
      <div class="container">
        <div class="row">
          <!-- content -->
          <div class="content col-lg-9">
            <PostContent @copy-link="copyLink(post.shortUrl)"/>
            <!-- 你可能還想看廣告區 -->
            <!-- Begin Dable article_bottom / For inquiries, visit http://dable.io -->
            <div id="dablewidget_3o5NBk7j" data-widget_id="3o5NBk7j" class="pt-5"></div>
            <!-- End article_bottom / For inquiries, visit http://dable.io -->
            <!-- 你可能還想看 -->
          </div>
          <!-- end: content -->
          <!-- Sidebar-->
          <div class="sidebar sticky-sidebar col-lg-3">
            <div id="widget-shortlink-area">
              <div class="input-group">
                <input type="text" class="form-control" :placeholder="post.shortUrl" aria-label="suprlink" aria-describedby="basic-addon1">
                <div class="input-group-append">
                  <button class="btn" @click="copyLink(post.shortUrl)"><i class="fa fa-copy"></i></button>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <a @click="checkIsAnyTransactionExists(post)"
                     class="btn btn-block promotion-btn">
                      <img class="icon-megaphone mt-1 mr-1" src="@/assets/images/icon-custom-megphone.svg"/>
                      <span>推廣新聞稿</span>
                  </a>
                </div>
              </div>
            </div>
            <PostUserInfo :showContact="post.showContact"></PostUserInfo>
            <SideBarTab/>
            <HotTags/>
          </div>
          <!-- end: Sidebar-->
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import PostContent from '@/components/public/PostContent.vue'
import PostUserInfo from '@/components/public/PostUserInfo.vue'
import SideBarTab from '@/components/public/SideBarPostsTab.vue'
import HotTags from '@/components/public/HotTags.vue'
import { mapMutations } from "vuex"
import ModalList from "@/const/modal";
import Const from "@/const";
import { findPost, findPreviousOrNextPost } from '@/service/api/public';
import { getPendingPromotion } from '@/service/api/internal/me/transaction';

export default {
  name: "PublicPostContent",
  components: {
    PostContent,
    PostUserInfo,
    SideBarTab,
    HotTags
  },
  metaInfo(){
    return {
      title: '| NewsBuffet',
      titleTemplate: `${this.post.title}  %s`,
      meta: this.$store.state.postContentMetaInfo
    }
  },
  computed:{
    post(){
      return this.$store.state.post ?  this.$store.state.post : {}
    }
  },
  created() {
    if(this.$route.params.id != undefined && this.$route.params.id != 'error.html'){
      this.getPostContent()
    }
  },
  mounted() {
    if(Const.PROD_SERVER_URL_BASE.includes(window.location.origin)) {
      let invokeScript = document.createElement("script");
      invokeScript.innerHTML = `
        (function (d, a, b, l, e, _) {
            if (d[b] && d[b].q) return; d[b] = function () { (d[b].q = d[b].q || []).push(arguments) }; e = a.createElement(l);
            e.async = 1; e.charset = 'utf-8'; e.src = '//static.dable.io/dist/plugin.min.js';
            _ = a.getElementsByTagName(l)[0]; _.parentNode.insertBefore(e, _);
            })(window, document, 'dable', 'script');
            dable('setService', 'nb.aotter.net');
            dable('sendLogOnce');
            dable('renderWidget', 'dablewidget_3o5NBk7j');
    `
      document.getElementById("dablewidget_3o5NBk7j").appendChild(invokeScript);
    }
  },
  methods: {
    ...mapMutations(['updatePost', 'updatePreviousPost', 'updateNextPost', 'showModal', 'updatePostContentMetaInfo']),
    async getPostContent() {
      await findPost(this.$route.params.id)
          .then(res => {
            if (res.status == 200) {
              this.getPreviousPost()
              this.getNextPost()
              return res.data
            }
          }).then(post => {
            if (post) {
              let payload = JSON.parse(JSON.stringify(post))
              this.checkTitle(payload.seoTitle, payload.title)
              this.updatePost(payload)
              this.updatePostContentMetaInfo(payload)
            } else {
              this.$router.push("/error.html")
            }
          })
    },
    checkTitle(seoTitle, title) {
      let paramsTitle = this.$route.params.title
      if (paramsTitle != undefined) {
        if (title != paramsTitle && seoTitle != paramsTitle) {
          this.$router.push("/error.html")
        }
      }
    },
    async getPreviousPost() {
      await findPreviousOrNextPost(this.$route.params.id, 'previous')
          .then(previousPost => {
            this.updatePreviousPost(previousPost)
          })
    },
    async getNextPost() {
      await findPreviousOrNextPost(this.$route.params.id, 'next')
          .then(nextPost => {
            this.updateNextPost(nextPost)
          })
    },
    copyLink(link) {
      const input = document.createElement('input')
      document.body.appendChild(input)
      input.value = link
      input.select()
      document.execCommand('copy')
      document.body.removeChild(input)
      this.showModal({
        modalId: ModalList.CHECK_MODAL,
        payload: {
          title: '成功',
          content: '已複製短網址',
          url: link
        }
      })
    },
    async checkIsAnyTransactionExists(post){
      await getPendingPromotion(post.id)
          .then(res =>{
            switch (res.status){
              case 204: {
                this.showModal({
                  modalId: ModalList.PROMOTION_MODAL,
                  payload: {
                    promotion: {
                      postId: post.id,
                      name: post.title,
                      text: post.summary,
                      imgMain: post.cover
                    }
                  }
                })
                break;
              }
              case 200:
                return res.data
            }
          }).then( transaction =>{
            if(transaction){
              this.showModal({modalId: ModalList.TRANSACTION_PENDING_MODAL,
                payload: {
                  title: '本文章有尚未完成付款的推廣訂單：',
                  orderId: transaction.orderId,
                  description: '您前一次操作並未完成流程。按下確認後，系統將為您檢查訂單狀態。若仍為有效，則會引導您繼續前往付款。若已失效，請您返回本頁，重新下單。'
                }})
            }
          })
    }
  }
}
</script>

<style scoped>

/* start of new version of promotion-btn */
.promotion-btn{
  background: linear-gradient(90deg, #6A11CB 0%, #2575FC 100%);
  box-shadow: 2px 2px 0px rgba(15, 70, 119, 0.4) !important;
  color: white !important;
  transition: linear .2s !important;
  position: relative;
  border: none !important;
}

.promotion-btn > span {
  position: relative;
  z-index: 3;
}

.promotion-btn > .icon-megaphone {
  position: relative;
  z-index: 3;
  display: inline-flex;
  width: 20px !important;
  height: 18px!important;
  margin: 3px 6px 0 0;
  transition: cubic-bezier(0.39, 0.58, 0.57, 1) .3s;
}

.promotion-btn:before {
  content:'';
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #2575FC 0%, #4744E4 25.52%, #6A11CB 100%);
  opacity: 0;
  transition: cubic-bezier(0.39, 0.58, 0.57, 1) .3s;
  border-radius: inherit;
}

.promotion-btn:hover{
  box-shadow: none!important;
  color:#FFDA55!important;
  transition: cubic-bezier(0.39, 0.58, 0.57, 1) .3s;
}

.promotion-btn:hover > img.icon-megaphone{
  filter: invert(0%) sepia(52%) saturate(7129%) hue-rotate(333deg) brightness(96%) contrast(93%);
  transition: cubic-bezier(0.39, 0.58, 0.57, 1) .3s;
}

.promotion-btn:hover:before {
  opacity: 1;
  transition: cubic-bezier(0.39, 0.58, 0.57, 1) .3s;
}
/* end of new version of promotion-btn */

</style>
