<template>
  <div :ref="modalId" id="cropper-modal" class="modal fade" role="dialog" aria-labelledby="imageModal" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="imageModal">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <div class="mb-2 text-center text-secondary">請利用拖曳、縮放、旋轉功能調整圖片</div>
          <div v-if="isInit" :style="getFrameSize()">
            <vue-cropper ref="cropper"
                         :img="option.img"
                         :output-size="option.size"
                         :output-type="option.outputType"
                         :info="option.info"
                         :full="option.full"
                         :fixed="option.fixed"
                         :fixed-number="option.fixedNumber"
                         :can-move="option.canMove"
                         :can-move-box="option.canMoveBox"
                         :fixed-box="option.fixedBox"
                         :original="option.original"
                         :auto-crop="option.autoCrop"
                         :auto-crop-width="option.autoCropWidth"
                         :auto-crop-height="option.autoCropHeight"
                         :center-box="option.centerBox"
                         :high="option.high"
                         :max-img-size="option.max"
                         :enlarge="option.enlarge"
                         @real-time="realTime"/>
          </div>
          <div class="d-flex justify-content-center mt-3" style="font-size: medium;">
            <button type="button" class="btn btn-light ms-1 border border-secondary" @click="changeScale(1)">放大 +</button>
            <button type="button" class="btn btn-light ms-1 border border-secondary" @click="changeScale(-1)">縮小 -</button>
            <button type="button" class="btn btn-light ms-1 border border-secondary" @click="rotateLeft">
              向左選轉<i class="icon-corner-up-left"></i>
            </button>
            <button type="button" class="btn btn-light ms-1 border border-secondary" @click="rotateRight">
              向右選轉<i class="icon-corner-up-right"></i>
            </button>
          </div>
        </div>
        <div class="modal-footer border-0 center" >
          <button type="button" class="btn btn-secondary" @click="hide">取消</button>
          <button type="button" class="btn btn-primary" @click="save">確定</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex"
import $ from "jquery";
import ModalList from "@/const/modal"

export default {
  name: "CropperModal",
  data(){
    return{
      modalId: ModalList.CROPPER_MODAL,
      modal: null,
      targetId: '',
      prevModalId: '',
      title: '裁切圖片',
      isInit: false,
      // 是否開始截圖
      crap: false,
      option: {
        // 裁剪圖片相關
        // 裁剪圖片的URL 或 base64
        img: '',
        // 上傳圖片按照原始比例渲染
        original: false,
        // 上傳圖片是否可以移動
        canMove: true,
        // 限制图片最大宽度和高度
        max: 2000,

        // 截圖框
        // 是否默認生成截圖框
        autoCrop: true,
        // 截圖框能否拖動
        canMoveBox: true,
        // 固定截圖框大小
        fixedBox: true,
        // 截圖框是否被限制在圖片里面
        centerBox: false,
        // 截圖框的大小信息
        info:true,
        // 截圖框是否寬高固定比例
        fixed: true,
        // 截圖框寬高比例
        fixedNumber: [2, 1],
        // 默认生成截图框宽度
        autoCropWidth: 300,
        // 默认生成截图框高度
        autoCropHeight: 157,

        // output
        // 是否輸出原圖比例的截圖
        full: false,
        // 裁剪生成圖片的质量
        size: 1,
        // 裁剪生成圖片的格式
        outputType: 'png',
        // 是否按照設備的dpr 輸出等比例圖片
        high: true,
        // 图片根据截图框输出比例倍数
        enlarge: 4
      },
      returnData: {},
      showPrevModalWithData: false,
      isSave: false
    }
  },
  mounted() {
    let modalElement = this.$refs[this.modalId]
    this.modal = modalElement

    $(this.modal).on('hidden.bs.modal', () => {
      this.isInit = false
      if(this.prevModalId){
        if(this.showPrevModalWithData){
          this.showModal({
            modalId: this.prevModalId,
            payload: {
              showPrevData: true,
              ...this.returnData
            }
          })
        } else {
          this.showModal({
            modalId: this.prevModalId,
            payload: {
              showPrevData: true
            }
          })
        }
      }

      if(this.targetId && this.isSave){
        this.setUploaderData({
          id: this.targetId,
          data: this.returnData
        })
      }
    })

    $(this.modal).on('shown.bs.modal', () => {
      this.isInit = true
    })
  },
  methods: {
    ...mapMutations(['showModal','setUploaderData']),
    show(data){
      this.title = data.title ? data.title : '裁切圖片'
      this.option.img = data.url ? data.url : null
      this.option.fixedNumber = data.fixedNumber ? data.fixedNumber: [2,1]
      this.option.autoCropWidth = data.autoCropWidth ? data.autoCropWidth: 300
      this.option.autoCropHeight = data.autoCropHeight ? data.autoCropHeight: 150
      this.targetId = data.id ? data.id : null
      this.prevModalId = data.from ? data.from : null
      this.returnData = {}
      this.isSave = false

      $(this.modal).modal('show')
    },
    hide(){
      this.showPrevModalWithData = false
      $(this.modal).modal('hide')
    },
    save(){
      this.showPrevModalWithData = true
      this.isSave = true
      this.crop().then(result => {
        this.returnData = result
        $(this.modal).modal('hide')
      })
    },
    crop() {
      return new Promise(resolve => {
        this.$refs.cropper.getCropBlob(data => {
          let result = {
            file: new File([data], "image.png"),
            url: URL.createObjectURL(data)
          }
          resolve(result);
        })
      });
    },
    changeScale(num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },
    rotateLeft() {
      this.$refs.cropper.rotateLeft()
    },
    rotateRight() {
      this.$refs.cropper.rotateRight()
    },
    getFrameSize(){
      return {
        width: '100%',
        height: '400px'
      }
    },
    realTime(data) {
      this.option.enlarge = this.option.autoCropWidth / data.w
    },
  }
}
</script>

<style scoped>

/*i.corner-up-right {*/
/*  background-image: url('~@/assets/images/icons/corner-up-right.svg');*/
/*  background-repeat: no-repeat;*/
/*  background-size:cover;*/
/*  display: inline-block;*/
/*  height: 20px;*/
/*  width: 20px;*/
/*}*/

/*i.corner-up-left {*/
/*  background-image: url('~@/assets/images/icons/corner-up-left.svg');*/
/*  background-repeat: no-repeat;*/
/*  background-size:cover;*/
/*  display: inline-block;*/
/*  height: 20px;*/
/*  width: 20px;*/
/*}*/


</style>
