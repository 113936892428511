const admin = {
    namespaced: true,
    state: {
        adminTab: {
            userList: {
                tabName: "使用者管理",
                pathName: "UserList",
                path: '/admin/user-list'
            },
            rssList: {
                tabName: "RSS匯入管理",
                pathName: "RSSList",
                path: '/admin/rss-list'
            },
            pinPost: {
                tabName: "置頂區管理",
                pathName: "PinPost",
                path: '/admin/pin-post'
            },
            selectedPost: {
                tabName: "精選新聞稿管理",
                pathName: "SelectedPost",
                path: '/admin/selected-post'
            },
            specialSelectedPost: {
                tabName: "特選新聞稿管理",
                pathName: "SpecialSelectedPost",
                path: '/admin/special-selected-post'
            },
            hotTag: {
                tabName: "熱門標籤管理",
                pathName: "HotTag",
                path: '/admin/hot-tag'
            },
            allPostList: {
                tabName: "新聞稿管理",
                pathName: "AllPostList",
                path: '/admin/all-post-list'
            },
            allPromotionList: {
                tabName: "新聞稿推廣管理",
                pathName: "AllPromotionList",
                path: '/admin/all-promotion-list'
            },
            siteConfig: {
                tabName: "全站設定",
                pathName: "SiteConfig",
                path: '/admin/site-config'
            }
        },
        adminPromotionState: {
            INITIAL: {
                name: '等待付款',
                style: 'text-dark',
                class: 'bg-dark'
            },
            REVIEWING: {
                name: '未審核',
                style: 'text-warning',
                class: 'bg-warning'
            },
            PENDING: {
                name: '等待開始',
                style: 'text-info',
                class: 'bg-info'
            },
            REJECT: {
                name: '審核未通過',
                style: 'text-danger',
                class: 'bg-danger'
            },
            PROGRESS: {
                name: '推廣進行中',
                style: 'text-success',
                class: 'bg-success'
            },
            COMPLETED: {
                name: '推廣結束',
                style: 'text-primary',
                class: 'bg-primary'
            },
            DELETED: {
                name: '付款失敗',
                style: 'text-secondary',
                class: 'bg-secondary'
            }
        },
    },
    getters: {
        getAdminTabName: (state) => (tab) => {
            let name = ''
            for (const [key, value] of Object.entries(state.adminTab)) {
                if(key == tab){
                    name =  value.tabName
                }
            }
            return name
        },
        getAdminPromotionStateName: (state) => (promotionState) => {
            let name = ''
            for (const [key, value] of Object.entries(state.adminPromotionState)) {
                if(key == promotionState){
                    name =  value.name
                }
            }
            return name
        },
        getAdminPromotionStateStyle: (state) => (promotionState) => {
            let style = ''
            for (const [key, value] of Object.entries(state.adminPromotionState)) {
                if(key == promotionState){
                    style =  value.style
                }
            }
            return style
        },
        getAdminPromotionStateClass: (state) => (promotionState) => {
            let stateClass = ''
            for (const [key, value] of Object.entries(state.adminPromotionState)) {
                if(key == promotionState){
                    stateClass =  value.class
                }
            }
            return stateClass
        },
    }
}

export default {
    admin
}
