import axios from "@/plugins/axios";

// post 相關的 api
let baseUrl = '/'

if(process.env.VUE_APP_baseUrl) {
    baseUrl = process.env.VUE_APP_baseUrl
}

baseUrl += 'api/internal/me/post'

// create a post
export function createPost(postDTO) {
    return axios.post(baseUrl, postDTO, { headers: {'Content-Type': 'application/json'} })
}

// find a post
export function getPostById(id) {
    return axios.get(`${baseUrl}/${id}`).then(res=>res.data)
}

// update a post
export function updatePost(id, postDTO) {
    return axios.put(`${baseUrl}/${id}`, postDTO, { headers: {'Content-Type': 'application/json'} })
}

// delete a post
export function deletePost(id, state) {
    return axios.delete(`${baseUrl}/${id}?state=${state}`)
}

// find user post list
export function findAllPost(query) {
    return axios.get(`${baseUrl}/list?${query}`).then(res=>res.data)
}

// create SuprLink
export function existsPostCreateSuprLink(id) {
    return axios.put(`${baseUrl}/${id}/suprlink`)
}

// get tag auto complete list
export function getTagAutoCompleteList() {
    return axios.get(`${baseUrl}/tag/autocompleteList`).then(res=>res.data)
}

// create a default cover
export function createDefaultCover(formData) {
    return axios.post(`${baseUrl}/default`, formData, { headers: {'Content-Type': 'multipart/form-data'} })
}

// get default cover
export function getDefaultCover() {
    return axios.get(`${baseUrl}/default/cover`)
}

// get post record list
export function findPostRecordList(postId) {
    return axios.get(`${baseUrl}/${postId}/record`).then(res=>res.data)
}

// get post record
export function findPostRecord(postId, recordId) {
    return axios.get(`${baseUrl}/${postId}/record/${recordId}`).then(res=>res.data)
}
