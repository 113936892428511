<template>
  <div v-show="isShow" class="widget">
    <div class="tabs">
      <ul class="nav nav-tabs" id="tabs-posts" role="tablist">
        <template v-for="(tab,index) in filteredTabList">
          <li class="nav-item"  :key="tab.key">
            <a :ref="`tab-${index}`" class="nav-link" :class="{'active': index == 0}" data-toggle="tab" :href="`#${tab.key}`" role="tab" aria-controls="popular" aria-selected="true">{{ tab.name }}</a>
          </li>
        </template>
      </ul>
      <div class="tab-content" id="tabs-posts-content"><!-- 皆給六則 -->
        <template v-for="(tab,index) in filteredTabList">
          <div class="tab-pane fade show" :class="{'active': index == 0}" :id="tab.key" role="tabpanel" :aria-labelledby="`${tab.key}-tab`" :key="tab.key">
            <div class="post-thumbnail-list">
              <template v-for="post in data[tab.key]">
                <div class="post-thumbnail-entry w-100" :key="`${tab.key}-${post.id}`">
                  <img alt="" :src="post.img">
                  <div class="post-thumbnail-content">
                    <a :href="`/post/${post.id}`">{{ post.title }}</a>
                    <span class="post-date"><i class="icon-clock"></i>{{ post.date | timeString('YYYY/MM/DD') }}</span>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

import { findPromotedPosts, findSelectedPosts, findLatestPosts } from '@/service/api/public';

export default {
  name: "SideBarPostsTab",
  data(){
    return {
      tabList: [
        { name: "推廣", key: "promotion" },
        { name: "精選新聞稿", key: "selected" },
        { name: "最新新聞稿", key: "latest" }
      ],
      data: {
        promotion: [],
        selected: [],
        latest: []
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    init(){
      this.getPromoPostList()
      this.getSelectedPostList()
      this.getLatestPostList()
    },
    async getPromoPostList(){
      await findPromotedPosts().then(data => {
        if(Array.isArray(data)){
          this.data.promotion = data.map(row => {
            let category = ""
            let categoryZh = ""
            if(Array.isArray(row.post.category) && row.post.category.length > 0){
              category = row.post.category[0].category
              categoryZh = row.post.category[0].categoryZh
            }
            return {
              id: row.post.id,
              title: row.post.title,
              date: row.post.publishedTime,
              category: category,
              categoryZh: categoryZh,
              img: row.post.cover
            }
          })
        }
      })
    },
    async getSelectedPostList(){
      await findSelectedPosts().then(data => {
        if(Array.isArray(data)){
          this.data.selected = data.map(row => {
            let category = ""
            let categoryZh = ""
            if(Array.isArray(row.post.category) && row.post.category.length > 0){
              category = row.post.category[0].category
              categoryZh = row.post.category[0].categoryZh
            }
            return {
              id: row.post.id,
              title: row.post.title,
              date: row.post.publishedTime,
              category: category,
              categoryZh: categoryZh,
              img: row.post.cover
            }
          })
        }
      })
    },
    async getLatestPostList(){
      await findLatestPosts().then(data => {
        if(Array.isArray(data)){
          this.data.latest = data.map(post => {
            let category = ""
            let categoryZh = ""
            if(Array.isArray(post.category) && post.category.length > 0){
              category = post.category[0].category
              categoryZh = post.category[0].categoryZh
            }
            return {
              id: post.id,
              title: post.title,
              date: post.publishedTime,
              category: category,
              categoryZh: categoryZh,
              img: post.cover
            }
          })
        }
      })
    },

  },
  computed: {
    filteredTabList(){
      return this.tabList.filter(tab => this.data[tab.key].length > 0)
    },
    isShow(){
      return this.filteredTabList.length > 0
    }
  }

}
</script>

<style scoped>

</style>
