<template>
  <div>
    <section id="page-content" class="sidebar-both">
      <div class="container" style="max-width: 1500px">
        <div class="row">
          <!-- Sidebar-->
          <div class="sidebar col-lg-3">
            <side-bar-services/>
          </div>
          <!-- end: Sidebar-->
          <!-- content -->
          <div class="content col-lg-9">
            <router-view/>
          </div>
          <!-- end: content -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SideBarServices from '@/components/internal/admin/SideBarServices'

export default {
  name: "AdminManagement",
  components: {
    SideBarServices,
  }
}
</script>

<style scoped>

</style>
