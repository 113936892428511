<template>
  <!--widget tags --><!-- 作者對文章自己設定的Tag，可作為該篇文章的Meta Tag 這邊列出10個就好-->
  <div class="widget widget-tags">
    <h4 v-if="hotTagList.length > 0" class="widget-title">熱門標籤</h4>
    <div class="tags">
      <template v-for="(tag, index) in hotTagList">
        <router-link
            :to="{
                  path: '/tag',
                  query: { q: tag.name }
            }"
            :key="index"
            @click.native="scrollToTop">
          {{tag.name}}
        </router-link>
      </template>
    </div>
  </div>
  <!--end: widget tags -->
</template>

<script>
import { utilMixin } from "@/mixins/utilMixin";
import { getHotTagList } from '@/service/api/public';

export default {
  name: "HotTags",
  mixins: [utilMixin],
  data(){
    return{
      hotTagList:[]
    }
  },
  created() {
    this.findHotTagList()
  },
  methods: {
    async findHotTagList() {
      await getHotTagList(7,10).then(hotTagList => {
        this.hotTagList = hotTagList ? hotTagList : []
      })
    }
  }
}
</script>

<style scoped>

</style>
