<template>
  <!-- Blog -->
  <div id="blog" class="single-post">
    <!-- Post single item-->
    <div class="post-item">
      <div class="post-item-wrap">
        <div class="post-image">
          <a :href="post.cover">
            <img alt="" :src="post.cover">
          </a>
        </div>
        <div class="post-item-description">
          <span v-if="post.promoting"><i class="fa fa-star"></i>贊助</span>
          <h2>{{post.title}}</h2>
          <div class="post-meta">
            <div>
              <a @click="checkIsAnyTransactionExists(post)" class="btn btn-shadow btn-rounded promotion-btn mr-3">
                <img class="icon-megaphone mt-1 mr-1" src="@/assets/images/icon-custom-megphone.svg"/>
                <span>推廣新聞稿</span>
              </a>
              <span class="post-meta-date"><i class="fa fa-calendar-o"></i>{{ post.publishedTime | timeString('MMM DD,YYYY')}}</span>
              <template v-if="post.category">
                <template v-for="category in post.category">
                  <span class="post-meta-category" :key="category.id">
                    <span class="text-bold mr-1" style="font-size: small">
                      <router-link :to="`/category/${category.category}`">
                        <i class="fa fa-tag"></i>{{category.categoryZh}}
                      </router-link>
                    </span>
                    <template v-if="category.subCategory">
                       <template v-for="subCategory in category.subCategory">
                         <router-link :to="`/category/${category.category}/sub/${subCategory.industry}`" :key="subCategory.id">
                           <i class="fa fa-tag"></i>
                           {{subCategory.industryZh}}
                         </router-link>
                       </template>
                    </template>
                  </span>
                </template>
              </template>
              <template v-else>
                  <span class="post-meta-category text-bold">
                    <span class="text-bold mr-1" style="font-size: small">
                      <router-link to="/category/News">
                        <i class="fa fa-tag"></i>新聞
                      </router-link>
                    </span>
                    <router-link to="/category/News/sub/News">
                      <i class="fa fa-tag"></i>新聞時事
                    </router-link>
                  </span>
                </template>
            </div>
            <div class="text-end">
              <div class="post-meta-share">
                <a class="btn btn-xs btn-slide btn-line" :href="`https://line.me/R/msg/text/?${encodeURIComponent(lineShareText)}`" target="_blank" data-width="118">
                  <i class="fab fa-line"></i>
                  <span>Line</span>
                </a>
                <a class="btn btn-xs btn-slide btn-facebook" :href="`https://www.facebook.com/sharer/sharer.php?u=${post.url}`" target="_blank">
                  <i class="fab fa-facebook-f"></i>
                  <span>Facebook</span>
                </a>
                <a class="btn btn-xs btn-slide btn-twitter" :href="`https://twitter.com/share?text=${post.title}&url=${post.url}`" target="_blank" data-width="100">
                  <i class="fab fa-twitter"></i>
                  <span>Twitter</span>
                </a>
                <a class="btn btn-xs btn-slide btn-googleplus" @click="$emit('copy-link')" data-width="80">
                  <i class="icon-share-2"></i>
                  <span>分享</span>
                </a>
              </div>
            </div>
          </div>
          <div class="force-line-break content-area" v-html="post.content"></div>
        </div>
        <div class="post-tags" v-if="isShowTagList">
          <template v-for="(tag ,index) in post.tag">
            <router-link class="mr-1"
                         :key="index"
                         :to="{ path: '/tag',
                                query: {q: tag}
            }">{{tag}}</router-link>
          </template>
        </div>

        <div class="post-navigation">
          <a :href="`/post/${previousPost.id}`" class="post-prev">
            <div class="post-prev-title"><span>上一篇</span>{{previousPostTitle}}</div>
          </a>
          <a href="/" class="post-all">
            <i class="icon-grid"> </i><!-- 回到首頁清單 -->
          </a>
          <a :href="`/post/${nextPost.id}`" class="post-next">
            <div class="post-next-title"><span>下一篇</span>{{nextPostTitle}}</div>
          </a>
        </div>
        <hr>
      </div>
    </div>
    <!-- end: Post single item-->
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import ModalList from "@/const/modal";
import { getPendingPromotion } from "@/service/api/internal/me/transaction";

export default {
  name: "PostContent",
  computed:{
    post(){
      return this.$store.state.post ?  this.$store.state.post : {}
    },
    previousPost(){
      return this.$store.state.previousPost ?  this.$store.state.previousPost : {}
    },
    nextPost(){
      return this.$store.state.nextPost ?  this.$store.state.nextPost : {}
    },
    previousPostTitle(){
      return this.getFixedLengthTitle(this.previousPost.title)
    },
    nextPostTitle(){
      return this.getFixedLengthTitle(this.nextPost.title)
    },
    lineShareText(){
      return `${this.post.title} ${this.post.url}`
    },
    isShowTagList(){
      return Array.isArray(this.post.tag) ? (this.post.tag.length > 0) : false
    }
  },
  methods:{
    ...mapMutations({
      showModal:'showModal'
    }),
    getFixedLengthTitle(title){
      let result = title
      if(typeof title == "string" && title.length > 19){
        result = `${title.substring(0,19)}...`
      }
      return result
    },
    async checkIsAnyTransactionExists(post){
      await getPendingPromotion(post.id).then(res =>{
        switch (res.status){
          case 204: {
            this.showModal({
              modalId: ModalList.PROMOTION_MODAL,
              payload: {
                promotion: {
                  postId: post.id,
                  name: post.title,
                  text: post.summary,
                  imgMain: post.cover
                }
              }
            })
            break;
          }
          case 200:
            return res.data
        }
      }).then( transaction =>{
        if(transaction){
          this.showModal({modalId: ModalList.TRANSACTION_PENDING_MODAL,
            payload: {
              title: '本文章有尚未完成付款的推廣訂單：',
              orderId: transaction.orderId,
              description: '您前一次操作並未完成流程。按下確認後，系統將為您檢查訂單狀態。若仍為有效，則會引導您繼續前往付款。若已失效，請您返回本頁，重新下單。'
            }})
        }
      })
    }
  }
}
</script>

<style>
.force-line-break{
  width: 100%;
  height: auto;
  word-wrap:break-word;
  word-break:break-all;
  overflow: hidden;
}

.content-area {
  line-height: 1.6em;
  font-size: 14px;
}
.content-area p {
  font-size: 14px !important;
}
.content-area span {
  line-height: 1.6em !important;
}

.content-area ol,
.content-area ul{
  padding-inline-start: 40px;
}

/* start of new version of promotion-btn */
.promotion-btn{
  background: linear-gradient(90deg, #6A11CB 0%, #2575FC 100%);
  box-shadow: 2px 2px 0px rgba(15, 70, 119, 0.4) !important;
  color: white !important;
  transition: linear .2s !important;
  position: relative;
  border: none !important;
}

.promotion-btn > span {
  position: relative;
  z-index: 3;
}

.promotion-btn > .icon-megaphone {
  position: relative;
  z-index: 3;
  display: inline-flex;
  width: 20px !important;
  height: 18px!important;
  margin: 3px 6px 0 0;
  transition: cubic-bezier(0.39, 0.58, 0.57, 1) .3s;
}
.promotion-btn:before {
  content:'';
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #2575FC 0%, #4744E4 25.52%, #6A11CB 100%);
  opacity: 0;
  transition: cubic-bezier(0.39, 0.58, 0.57, 1) .3s;
  border-radius: inherit;
}

.promotion-btn:hover{
  box-shadow: none!important;
  color:#FFDA55!important;
  transition: cubic-bezier(0.39, 0.58, 0.57, 1) .3s;
}

.promotion-btn:hover > img.icon-megaphone{
  filter: invert(0%) sepia(52%) saturate(7129%) hue-rotate(333deg) brightness(96%) contrast(93%);
  transition: cubic-bezier(0.39, 0.58, 0.57, 1) .3s;
}

.promotion-btn:hover:before {
  opacity: 1;
  transition: cubic-bezier(0.39, 0.58, 0.57, 1) .3s;
}
/* end of new version of promotion-btn */

</style>
