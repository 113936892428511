<template>
  <!--Tabs with Posts-->
  <div class="widget">
    <h4 class="widget-title">分類</h4>
    <ul class="list-group" v-if="categoryList.length > 0">
      <template v-for="(category, index) in categoryList">
        <li :key="'primary-'+index" class="list-group-item d-flex justify-content-between align-items-center">
          <router-link :to="`/category/${category.category}`"
                       @click.native="scrollToTop">
            {{category.categoryZh}}
          </router-link>=
        </li>
        <li v-for="(subCategory, index) in category.subCategory" :key="'primary-'+ category.category+'-sub-'+index"
            class="list-group-item d-flex justify-content-between align-items-center sub-list">
          <router-link :to="`/category/${category.category}/sub/${subCategory.industry}`"
                       @click.native="scrollToTop">
            {{subCategory.industryZh}}
          </router-link>
          <span style="background-color: #164573" class="badge badge-primary badge-pill">{{subCategory.total}}</span>
        </li>
      </template>
    </ul>
  </div>
  <!--End: Tabs with Posts-->
</template>

<script>

import {mapMutations, mapState} from 'vuex'
import { utilMixin } from "@/mixins/utilMixin";
import { getCategoryList } from '@/service/api/public';

export default {
  name: "SideBarCategoryList",
  mixins: [utilMixin],
  created() {
    this.findCategoryList()
  },
  computed:{
    ...mapState(['categoryList'])
  },
  methods: {
    ...mapMutations(['updateCategoryList']),
    async findCategoryList() {
      await getCategoryList().then(categoryList => {
        this.updateCategoryList(categoryList)
      })
    }
  }
}
</script>

<style scoped>

</style>
