<template>
  <!-- footer start -->
  <footer class="nb-guide-footer">
    <section class="more-section d-flex justify-content-center px-2">
      <div class="section-content-wrapper-sm row py-4 py-md-5">
        <div class="col-md-4 p-4 d-flex flex-column">
          <div class="more-title">NEWSBUFFET 新聞稿自助吧</div>
          <div class="sub-more-title flex-grow-1">
            新聞稿的好去處，三分鐘上稿完成，最快接觸最多讀者的方案！
          </div>
          <button type="button" class="btn btn-in-nb-blue btn-xl w-100" @click="publish">
            <span>刊登新聞稿</span>
          </button>
        </div>

        <div class="col-md-4 p-4 d-flex flex-column">
          <div class="more-title">立即購買新聞稿曝光</div>
          <div class="sub-more-title flex-grow-1">
            發一篇新聞稿透通到各大媒體的最快速、最便捷的方案！
          </div>
          <button type="button" class="btn btn-in-nb-blue btn-xl w-100" @click="promotion">
            <span>讓新聞稿曝光</span>
          </button>
        </div>

        <div class="col-md-4 p-4 d-flex flex-column">
          <div class="more-title">分析新聞稿成效</div>
          <div class="sub-more-title flex-grow-1">
            透過 Trek 數據平台的分析讓您知道您的新聞稿成效表現如何。
          </div>
          <button type="button" class="btn btn-in-nb-blue btn-xl w-100" @click="toTrek">
            <span>我想了解</span>
          </button>
        </div>
      </div>
    </section>
    <div class="copyright-content">
      <div class="container">
        <div class="copyright-text text-center">
          COPYRIGHT &copy; 2023 Aotter Inc.
        </div>
        <div class="text-center flex">
          <span>
            <a href="/policy/publish">| 刊登政策 </a>
          </span>
          <span>
            <a href="/policy/tou">| 服務條款 </a>
          </span>
          <span>
            <a href="/policy/privacy">| 隱私權政策 </a>
          </span>
          <span>
            <a :href="`mailto:${contactEmail}?subject=${subject}&body=${body}`">| 聯絡我們 </a>
          </span>
          <span>| </span>
        </div>
      </div>
    </div>
  </footer>
  <!-- end of footer -->
</template>

<script>
import { utilMixin } from "@/mixins/utilMixin";
import { findContactEmail } from "@/service/api/public";
export default {
  name: "Footer",
  mixins: [utilMixin],
  data() {
    return {
      contactEmail: '',
      subject: '【NEWSBUFFET】聯絡我們或回報問題',
      body: '您好，歡迎使用 NEWSBUFFET 新聞稿自助吧，請告訴我們 (1) 您遇到的問題或提供建議 (2) 姓名 (3) 帳號 Email (4) 聯絡電話（選填），謝謝！'
    };
  },
  mounted() {
    this.getContactEmail()
  },
  methods: {
    async getContactEmail(){
      this.contactEmail = await findContactEmail()
    },
    publish() {
      // 按我刊登新聞稿按鈕導向位置
      this.checkLoginStatusAndRedirect("/me/post-edit", "postEdit");
    },
    promotion() {
      // 按我刊登新聞稿按鈕導向位置
      this.checkLoginStatusAndRedirect("/me/post-list", "promotion");
    },
    toTrek(){
      let path = 'https://trek.aotter.net/'
      window.open(path);
    }
  },
};
</script>

<style scoped>
/* footer */
footer .more-section .more-title {
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 3.4em;
}

footer .more-section .sub-more-title {
  color: #828282;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 2em;
  margin: 0 0 3em 0;
  text-align: justify;
}

.copyright-content {
  min-height: 80px;
  padding: 30px 0;
  background-color: #eff1f4;
  font-size: 13px;
  color: #484848;
}

.copyright-content a {
  color: #484848;
}

.copyright-text {
  margin: 20px 0;
}
</style>
