import axios from "@/plugins/axios";

// promotion 相關的 api
let baseUrl = '/'

if(process.env.VUE_APP_baseUrl) {
    baseUrl = process.env.VUE_APP_baseUrl
}

baseUrl += 'api/internal/me/promotion'

// create Promotion and create Transaction (get Payment Page Url from Aotter Pay)
export function createPromotionAndGetPaymentPageUrl(postId, promotionDTO){
    return axios.post(`${baseUrl}/post/${postId}`, promotionDTO, { headers: {'Content-Type': 'application/json'} })
        .then(res=>res.data)
}

// find Promotion List
export function findPromotionList(query){
    return axios.get(`${baseUrl}/list?${query}`).then(res=>res.data)
}
