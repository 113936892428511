<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-9">
          <div class="h4">精選新聞稿管理</div>
          <span class="text-danger">*同一時段最多設定6則新聞稿，若超過由系統抓取顯示</span>
        </div>
        <div class="col-3 d-flex flex-row-reverse">
          <button class="btn btn-primary btn-size"
                  @click="createSelectedPost()">新增</button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="form-inline my-2 my-lg-0" style="justify-content: end">
        <input class="form-control w-100 mb-2" placeholder="輸入新聞稿編號" v-model="search.postId" >
        <input class="form-control w-100 mb-2" placeholder="輸入標題關鍵字" v-model="search.title" >
        <button class="btn btn-outline-success my-2 my-sm-0" @click="searchByQuery(getSelectedPostList, {page: 1, ...search})">搜尋新聞稿</button>
        <button class="btn btn-danger my-2 my-sm-0" @click="resetSearch">重設搜尋</button>
      </div>
      <div class="mt-2 d-flex flex-wrap flex-md-nowrap justify-content-end">
        <div class="text-secondary align-self-center" id="resultCount">
          共<span class="mr-1 ml-1">{{ totalData }}</span>筆
        </div>
        <div class="ml-2" style="width: 100px">
          <select class="form-control" v-model="search.state" @change="searchByQuery(getSelectedPostList, {page: 1, ...search})">
            <option :value="Const().ACTIVE">啟用中</option>
            <option :value="Const().ARCHIVED">已結束</option>
          </select>
        </div>
      </div>
      <div class="table-outter wrapper">
        <table v-if="currentPageDataList.length > 0" id="dataTable" class="table table-hover table-responsive vertical-middle text-center mt-4">
          <thead>
          <tr>
            <th scope="col" class="col-250" width="20%">新聞稿編號</th>
            <th scope="col" class="col-250" width="20%">標題</th>
            <th scope="col" class="col-75" v-if="search.state == Const().ACTIVE" >精選中</th>
            <th scope="col" class="col-75" width="15%">起始時間</th>
            <th scope="col" class="col-75" width="15%">結束時間</th>
            <th scope="col" class="col-50" :width="search.state == Const().ACTIVE? '5%' : '20%'">{{ search.state == Const().ACTIVE? '編輯' : '再次精選' }}</th>
            <th scope="col" class="col-50" v-if="search.state == Const().ACTIVE" >排序</th>
            <th scope="col" class="col-50" width="5%">刪除</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="data in currentPageDataList">
            <tr :key="data.id">
              <td class="text-break col-250">{{ data.postId }}</td>
              <td class="col-250">
                {{ data.post.title }}
                <a target="_blank"  :href="`/post/${data.postId}`">
                  <i class="ml-2 icon-eye"></i>
                </a>
              </td>
              <td class="col-75" v-if="search.state == Const().ACTIVE" >{{ data.active | activeString }}</td>
              <td class="col-75">{{ data.startTime | timeString('YYYY/MM/DD HH:mm')}}</td>
              <td class="col-75">{{ data.endTime | timeString('YYYY/MM/DD HH:mm')}}</td>
              <td class="col-50">
                <i class="ml-2 icon-edit pointIcon" @click="editSelectedPost(data)"></i>
              </td>
              <td class="col-50" v-if="search.state == Const().ACTIVE" >
                <a class="d-block pointIcon" @click="moveUpItem(type, data, updateOrder, reOrderList, getSelectedPostList)">
                  <i class="font-weight-bold icon-chevron-up" ></i>
                </a>
                <a class="d-block pointIcon" @click="moveDownItem(type, data, updateOrder, reOrderList, getSelectedPostList)">
                  <i class="font-weight-bold icon-chevron-down"></i>
                </a>
              </td>
              <td class="col-50">
                <i class="icon-trash-2 pointIcon" @click="deleteSelectedPost(data)"></i>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
        <div v-else id="result-hint" class="d-flex justify-content-center">
          <template v-if="isEmptyList">尚無資料</template>
          <template v-else><loading/></template>
        </div>
      </div>
      <!-- Pagination -->
      <ul v-if="currentPageDataList.length > 0" class="pagination justify-content-center">
        <li class="page-item">
          <a class="page-link" @click="currentPage != 1 ? getPreviousPage(currentPage, getSelectedPostList):''"><i class="fa fa-angle-left"></i></a>
        </li>
        <template v-for="page in range">
          <li class="page-item" :class="{'active': page === currentPage}" :key="page">
            <a class="page-link" @click="currentPage != page ? toTargetPage(page, getSelectedPostList):''" style="cursor: default;">{{page}}</a>
          </li>
        </template>
        <li class="page-item">
          <a class="page-link" @click="currentPage != totalPage ? toNextPage(currentPage, totalPage, getSelectedPostList):''"><i class="fa fa-angle-right"></i></a>
        </li>
      </ul>
      <!-- end: Pagination -->
    </div>
  </div>
</template>

<script>
import Loading from "@/components/common/Loading";
import ModalList from "@/const/modal";
import Const from "@/const";
import { utilMixin } from "@/mixins/utilMixin";
import { adminMixin } from "@/mixins/adminMixin";
import { findManualSelectedList } from "@/service/api/internal/admin/manualSelected";

export default {
  name: "SelectedPost",
  mixins: [utilMixin, adminMixin],
  components: {
    Loading
  },
  data(){
    return {
      type: 'selected',
      search:{
        postId: '',
        title: '',
        state: Const.ACTIVE
      }
    }
  },
  mounted(){
    this.initSearchParam()
    this.getSelectedPostList(this.currentPage)
  },
  methods: {
    initSearchParam(){
      let search = JSON.parse(JSON.stringify(this.search))
      this.search = this.initSearchData(search)
      if(!this.search.state){
        this.search.state= Const.ACTIVE
      }
    },
    async getSelectedPostList(page){
      let currentPage = this.checkPageValid(page, this.currentPage, this.totalPage)
      this.currentPage = currentPage
      let query = this.getSearchQuery(this.currentPage, this.show, this.search)
      let response = await findManualSelectedList(this.type, query)
      this.currentPageDataList = response.data
      this.totalPage = response.totalPage
      this.totalData = response.totalElement
      if(this.currentPageDataList.length == 0){
        this.isEmptyList = true
      }
    },
    resetSearch(){
      let state = this.search.state
      this.search = {}
      this.search.state = state
      this.search.postId = ""
      this.search.title = ""
      if(window.location.search) {
        this.searchByQuery(this.getSelectedPostList, {page: 1, ...this.search})
      }
    },
    createSelectedPost(){
      let type = this.type
      this.showModal({
        modalId: ModalList.MANUAL_SELECTED_MODAL,
        payload: { type, mode: 'create', execFunc: this.getSelectedPostList}
      })
    },
    editSelectedPost(item){
      let state = this.search.state
      let data = item
      if(state == Const.ARCHIVED){
        data = { postId: item.postId}
      }
      this.showManualSelectedModal(this.type, state, data, this.getSelectedPostList)
    },
    deleteSelectedPost(data){
      let type = this.type
      this.showModal({
        modalId: ModalList.MANUAL_SELECTED_DELETE_MODAL,
        payload: {
          type,
          ...data,
          execFunc: this.getSelectedPostList
        }
      })
    }
  }
}
</script>

<style scoped>

.pointIcon{
  cursor: pointer;
  color: rgb(24 135 255)
}

.table-outter {
  overflow-x: scroll;
}

.col-250 {
  min-width: 250px;
}

.col-75 {
  min-width: 75px;
}

.col-50 {
  min-width: 50px;
}

@media only screen and (max-width: 576px) {
  .btn-size {
    border-radius: 0.2rem !important;
    font-size: 11px !important;
    height: 30px !important;
    line-height: 28px !important;;
    padding: 0 14px !important;
  }
}
</style>
