<template>
  <div id="report-view" class="container-md report font-size-base" >
    <div class="pb-10">
      <div class="space-root-children-3 md:col-12">
      <div class="text-right my-4">
        <button class="btn-submit" @click="print">列印此頁</button>
      </div>
      <div class="row">
        <div class="md:col-12">
          <h3 class="nblink-title__grade-2 mb-3">加強推廣
            <i class="icon-alert-circle" data-toggle="tooltip" data-placement="top" title="透過廣告聯播網 Supr.Link Ad 的技術，高度曝光縮網址連結。"></i>
          </h3>
          <section code="7la6B" title="加強推廣" description="透過廣告聯播網 Supr.Link Ad 的技術，高度曝光縮網址連結。" id="promotion">
            <div class="report-promotion">
              <div class="row w-full">
                <div class="md:col-3 mb-10 md:mb-0" style="padding: 0px;">
                  <div class="report-promotion__header">
                    <h5 class="table-header flex items-center justify-center whitespace-no-wrap">推廣新聞稿 Ad 總點擊數
                      <i class="icon-alert-circle ml-2" data-toggle="tooltip" data-placement="top" title="所有加強推廣產生的點擊數加總。"></i>
                    </h5>
                    <p class="report-promotion__total">{{report.click}}</p>
                  </div>
                </div>
                <div class="md:col-9" style="padding: 0px;">
                  <section class="grid-table report-promotion__table">
                    <div style="">
                      <div class=" grid-table__header grid-top-columns">
                        <h5 class="col-top-show justify-center">開始日期</h5>
                        <h5 class="justify-center">已消耗/推廣金額
                          <i class="icon-alert-circle ml-1" data-toggle="tooltip" data-placement="top" title="檢視目前推廣金額的消耗狀況"></i>
                        </h5>
                        <h5 class="justify-center">點擊數
                          <i class="icon-alert-circle ml-1" data-toggle="tooltip" data-placement="top" title="原始紀錄的點擊數"></i>
                        </h5>
                        <h5 class="justify-center">有效點擊數
                          <i class="icon-alert-circle ml-1" data-toggle="tooltip" data-placement="top" title="扣除誤觸、無效的點擊數，已消耗金額是以有效點擊數進行推估計算。"></i>
                        </h5>
                        <h5 class="col-top-show justify-center">下載名單
                          <i class="icon-alert-circle ml-1" data-toggle="tooltip" data-placement="top" title="獲取曾經點擊過本推廣的用戶名單"></i>
                        </h5>
                      </div>
                      <div class=" grid-table__body grid-top-columns">
                        <div class="col-top-show justify-center">{{report.trekFromTime | timeString('YYYY-MM-DD')}}</div>
                        <div class="justify-center">
                          <div class="chart-progress pt-3">
                            <div class="progress-wrapper">
                              <progress max="100" class="progress is-small is-success" :value="getPercentage(report.budget, report.consumedBudget)">{{getPercentage(report.budget, report.consumedBudget)}}</progress>
                            </div>
                            <p class="chart-progress__text">${{report.consumedBudget}} / ${{report.budget}}</p>
                          </div>
                        </div>
                        <div class="justify-center">{{report.click}}</div>
                        <div class="justify-center">{{report.rclick}}</div>
                        <div class="col-top-show justify-center">
                          <div class="text-center">
                            <a :href="report.download_path">
                              <button class="btn-success-sm" style="width: 70px;">下載</button>
                            </a>
                            <small class="mt-1 items-center" style="font-size: 12px; display: inherit">有效人數
                              <span style="display: block">{{ report.count }} 人
                                <i class="icon-alert-circle ml-1" data-toggle="tooltip" data-placement="top" title="此為有效名單人數，系統過濾去除重複點擊、無效點擊後的名單人數"></i>
                              </span>
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
            <div>
              <hr class="my-8">
              <div class="mt-6">
                <div class="flex justify-between mb-4">
                  <h5 class="report-block__title">日報表</h5>
                  <div v-if="!isMobile">
                    <download-csv
                        :data="dailyReport"
                        :name="dataFile"
                        :labels="labels"
                        :fields="fields"
                        v-on:export-finished="exported">
                      <button class="btn-submit-sm">下載報表</button>
                    </download-csv>
                  </div>

                </div>
                <section class="grid-table report-promotion__table">
                  <div style="">
                    <div class="grid-table__header grid-daily-columns">
                      <div class="justify-center">日期</div>
                      <div class="justify-center">曝光數</div>
                      <div class="justify-center">點擊數</div>
                      <div class="justify-center">點擊率</div>
                      <div class="col-daily-show justify-center">千次曝光費用</div>
                      <div class="col-daily-show justify-center">推廣金額</div>
                      <div class="col-daily-show justify-center">點擊成本</div>
                    </div>
                    <template v-for="daily in report.dailyReport">
                      <div :key="daily._id" class="grid-table__body grid-daily-columns">
                        <div class="justify-center">{{ getDate(daily._id) }}</div>
                        <div class="justify-center">{{daily.imp}}</div>
                        <div class="justify-center">{{daily.clc}}</div>
                        <div class="justify-center">{{precisionNum(daily.ctr)}}%</div>
                        <div class="col-daily-show justify-center">{{daily.cpm}}</div>
                        <div class="col-daily-show justify-center">{{daily.consumedBudget}}</div>
                        <div class="col-daily-show justify-center">{{daily.cpc}}</div>
                      </div>
                    </template>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import JsonCSV from 'vue-json-csv';
import { utilMixin } from "@/mixins/utilMixin";
import { getPromotionReportById } from "@/service/api/internal/promotionReport";

export default {
  name: "PromotionReport",
  mixins: [ utilMixin ],
  components: { 'download-csv': JsonCSV },
  data(){
    return{
      report: {},
      dailyReport:[],
      promotionId:'',
      dataFile: '曝光成效日報表.csv',
      labels: {
        _id: '日期',
        imp: '曝光數',
        clc: '點擊數',
        ctr: '點擊率',
        cpm: '千次曝光費用',
        consumedBudget: '推廣金額',
        cpc: '點擊成本',
      },
      fields: ['_id', 'imp', 'clc', 'ctr','cpm','consumedBudget','cpc'],
      isExported: false
    }
  },
  created() {
    if(this.$route.query.id){
      this.promotionId = this.$route.query.id
      this.getPromotionReport()
    }else {
      this.$router.push('/')
    }
  },
  mounted() {
    $('[data-toggle="tooltip"]').tooltip()
  },
  methods: {
    async getPromotionReport(){
      await getPromotionReportById(this.promotionId)
        .then(res =>{
          if(res.status == 200){
            return res.data
          }else {
            this.$router.push('/')
          }
        })
        .then(result =>{
          this.report = result
          this.dailyReport = this.report.dailyReport
        })
    },
    getDate(id){
      return id.split('T')[0]
    },
    getPercentage(budget, consumedBudget){
      let percentage = this.round(consumedBudget/budget)
      return percentage
    },
    round(num) {
      return Math.round(this.precisionNum(num)) * Math.sign(num);
    },
    precisionNum(num){
      return  Number((Math.abs(num) * 100).toPrecision(15));
    },
    print(){
      window.print()
    },
    exported() {
      this.isExported = true
      setTimeout(() => {
        this.isExported = false
      }, 3 * 1000)
    }
  }
}
</script>

<style scoped>
.report {
  border: 7px ridge #3e5787;
  margin-bottom: 5rem;
  margin-top: 2rem;
  box-shadow:3px 3px 5px 6px #cccccc;
}

.space-root-children-3>:not(:last-child) {
  margin-bottom:2rem;
}

.text-right {
  text-align: right;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.btn-submit {
  color: #fff;
  background: #3e5787;
  border: 1px solid #3e5787;
}

.btn-success-sm {
  color: #fff;
  background: #00c79c;
  border: 1px solid #00c79c;
  padding: 0.25rem 0.25rem;
}

.btn-submit, .btn-submit-sm, .btn-success-sm{
  display: inline-block;
  text-align: center;
  padding: 0.5rem 0.75rem;
  border-radius: 3px;
  transition: background-color .15s;
  white-space: nowrap;
  cursor: pointer;
}

.nblink-title__grade-2 {
  font-size: 1.5rem;
  color: #1f2f5b;
  font-weight: 700;
}

@media only screen and (min-width: 768px) {
  .md\:col-2 {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .md\:col-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .md\:col-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .md\:col-10 {
    flex: 0 0 80%;
    max-width: 80%;
  }

  .md\:col-11 {
    flex: 0 0 91.66666666666667%;
    max-width: 91.66666666666667%;
  }

  .md\:col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .md\:mb-0 {
    margin-bottom: 0 !important;
  }
}

.grid-top-columns {
  grid-template-columns: 110px minmax(120px, 100%) minmax(70px, 90px) 130px 120px
}

.grid-daily-columns {
  grid-template-columns: minmax(100px, 1fr) 1fr 1fr 1fr 1fr 1fr 1fr;
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
  .grid-daily-columns {
    grid-template-columns: minmax(100px, 1fr) 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
  .grid-daily-columns {
    grid-template-columns: minmax(100px, 1fr) 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

@media all and (device-width: 820px) and (device-height: 1180px) and (orientation:landscape) {
  .grid-daily-columns {
    grid-template-columns: minmax(100px, 1fr) 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 576px) {
  .grid-daily-columns {
    grid-template-columns: minmax(100px, 1fr) 1fr 1fr 1fr;
  }

  .col-daily-show{
    display: none !important;
  }
}


@media only screen and (max-width: 1100px) {
  #report-view div, button, h3, h5{
    font-size: 0.8rem !important;
  }

  .grid-top-columns {
    grid-template-columns: minmax(105px, 100%) minmax(50px, 80px) 80px
  }

  .col-top-show{
    display: none !important;
  }
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.md\:col-3, .md\:col-9, .md\:col-12{
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.mb-3 {
  margin-bottom: .75rem;
}

.ml-2 {
  margin-left: .5rem;
}

span {
  font-style: inherit;
  font-weight: inherit;
}

*, :after, :before {
  box-sizing: inherit;
}

svg:not(:root){
  overflow: visible;
}

.is-small:after {
  width: 180px;
}

article, aside, figure, footer, header, section {
  display: block;
}

.report-promotion {
  background: #eff2f5;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.w-full {
  width: 100%;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.report-promotion__header {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
}

.whitespace-no-wrap {
  white-space: nowrap;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.flex {
  display: flex;
}

.ml-1 {
  margin-left: .25rem;
}

span {
  font-style: inherit;
  font-weight: inherit;
}

.report-promotion__total {
  flex-grow: 1;
  font-size: 1.75rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

.report-promotion__table {
  flex-grow: 1;
}

.grid-table__header {
  display: grid;
  grid-column-gap: 0.5rem;
  margin-bottom:  0.5rem;
}

.grid-table__header, .table-header {
  font-size: 1rem;
  color: #3e5787;
  padding: 0 0.5rem;
}

.grid-table__header>* {
  display: flex;
  align-items: center;
}

.grid-table__body {
  display: grid;
  grid-column-gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 3px;
  box-shadow: 0 0 4.5px .5px hsla(0,0%,0%,.1);
  background-color: hsla(0,0%,100%,.4);
  margin-bottom: 0.5rem;
}

.grid-table__body>* {
  display: flex;
  align-items: center;
}

.chart-progress {
  width: 80%;
}

.chart-progress .progress-wrapper {
  margin-bottom: 0;
}

.progress-wrapper {
  position: relative;
  overflow: hidden;
}

.chart-progress__text {
  text-align: right;
  font-size: 0.75rem;
}

.progress-wrapper .progress {
  margin-bottom: 0;
}

progress.is-small {
  height: 0.4rem!important;
}

.progress {
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  border-radius: 290486px;
  display: block;
  height: 1rem;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

progress {
  vertical-align: baseline;
}

.text-center {
  text-align: center;
}

.btn-submit-sm {
  color: #fff;
  background: #3e5787;
  border: 1px solid #3e5787);
  padding: 0.25rem 0.25rem;
}

section {
  padding: unset;
  background-color: unset;
}

.report-block__title {
  font-size: 1.25rem;
  color: #1f2f5b;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.justify-between {
  justify-content: space-between;
}

</style>
