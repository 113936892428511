<template>
  <div>
    <!--Services-->
    <div class="widget clearfix widget-categories">
      <h4 class="widget-title">服務選單</h4>
      <div class="list-group">
        <template v-for="(tab, key) in userTab">
          <router-link :to="{name: tab.pathName}"
                       :key="key"
                       @click.native="scrollToTop"
                       class="list-group-item list-group-item-action"
                       :class="{'active': checkPath(key)}">{{tab.tabName}}
          </router-link>
        </template>
      </div>
    </div>
    <!--End: Services-->
    <!-- 廣告版位 -->
    <div class="widget">
      <h4 class="widget-title">贊助</h4>
      <!-- adset-rightsidebar-01 -->
      <div class="adset-rightsidebar">
        <div :id="selectorId" :data-place="uuid"></div>
      </div>
    </div>
    <!-- 廣告版位 -->
  </div>
</template>

<script>
import Const from "@/const";
import { mapState } from "vuex";
import { utilMixin } from "@/mixins/utilMixin";

export default {
  name: "SideBarServices",
  mixins: [utilMixin],
  mounted() {
    if(Const.PROD_SERVER_URL_BASE.includes(window.location.origin)) {
      let selector = `#${this.selectorId}`;
      let invokeScript = document.createElement("script");
      invokeScript.innerHTML = `
              AotterTrek('suprAd', {
                  selector: '${selector}',
                  place: '${this.uuid}',
                  onAdLoad: () => {
                      console.log("${this.selectorId} success")
                  },
                  onAdFail: () => {
                      console.log("${this.selectorId} fail")
                  }
              })`

      document.body.appendChild(invokeScript);
    }
  },
  metaInfo(){
    return {
      script:this.script
    }
  },
  computed: {
    ...mapState('userStore',['userTab']),
    uuid(){
      if(this.$isMobile()){
        return Const.ADMIN_SIDEBAR_LEFT_MOBILE_AD_UUID
      }else {
        return Const.ADMIN_SIDEBAR_LEFT_DESKTOP_AD_UUID
      }
    },
    selectorId(){
      if(this.$isMobile()){
        return Const.ADMIN_SIDEBAR_LEFT_MOBILE_AD
      }else {
        return Const.ADMIN_SIDEBAR_LEFT_DESKTOP_AD
      }
    }
  },
  methods: {
    checkPath(tab){
      if(this.$route.fullPath == `/${Const.USER_LABEL}`){
        this.$router.push({
          name: this.userTab.postList.pathName
        })
      }
      return this.$route.meta.tab == tab
    }
  }
}
</script>

<style scoped>

</style>
