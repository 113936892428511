<template>
  <div :ref="modalId" class="modal fade" role="dialog" aria-hidden="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header text-bold text-center">
          提示
        </div>
        <div class="modal-body row text-break">
          <i class="col-4 fas fa-exclamation fa-5x align-self-center text-center"></i>
          <div id="modalHintContent" class="col-8 align-self-center text-bold text-secondary">
            <div>是否{{data.add? '增加' : '移除'}}管理員權限？</div>
            <div class="text-primary mt-2">使用者編號</div>
            {{data.userId}}
            <div class="text-primary mt-2">電子信箱</div>
            <div>{{data.email}}</div>
          </div>
        </div>
        <div class="modal-footer border-0">
          <button type="button" class="btn btn-info" data-dismiss="modal" @click="hide">取消</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" @click="confirmUpdate">確定</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import ModalList from "@/const/modal";
import { mapMutations } from "vuex";

export default {
  name: "UpdateAdminRoleModal",
  data(){
    return {
      modalId: ModalList.UPDATE_ADMIN_ROLE_MODAL,
      data: {
        add: false,
        userId: '',
        email: '',
        roles: [],
        getList: () => {},
        update: () => {}
      }
    }
  },
  methods: {
    ...mapMutations({
      showModal:'showModal'
    }),
    show(data){
      data = data ? data : {}
      this.data.add = data.add ? data.add : false
      this.data.userId = data.userId ? data.userId : ''
      this.data.email = data.email ? data.email : ''
      this.data.roles = data.roles ? data.roles : []
      this.data.update = data.update ? data.update : null

      $(this.$refs[this.modalId]).modal('show')
    },
    hide(){
      $(this.$refs[this.modalId]).modal('hide')
    },
    confirmUpdate(){
      this.data.update(this.data)
    }
  }
}
</script>

<style scoped>

</style>
