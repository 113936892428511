import axios from "@/plugins/axios";

// promotion 相關的 api
let baseUrl = '/'

if(process.env.VUE_APP_baseUrl) {
    baseUrl = process.env.VUE_APP_baseUrl
}

baseUrl += 'api/internal/admin/promotion'

// find Promotion List
export function findPromotionList(query){
    return axios.get(`${baseUrl}/list?${query}`).then(res=>res.data)
}

// find Promotion review
export function getPromotionReviewing(id){
    return axios.get(`${baseUrl}/review/${id}`).then(res=>res.data)
}

// update promotion state
export function updatePromotionState(id, state){
    return axios.put(`${baseUrl}/state/${id}?state=${state}`)
}

// update promotion info or content
export function updatePromotion(type, id, dto){
    return axios.put(`${baseUrl}/${type}/${id}`, dto, { headers: {'Content-Type': 'application/json'} })
}

// update promotion info
export function updatePromotionInfo(id, dto){
    return axios.put(`${baseUrl}/info/${id}`, dto, { headers: {'Content-Type': 'application/json'} })
}

// update promotion content
export function updatePromotionContent(id, dto){
    return axios.put(`${baseUrl}/content/${id}`, dto, { headers: {'Content-Type': 'application/json'} })
}

// send promotion to trek draft
export function approvePromotion(id){
    return axios.put(`${baseUrl}/start/${id}`)
}
