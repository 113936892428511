<template>
  <div id="app">
<!--    <router-view :key="$route.path"/>-->
    <router-view />
    <modal-group/>
  </div>
</template>

<script>
import ModalGroup from "@/components/modal/ModalGroup"
import $ from "jquery"

export default {
  name:'App',
  components: {
    ModalGroup,
  },
  mounted() {
    this.resetIndexButton()
  },
  watch:{
    '$route.fullPath'(){
      this.resetIndexButton()
    }
  },
  methods:{
    resetIndexButton(){
      let path = this.$route.fullPath
      if(path == '/'){
        $('#returnIndex').hide()
      }else {
        $('#returnIndex').show()
      }
    }
  }
}
</script>

<style>
</style>
