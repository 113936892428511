import axios from "@/plugins/axios";

let baseUrl = '/'

if(process.env.VUE_APP_baseUrl) {
    baseUrl = process.env.VUE_APP_baseUrl
}

baseUrl += 'api/internal'

// find Promotion report
export function getPromotionReportById(id){
    return axios.get(`${baseUrl}/promotion-report/${id}`)
}
