import { render, staticRenderFns } from "./RSSList.vue?vue&type=template&id=e5f72414&scoped=true&"
import script from "./RSSList.vue?vue&type=script&lang=js&"
export * from "./RSSList.vue?vue&type=script&lang=js&"
import style0 from "./RSSList.vue?vue&type=style&index=0&id=e5f72414&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5f72414",
  null
  
)

export default component.exports