<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-9">
          <span class="h4">新聞稿推廣管理</span>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="form-inline my-2 my-lg-0" style="justify-content: end">
        <input class="form-control mr-sm-2 w-100 mb-2" placeholder="輸入訂單編號" v-model="search.transactionId">
        <input class="form-control mr-sm-2 w-100 mb-2" placeholder="輸入新聞稿編號" v-model="search.postId">
        <input class="form-control mr-sm-2 w-100 mb-2" placeholder="輸入推廣人編號" v-model="search.userId">
        <input class="form-control mr-sm-2 w-100 mb-2" placeholder="輸入推廣標題" v-model="search.title">
        <button class="btn btn-outline-success my-2 my-sm-0" @click="searchByQuery(getPromotionList, {page: 1, ...search})">搜尋</button>
        <button class="btn btn-danger my-2 my-sm-0" @click="resetSearch">重設搜尋</button>
      </div>
      <div class="mt-2 d-flex flex-wrap flex-md-nowrap justify-content-end">
        <div class="d-flex">
          <div class="text-secondary align-self-center" id="resultCount">
            共<span class="mr-1 ml-1">{{totalData}}</span>筆
          </div>

          <div class="ml-3">
            <select name="state" class="border-0 font-weight-bold text-primary mr-2"
                    v-model="search.state" @change="searchByQuery(getPromotionList, {page: 1, ...search})">
              <option value="">所有訂單</option>
              <option value="INITIAL">等待付款</option>
              <option value="DELETED">付款失敗</option>
              <option value="REVIEWING">未審核</option>
              <option value="REJECT">審核未通過</option>
              <option value="PENDING">等待開始</option>
              <option value="PROGRESS">推廣進行中</option>
              <option value="COMPLETED">推廣結束</option>
            </select>
          </div>
        </div>
      </div>
      <div class="table-outter wrapper">
        <table v-if="currentPageDataList.length > 0" id="dataTable" class="table table-hover table-responsive vertical-middle text-center mt-4">
          <thead>
          <tr>
            <th scope="col" class="col-100" width="15%">狀態</th>
            <th scope="col" class="col-250" width="25%">推廣標題</th>
            <th scope="col" class="col-250" width="25%">推廣人資訊</th>
            <th scope="col" class="col-100" width="20%">建立時間</th>
            <th scope="col" class="col-100" width="20%">消耗/總預算</th>
            <th scope="col" class="col-50">詳情</th>
            <th scope="col" class="col-50">審核</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(promotion, promotionIndex) in currentPageDataList">
            <tr :key="`${promotion.id}-1`">
              <td class="col-100" :class="getAdminPromotionStateStyle(promotion.state)">{{getAdminPromotionStateName(promotion.state)}}</td>
              <td class="col-250">{{promotion.name}}</td>
              <td class="col-250">{{promotion.userEmail}}</td>
              <td class="col-100">{{promotion.createTime | timeString('YYYY-MM-DD') }}</td>
              <td class="col-100">
                <template v-if="promotion.state == 'PROGRESS' || promotion.state == 'COMPLETED'">
                  <a :href="'/promotion-report?id='+promotion.id" target="_blank">
                    <div class="progress">
                      <div class="progress-bar" role="progressbar" style="padding: 7px 0 15px;"
                           :style="'width:'+ getPercentage(promotion.budget, promotion.consumedBudget)+ '%'"
                           :aria-valuenow="getPercentage(promotion.budget, promotion.consumedBudget)"
                           aria-valuemin="0" aria-valuemax="100">
                        {{getPercentage(promotion.budget, promotion.consumedBudget)}}%</div>
                    </div>
                    <small v-if="promotion.fromTime" class="text-dark">{{promotion.consumedBudget}} /${{promotion.budget}}</small>
                    <small v-else class="text-dark"> 無資料/${{promotion.budget}}</small>
                  </a>
                </template>
                <template v-else>
                  <div class="progress"></div>
                  <small class="text-dark"> 無資料/${{promotion.budget}}</small>
                </template>
              </td>
              <td class="col-50"><i class="icon-monitor pointIcon" @click="toggleDetail(`collapse-${promotionIndex}`, promotion.id)"></i></td>
              <td class="col-50">
                <template v-if="promotion.state == 'INITIAL' || promotion.state == 'DELETED'">
                  <i class="icon-loader text-secondary"></i>
                </template>
                <template v-else>
                  <router-link :to="'/admin/promotion-reviewing?id='+ promotion.id"
                               @click.native="scrollToTop"><i class="icon-loader pointIcon"></i>
                  </router-link>
                </template>
              </td>
            </tr>
            <tr :key="`${promotion.id}-2`" :ref="`collapse-${promotionIndex}`" class="collapse" data-parent="#dataTable">
              <td colspan="8">
                <div class="m-1">
                  <div class="mb-2 text-left">
                    <div class="text-primary">訂單編號</div>
                    <div>{{promotion.transactionId}}</div>
                  </div>
                  <div class="mb-2 text-left">
                    <div class="text-primary">新聞稿編號
                      <a class="ml-1 text-primary" style="cursor: pointer;"
                         @click="showModal({modalId: ModalList().PREVIEW_MODAL, payload: { type: 'adminCurrentPost', postId: promotion.postId }})">檢視<i class="ml-1 icon-eye"></i></a>
                    </div>
                    <div>{{promotion.postId}}</div>
                  </div>
                  <div class="mb-2 text-left">
                    <div class="text-primary">發布單位編號
                      <a class="ml-1 text-primary" style="cursor: pointer;"
                         @click="showModal({modalId: ModalList().USER_PREVIEW_MODAL, payload: { userId: promotion.userId }})">檢視<i class="ml-1 icon-eye"></i></a>
                    </div>
                    <div>{{promotion.userId}}</div>
                  </div>
                  <div class="mb-2 text-left">
                    <div class="text-primary">推廣編號
                      <a class="ml-1 text-primary" style="cursor: pointer;"
                         @click="showModal({modalId: ModalList().PROMOTION_MODAL, payload: { step: 1, mode: 'display',promotion: promotion}})">檢視<i class="ml-1 icon-eye"></i></a>
                    </div>
                    <div>{{promotion.id}}</div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
        <div v-else id="result-hint" class="d-flex justify-content-center">
          <template v-if="isEmptyList">尚無資料</template>
          <template v-else><loading/></template>
        </div>
      </div>
      <!-- Pagination -->
      <ul v-if="currentPageDataList.length > 0" class="pagination justify-content-center">
        <li class="page-item">
          <a class="page-link" @click="currentPage != 1 ? getPreviousPage(currentPage, getPromotionList):''"><i class="fa fa-angle-left"></i></a>
        </li>
        <template v-for="page in range">
          <li class="page-item" :class="{'active': page === currentPage}" :key="page">
            <a class="page-link" @click="currentPage != page ? toTargetPage(page, getPromotionList):''" style="cursor: default;">{{page}}</a>
          </li>
        </template>
        <li class="page-item">
          <a class="page-link" @click="currentPage != totalPage ? toNextPage(currentPage, totalPage, getPromotionList):''"><i class="fa fa-angle-right"></i></a>
        </li>
      </ul>
      <!-- end: Pagination -->
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Loading from "@/components/common/Loading";
import { mapGetters, mapMutations } from "vuex";
import { utilMixin } from "@/mixins/utilMixin";
import { findPromotionList } from "@/service/api/internal/admin/promotion";

export default {
  name: "AllPromotionList",
  mixins: [utilMixin],
  components: {
    Loading
  },
  data(){
    return{
      search:{
        transactionId: '',
        postId: '',
        userId: '',
        title: '',
        state: ''
      }
    }
  },
  computed:{
    ...mapGetters('adminStore', ['getAdminPromotionStateName','getAdminPromotionStateStyle'])
  },
  mounted() {
    this.initSearchParam()
    this.getPromotionList(this.currentPage)
  },
  methods: {
    ...mapMutations(['showModal']),
    initSearchParam(){
      let search = JSON.parse(JSON.stringify(this.search))
      this.search = this.initSearchData(search)
      if(!this.search.state){
        this.search.state= ''
      }
    },
    async getPromotionList(page){
      let currentPage = this.checkPageValid(page, this.currentPage, this.totalPage)
      this.currentPage = currentPage
      let query = this.getSearchQuery(this.currentPage, this.show, this.search)
      let response = await findPromotionList(query)
      this.currentPageDataList = response.data
      this.totalPage = response.totalPage
      this.totalData = response.totalElement
      if(this.currentPageDataList.length == 0){
        this.isEmptyList = true
      }
    },
    toggleDetail(refId){
      if(this.$refs[refId]){
        $(this.$refs[refId]).collapse('toggle')
      }
    },
    getPercentage(budget, consumedBudget){
      return this.round(consumedBudget/budget)
    },
    round(num) {
      let m = Number((Math.abs(num) * 100).toPrecision(15));
      return Math.round(m) * Math.sign(num);
    },
    resetSearch(){
      let state = this.search.state
      this.search = {}
      this.search.state = state
      this.search.transactionId = ""
      this.search.postId = ""
      this.search.userId = ""
      this.search.title = ""
      if(window.location.search) {
        this.searchByQuery(this.getPromotionList, {page: 1, ...this.search})
      }
    }
  }
}
</script>

<style scoped>
.pointIcon{
  cursor: pointer;
  color: rgb(24 135 255)
}

.progress{
  background-color: #b1b3b5;
}

.progress .progress-bar {
  height: 4px;
  background-color: #44ad1f;
}

.table-outter {
  overflow-x: scroll;
}

.col-250 {
  min-width: 250px;
}

.col-100 {
  min-width: 100px;
}

.col-50 {
  min-width: 50px;
}
</style>
