<template>
  <div :ref="modalId" class="modal fade" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header text-bold text-center">
          提示
        </div>
        <div class="modal-body row text-break">
          <i class="col-4 fas fa-exclamation fa-5x align-self-center text-center"></i>
          <div id="modalHintContent" class="col-8 align-self-center text-bold text-secondary">
            <div>{{getTitle}}</div>
          </div>
        </div>
        <div class="modal-footer border-0">
          <button type="button" class="btn btn-info" @click="hide">取消</button>
          <button type="button" class="btn btn-primary" @click="confirm">確定</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import ModalList from "@/const/modal";
import { mapMutations } from "vuex";
import { utilMixin } from "@/mixins/utilMixin";
import { updateSiteConfig } from "@/service/api/internal/admin/siteConfig";

export default {
  name: "SiteConfigConfirmModal",
  mixins: [utilMixin],
  data(){
    return {
      modalId: ModalList.SITE_CONFIG_CONFIRM_MODAL,
      type: '',
      siteConfig: {},
      execFunc: null
    }
  },
  methods:{
    ...mapMutations({
      showModal:'showModal'
    }),
    show(data){
      this.type = data.type ? data.type : ''
      this.siteConfig = data.siteConfig ? data.siteConfig : {}
      this.execFunc = data.execFunc ? data.execFunc : null

      $(this.$refs[this.modalId]).modal('show')
    },
    hide(){
      $(this.$refs[this.modalId]).modal('hide')
    },
    confirm(){
      if(this.type == 'cancel'){
        this.cancel()
      }else if (this.type == 'save'){
        this.save()
      }
    },
    cancel(){
      this.hide()
      this.execFunc()
    },
    async save(){
      await updateSiteConfig(JSON.stringify(this.siteConfig))
      .then(res => {
        this.hide()
        if(res.status == 200){
          if(this.execFunc){
            this.execFunc()
            this.showSuccessModal({ maxCountDownSec: 3, content: `已成功修改全站設定` })
          }
        }else{
          this.showErrorModal()
        }
      })
    }
  },
  computed:{
    getTitle(){
      let title = ''
      switch (this.type){
        case "cancel":
          title = '是否要放棄修改？'
          break;
        case "save":
          title = '是否要儲存修改？'
          break;
      }
      return title
    }
  }
}
</script>

<style scoped>

</style>
