<template>
  <div class="d-flex flex-column overflow-hidden">
    <Header
      :isLandingPage="true"
      :navList="helpGuideNavList"
      :currentNavItem="currentNavItem"
      @toggle-dropdown="toggleDropdown($event)"/>
    <!-- end of header -->

    <main class="main-content">
      <!-- banner 文案 + 刊登新聞稿按鈕 -->
      <section class="promotion-feature-banner-section overflow-hidden">
        <section class="promotion-section">
          <article class="d-flex flex-column align-items-center justify-content-center">
            <h1 class="banner-title text-center">超實用新聞稿發佈解決方案</h1>
            <h4 class="sub-title text-center">
              SUBMIT YOUR PRESS RELEASE NOW !
            </h4>
            <p class="promotion-content text-center">
              在為寄送新聞稿給媒體而煩惱嗎？擔心被已讀不回？<br/>
              想要透過一站式管理、同時加強曝光嗎？ <br/>
              NewsBuffet 新聞稿自助吧是您省時省力的最好幫手！
            </p>
            <button class="btn btn-in-nb-blue btn-xl mt-4" @click="publish">
              按我刊登新聞稿
            </button>
          </article>
        </section>
        <!-- 服務特色 -->
        <section id="nav1" class="feature-section d-flex align-items-center justify-content-center">
          <div class="section-content-wrapper d-flex justify-content-center justify-content-sm-start">
            <article class="feature-content d-flex align-items-center justify-content-center">
              <ul class="feature-list m-0">
                <li class="feature-item"
                    v-for="item in featureList"
                    :key="item.title">
                  <span class="feature-title">{{ item.title }}</span>
                  <span class="feature-description">{{item.description}}</span>
                </li>
              </ul>
            </article>
          </div>
        </section>
        <!-- 裝飾用的背景方塊 -->
        <transition name="slideIn" appear>
          <div class="banner-bg-wrapper d-flex justify-content-end align-items-end">
            <img
              class="blocks-bg img-fluid banner-bg"
              src="@/assets/images/help-guide/banner-block.svg"
              alt=""/>
            <img
              class="blocks-bg img-fluid banner-bg is-shadow"
              src="@/assets/images/help-guide/banner-block-shadow.svg"
              alt=""/>
          </div>
        </transition>
        <!-- 背景方塊 的 shadow  -->
        <!-- <div class="banner-bg d-flex justify-content-end align-items-end is-shadow">
        </div> -->

        <!-- 裝飾用的背景方塊 -->
      </section>

      <!-- 333法則 -->
      <section id="nav1-1" class="d-flex justify-content-center rule-section white-center-section">
        <div class="section-content-wrapper-lg d-flex flex-column align-items-center">
          <h2 class="section-title-color">333法則</h2>
          <span class="sub-title">讀者接觸無上限，6分鐘搞定！</span>
          <ul class="row rule-list">
            <li class="d-flex flex-column justify-content-center align-items-center rule-list-item col-12 col-md-4"
                v-for="card in ruleCardList"
                :key="card.title">
              <div class="card-content-wrapper d-flex flex-column align-items-center mb-5 mb-md-0">
                <span class="card-label d-flex flex-column align-items-center">
                  <span class="number">{{ card.number }}</span>
                  <span class="unit">{{ card.unit }}</span>
                </span>
                <div class="img-wrapper d-flex justify-content-center align-items-center">
                  <img class="mh-100" :src="card.imgUrl" alt="" />
                </div>
                <h5 class="rule-title">{{ card.title }}</h5>
                <p class="rule-content">{{ card.content }}</p>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <!-- 為何選擇NEWSBUFFET -->
      <section id="nav1-2" class="reason-section d-flex justify-content-center">
        <div class="section-content-wrapper row d-flex align-items-end">
          <div class="reason-text-content d-flex flex-column justify-content-center align-items-start col-12 col-sm-6">
            <article class="px-4 pt-5 py-4 p-lg-0">
              <h3 class="section-title">
                <span style="font-weight: 400">為何選擇</span><br/>
                <span>NEWSBUFFET?</span>
              </h3>
              <ul class="reason-list">
                <li class="d-flex align-items-center reason-list-item"
                    v-for="(item, index) in reasonList"
                    :key="index">
                  <span class="list-item-dot d-flex align-items-center justify-content-center">
                    <img src="@/assets/images/help-guide/icon-check.svg" alt=""/>
                  </span>
                  <span>{{ item.title }}</span>
                </li>
              </ul>
            </article>
          </div>

          <div class="illustration-wrapper col-12 col-sm-6 d-flex justify-content-end">
            <img
              src="@/assets/images/help-guide/illustration-01.png"
              alt=""
              class="person-with-computer"/>
          </div>
        </div>
      </section>

      <!-- +1 ptt spread broadcast -->

      <section id="nav1-3" class="broadcast-section d-flex align-items-center justify-content-center">
        <div class="section-content-wrapper row d-flex flex-column-reverse flex-sm-row align-items-center">
          <div class="illustration-wrapper d-flex justify-content-end col-12 col-sm-6">
            <img src="@/assets/images/help-guide/illustration-ptt-user.png" alt=""/>
          </div>
          <div class="d-flex flex-column align-items-end justify-content-center col-12 col-sm-6 article-wrapper">
            <article class="px-4 pt-5 py-4 p-lg-0 w-100">
              <h3 class="section-title">
                <span style="font-weight: 400">＋1 擴散至</span>
                <span>鄉民晚報</span>
              </h3>
              <div>
                <p class="main-text-content">
                  除了新聞稿自助吧曝光以外，加碼免費曝光鄉民晚報<br/>網頁版和
                  APP，增加曝光渠道、提升新聞稿能見度。
                </p>
                <a class="btn-in-nb-transparent btn btn-xl" href="https://pnn.tw/" target="_blank">前往鄉民晚報</a>
              </div>
            </article>
          </div>
        </div>
      </section>
      <!-- increase SEO -->
      <section id="nav1-4" class="reason-section d-flex justify-content-center">
        <div class="section-content-wrapper row d-flex align-items-end">
          <div class="reason-text-content d-flex flex-column justify-content-center align-items-start col-sm-6">
            <article class="px-4 pt-5 py-4 p-lg-0">
              <h3 class="section-title">
                <span style="font-weight: 400">增加</span>
                <span>網路SEO能見度</span>
              </h3>
              <p class="main-text-content">
                每篇新聞稿擁有獨立網頁，獨家強化的 SEO 技術，<br/>提高搜尋引擎的網頁排名，增加關鍵字被搜尋機會。
              </p>
            </article>
          </div>

          <div class="illustration-wrapper person-with-magnifier d-flex justify-content-end col-12 col-sm-6">
            <img src="@/assets/images/help-guide/illustration-02.png"
                 alt=""
                 class="person-with-computer person-with-magnifier"/>
          </div>
        </div>
      </section>
      <!-- procedure -->
      <section id="nav2" class="d-flex justify-content-center white-center-section procedure-section">
        <div class="section-content-wrapper-lg d-flex flex-column align-items-center">
          <h2 class="section-title-color">推廣新聞稿</h2>
          <span class="sub-title">
            透過廣告聯播網，高度曝光新聞稿，加強推廣給更多的潛在客戶<br/>小額（費用）、簡單（操作）、快速（傳播）<br/>只要三分鐘、三個步驟就搞定！
          </span>
          <div class="procedure-out-wrapper d-flex flex-column align-items-center">
            <ul class="procedure-progress d-flex justify-content-between align-items-baseline">
              <li
                v-for="(step, index) in procedureList"
                :key="step.order"
                :class="{ active: index <= currentShowStepIndex }"
                class="one-step d-flex flex-column align-items-center justify-content-center"
                @click="showStep(index)">
                <span class="step-order d-flex justify-content-center align-items-center">
                  {{ step.order }}
                </span>
                <span class="step-title text-center">{{ step.title }}</span>
              </li>
            </ul>
            <ul class="procedure-steps">
              <div class="w-100"
                   v-touch:swipe.left="swipeHandler"
                   v-touch:swipe.right="swipeRightHandler">
                <transition name="fadeIn">
                  <img
                    :src="procedureList[currentShowStepIndex].imgUrl"
                    :key="currentShowStepIndex"
                    alt=""
                    class="img-fluid"/>
                </transition>
              </div>
            </ul>
          </div>
        </div>
      </section>

      <!-- partner -->
      <section id="nav3" class="d-flex justify-content-center white-center-section partner-section">
        <div class="section-content-wrapper-sm d-flex flex-column align-items-center">
          <h2 class="section-title-color">媒體合作</h2>
          <span class="sub-title">
            身為新聞稿匯聚平台，歡迎各大媒體洽談商務合作方案！
          </span>
          <ul class="d-flex align-items-center row w-100 brand-logo-list">
            <li class="col-4 d-flex justify-content-center py-4">
              <img
                class="img-fluid"
                src="@/assets/images/help-guide/logo-2.png"
                alt=""/>
            </li>
            <li class="col-4 d-flex justify-content-center py-4">
              <img
                class="img-fluid"
                style="mix-blend-mode: darken"
                src="@/assets/images/help-guide/logo-1.png"
                alt=""/>
            </li>
            <li class="col-4 d-flex justify-content-center py-4">
              <img
                class="img-fluid"
                src="@/assets/images/help-guide/logo-3.png"
                alt=""/>
            </li>
          </ul>
        </div>
      </section>
    </main>
    <Footer></Footer>
    <transition name="fadeUp" leave-active-class="drop-down-transition">
      <div class="to-top-btn" ref="scrollTopBtn" @click="toTop" v-if="isGoTopShow">
        <img
          src="@/assets/images/help-guide/dropdown-arrow.svg"
          alt=""
          class="toggle-icon"/>
      </div>
    </transition>
  </div>
</template>

<script>

import { utilMixin } from "@/mixins/utilMixin";
import Header from "@/components/common/Header";
import Footer from "@/components/common/Footer";

export default {
  name: "HelpGuide",
  mixins: [utilMixin],
  data() {
    return {
      // header : helpGuide nav list
      helpGuideNavList: [
        {
          title: "服務特色",
          id: "nav1",
          isChildShow: true,
          childList: [
            {
              title: "333 法則",
              id: "nav1-1",
            },
            {
              title: "為何選擇NEWSBUFFET",
              id: "nav1-2",
            },
            {
              title: "＋1 擴散至鄉民晚報",
              id: "nav1-3",
            },
            {
              title: "增加網路SEO能見度",
              id: "nav1-4",
            },
          ],
        },
        {
          title: "加強推廣",
          id: "nav2",
        },
        {
          title: "媒體合作",
          id: "nav3",
        },
      ],
      // header : current navigation
      currentNavItem: "",
      // 特色
      featureList: [
        {
          title: "免費就能用",
          description: "即刻上手的新聞稿發佈平台",
        },
        {
          title: "擴散好方便",
          description: "立即加強曝光您的新聞稿",
        },
        {
          title: "品牌最愛用",
          description: "超過1500家商業客戶愛用",
        },
      ],
      // 333法則
      ruleCardList: [
        {
          title: "刊登新聞稿",
          content: "輸入標題和新聞稿內容，\n免費刊登、即刻曝光。",
          imgUrl: require(`@/assets/images/help-guide/icon-document.svg`),
          number: 3,
          unit: "分鐘",
        },
        {
          title: "推廣新聞稿",
          content:
            "小額投遞廣告，步驟簡單、\n快速上手，突破同溫層、增\n加曝光。",
          imgUrl: require(`@/assets/images/help-guide/icon-broadcast.svg`),
          number: 3,
          unit: "分鐘",
        },
        {
          title: "推廣流程",
          content: "step 1．發出稿件\nstep 2．上傳素材\nstep 3．付費投放",
          imgUrl: require(`@/assets/images/help-guide/icon-procedure.svg`),
          number: 3,
          unit: "步驟",
        },
      ],
      // 為何選擇newsbuffet
      reasonList: [
        {
          title: "0 元刊登費",
        },
        {
          title: "3 分鐘上稿完成",
        },
        {
          title: "100 + 新聞稿 / 天",
        },
        {
          title: "800 + 廣告代理商與品牌主使用",
        },
      ],
      // 操作流程
      procedureList: [
        {
          title: "按下「推廣」按鈕",
          order: "1",
          imgUrl: require(`@/assets/images/help-guide/broatcast-step1.png`),
        },
        {
          title: "調整新聞稿標題、圖片",
          order: "2",
          imgUrl: require(`@/assets/images/help-guide/broatcast-step2.png`),
        },
        {
          title: "線上刷卡",
          order: "3",
          imgUrl: require(`@/assets/images/help-guide/broatcast-step3.png`),
        },
      ],
      //
      currentShowStepIndex: 0,
      isGoTopShow: false,
    };
  },
  components: {
    Header,
    Footer,
  },
  created() {},
  methods: {
    publish() {
      // 按我刊登新聞稿按鈕導向位置
      this.checkLoginStatusAndRedirect("/me/post-edit", "postEdit");
    },
    // show dropdown
    toggleDropdown(item) {
      this.currentNavItem = item.id;
    },
    // show current step
    showStep(index) {
      if (index !== this.currentShowStepIndex) {
        this.currentShowStepIndex = index;
      } else {
        return;
      }
    },
    // 左滑動
    swipeHandler() {
      if (this.currentShowStepIndex < 2) {
        this.currentShowStepIndex += 1;
      } else {
        return;
      }
    },
    // swipe to right
    swipeRightHandler() {
      if (this.currentShowStepIndex > 0) {
        this.currentShowStepIndex -= 1;
      } else {
        return;
      }
    },
    // 置頂
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    // 取滾動位置標記navigator
    getCurrentPosition(scrollTop) {
      let headerHeight = document.querySelector("#header").offsetHeight;
      let navStart1 = document.querySelector("#nav1").offsetTop - headerHeight;
      let navStart2 = document.querySelector("#nav2").offsetTop - headerHeight;
      let navStart3 = document.querySelector("#nav3").offsetTop - headerHeight;
      if (scrollTop >= navStart1 && scrollTop < navStart2) {
        this.helpGuideNavList.forEach((element) => {
          if (element.id == "nav1") {
            this.currentNavItem = element.id;
            return;
          }
        });
      } else if (scrollTop >= navStart2 && scrollTop < navStart3) {
        this.helpGuideNavList.forEach((element) => {
          if (element.id == "nav2") {
            this.currentNavItem = element.id;
          }
        });
      } else if (scrollTop >= navStart2) {
        this.helpGuideNavList.forEach((element) => {
          if (element.id == "nav3") {
            this.currentNavItem = element.id;
          }
        });
      } else {
        this.currentNavItem = "";
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.hideDropdown);
    document.addEventListener("scroll", () => {
      let windowInnerHeight = window.innerHeight;
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
        this.getCurrentPosition(scrollTop);
      this.$nextTick(() => {
        if (scrollTop < windowInnerHeight) {
          // this.$refs.scrollTopBtn.classList.add("is-hide"); // 下滑隱藏
          this.isGoTopShow = false;
        } else {
          // this.$refs.scrollTopBtn.classList.remove("is-hide");
          this.isGoTopShow = true;        }
      });
    });
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
  text-decoration: none;
  --nb-blue-main: #164573;
  --nb-blue-light: #5bbacd;
  --nb-blue-grey: #a6b6c9;
  --nb-black: #212121;
  --nb-brown: #caa575;
  --bg-grey-1: #e6e6e6;
  font-family: "Noto Sans TC", sans-serif;
}

.main-content {
  padding: 127px 0 0 0;
}

h1 {
  font-size: 60px;
  font-weight: 700;
}

h2 {
  font-size: 46px;
  font-weight: 700;
  letter-spacing: 0.06em;
}

h3 {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0.06em;
}

h4 {
  font-size: 31px;
}

h5 {
  font-size: 24px;
}

.section-title-color {
  color: var(--nb-blue-main);
}

/deep/.btn-in-nb-blue {
  background-color: var(--nb-blue-main);
  color: white;
  font-weight: 700;
  letter-spacing: 0.18em;
}

/deep/.btn-in-nb-transparent {
  background-color: none;
  color: var(--nb-blue-main);
  font-weight: 700;
  letter-spacing: 0.18em;
  border: 1.5px solid var(--nb-blue-main);
}

/deep/.btn-in-nb-blue:hover {
  color: white;
  /*  background-color: #0333e8;*/
}

/deep/.btn-xl {
  padding: 18px 40px;
}

ul {
  list-style: none;
}

/deep/a:hover {
  text-decoration: none;
}

/* section */

section .section-title {
  color: var(--nb-blue-main);
  margin: 0 0 28px 0;
}

section .main-text-content {
  line-height: 32px;
  letter-spacing: 0.05em;
  margin: 0 0 28px 0;
}

.white-center-section {
  padding: 110px 20px;
}

.white-center-section .sub-title {
  font-size: 18px;
  margin: 12px 0;
  letter-spacing: 0.05em;
  line-height: 1.75em;
  text-align: center;
}

/* banner section */
.promotion-feature-banner-section {
  background-color: #fff;
  position: relative;
}

.banner-bg-wrapper {
  width: 60%;
  position: absolute;
  bottom: 120px;
  right: 0;
  z-index: 2;
  animation: slideIn 0.4s linear;
}

.banner-bg {
  position: absolute;
  right: -20%;
  bottom: 50px;
  z-index: 3;
  animation: upDown 4.6s ease-in-out infinite;
  animation: upDown 4.6s cubic-bezier(0.45, 0.05, 0.55, 0.95) infinite;
  animation-delay: 0.5s;
}

.banner-bg.is-shadow {
  position: absolute;
  right: -20%;
  bottom: 0;
  transform: scale(1.05);
  transition: ease-in 0.2s;
  z-index: 2;
  animation: float 4.6s ease-in-out infinite;
  animation: float 4.6s cubic-bezier(0.45, 0.05, 0.55, 0.95) infinite;
  animation-delay: 0.5s;
}

/* 滑進畫面 */
@keyframes slideIn {
  0% {
    right: -50%;
  }
  100% {
    right: 0;
  }
}

/* 上下漂浮 */
@keyframes upDown {
  0% {
    bottom: 50px;
  }
  50% {
    bottom: 30px;
  }
  100% {
    bottom: 50px;
  }
}

@keyframes float {
  0% {
    transform: scale(1.02);
    bottom: -10px;
    right: -21%;
  }
  50% {
    transform: scale(0.9);
    bottom: 10px;
    right: -18.2%;
  }
  100% {
    transform: scale(1.02);
    bottom: -10px;
    right: -21%;
  }
}

.promotion-feature-banner-section .banner-title {
  letter-spacing: 0.06em;
}

.promotion-feature-banner-section .sub-title {
  letter-spacing: 0.02em;
  margin: 0 0 16px 0;
}

.promotion-feature-banner-section .promotion-content {
  line-height: 1.75em;
  font-size: 18px;
  letter-spacing: 0.05em;
  margin: 45px 0;
  color: #4a4a4a;
}

.promotion-feature-banner-section .promotion-section {
  padding: 100px 0;
  position: relative;
  z-index: 3;
}

.promotion-feature-banner-section .feature-section {
  background-color: #edeff2;
  flex-direction: column;
  overflow: hidden;
}

.section-content-wrapper {
  width: 100%;
  max-width: 1000px;
}

.section-content-wrapper-lg {
  width: 100%;
  max-width: 1440px;
}

.section-content-wrapper-sm {
  width: 100%;
  max-width: 890px;
}

.feature-content {
  position: relative;
  width: 600px;
  height: 450px;
  z-index: 2;
}

.feature-content::after {
  content: "";
  position: absolute;
  background: #fff;
  display: flex;
  transform: rotateZ(135deg) skew(12deg, 12deg);
  width: 612px;
  height: 612px;
  z-index: -1;
  border-radius: 24px;
}

.feature-content .feature-item {
  font-size: 20px;
  line-height: 2.4em;
  letter-spacing: 0.05em;
  color: var(--nb-blue-main);
}

.feature-content .feature-title {
  font-weight: 700;
  margin: 0 20px 0 0;
}

.feature-content .feature-description {
  font-weight: 300;
}

/* 333 rule */

ul.rule-list {
  max-width: 1440px;
  width: 100%;
  margin: 80px 0 0 0;
}

.rule-list .rule-list-item {
}

.rule-list .rule-list-item .card-content-wrapper {
  border: 2px solid var(--nb-blue-main);
  color: var(--nb-blue-main);
  border-radius: 8px;
  width: 80%;
  height: 100%;
  flex: 1;
  padding: 28% 0 5% 0;
  position: relative;
}

.rule-list .rule-list-item .card-content-wrapper .card-label {
  background-color: var(--nb-brown);
  position: absolute;
  top: -3%;
  left: 5%;
  color: #fff;
  padding: 8px 20px;
  border-radius: 8px;
}

.rule-list .rule-list-item .card-content-wrapper .card-label .number {
  font-size: 36px;
  line-height: 40px;
}

.rule-list .rule-list-item .card-content-wrapper .card-label .unit {
  font-size: 19px;
  line-height: 20px;
}

.rule-list .rule-list-item .img-wrapper {
  height: 60px;
  width: 100%;
}

.rule-list .rule-list-item .rule-title {
  font-weight: 700;
  margin: 24px 0;
}

.rule-list .rule-list-item .rule-content {
  letter-spacing: 0.05em;
  white-space: pre-line;
  line-height: 1.75em;
}

/**/

.reason-section {
  background-color: #edeff2;
}

.reason-text-content {
  height: 100%;
}

.reason-text-content .reason-list-item {
  font-size: 20px;
  font-weight: 700;
  line-height: 2.5em;
}

.reason-text-content .reason-list-item .list-item-dot {
  background-color: #d8e0e9;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  margin: 0 24px 0 0;
}

.broadcast-section {
  background-color: #e1e5eb;
}

.reason-section,
.broadcast-section {
  padding: 100px 20px 0 20px;
}

/*SEO*/

.white-dashboard {
  transform: translate(-344px, 307px);
}

.white-window-1 {
  transform: translate(-140px, 318px);
}

/* procedure */
.procedure-out-wrapper {
  max-width: 1000px;
  width: 100%;
}
.procedure-out-wrapper .procedure-progress {
  width: 100%;
  max-width: 900px;
  margin: 60px 0 32px 0;
}

.procedure-progress .one-step {
  color: var(--nb-blue-grey);
  font-weight: 700;
  width: 300px;
  position: relative;
  transition: linear 0.2s;
  cursor: pointer;
}

.procedure-progress .one-step.active {
  color: #fff;
  transition: linear 0.2s;
}

.procedure-progress .one-step .step-order {
  width: 50px;
  height: 50px;
  font-size: 24px;
  border-radius: 100%;
  border: 4px solid var(--nb-blue-grey);
  position: relative;
  background-color: #fff;
  z-index: 2;
  transition: linear 0.2s;
}

.procedure-progress .one-step.active .step-order {
  border: none;
  background-color: var(--nb-brown);
  transition: linear 0.2s;
}

.procedure-progress .one-step .step-title {
  letter-spacing: 0.05em;
  font-size: 20px;
  margin: 20px 0 0 0;
  transition: linear 0.2s;
}

.procedure-progress .one-step.active .step-title {
  color: var(--nb-blue-main);
  transition: linear 0.2s;
}

.procedure-progress .one-step + .one-step::before {
  content: "";
  display: inline-block;
  width: 100%;
  height: 1.5px;
  background-color: var(--nb-blue-grey);
  position: absolute;
  right: 50%;
  top: 25px;
  transition: linear 0.2s;
}

.procedure-progress .one-step + .one-step.active::before {
  background-color: var(--nb-brown);
  transition: linear 0.2s;
}

.to-top-btn {
  position: fixed;
  bottom: 26px;
  right: 26px;
  display: flex;
  background-color: #00000040;
  height: 36px;
  width: 36px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  transition: 0.3s cubic-bezier(0.67, 0.07, 0.2, 0.75);
  cursor: pointer;
}

.to-top-btn > img {
  transform: rotateZ(180deg);
  filter: invert(100%) sepia(0%) saturate(7497%) hue-rotate(130deg)
    brightness(105%) contrast(91%);
}

/**/
.partner-section {
  background-color: #edeff2;
  background-image: url("~@/assets/images/help-guide/partner-bg.svg");
  background-position: center;
  background-repeat: no-repeat;
}

.partner-section .brand-logo-list {
  margin: 45px 0 0 0;
}

/**/
.fadeIn-enter-active {
  transition: opacity 0.3s;
}

.fadeIn-enter {
  opacity: 0;
}

.fadeUp-enter-active,
.fadeUp-leave-active {
  transition: opacity .3s;
  opacity: 0;
  transform: translateY(60px);
}

.fadeUp-enter,
.fadeUp-leave-to {
  opacity: 0;
}

.drop-down-transition {
  transform: translateY(60px);
  transition: linear .3s;
}


/* 1200 lg */
@media (max-width: 1200px) {
  .banner-bg-wrapper {
    bottom: 135px;
  }

  .feature-content {
    height: 350px;
  }

  .feature-content::after {
    width: 412px;
    height: 412px;
  }

  .feature-content .feature-item {
    font-size: 18px;
  }

  .feature-content .feature-title {
    margin: 0 12px 0 0;
  }

  .rule-list .rule-list-item .card-content-wrapper {
    width: 100%;
  }

  .rule-list .rule-list-item .card-content-wrapper .card-label {
    padding: 8px 16px;
  }

  .rule-list .rule-list-item .card-content-wrapper .card-label .number {
    font-size: 28px;
  }

  .rule-list .rule-list-item .card-content-wrapper .card-label .unit {
    font-size: 15px;
  }
}

/* 992 lg */
@media (max-width: 992px) {
  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 16px;
  }

  h5 {
    font-size: 16px;
  }

  .promotion-feature-banner-section .promotion-content {
    font-size: 16px;
    margin: 24px 0;
  }

  .feature-content {
    height: 250px;
  }

  .feature-content::after {
    width: 312px;
    height: 312px;
  }

  .feature-content .feature-item {
    font-size: 15px;
  }

  .reason-text-content .reason-list-item {
    font-size: 16px;
  }

  .reason-text-content .reason-list-item .list-item-dot {
    width: 20px;
    height: 20px;
    margin: 0 12px 0 0;
  }
}

/* 768 lg */
@media (max-width: 768px) {
  /deep/.btn-xl {
    padding: 8px 24px;
    font-size: 14px;
  }

  .banner-bg {
    bottom: 40%;
  }

  .rule-list .rule-list-item .card-content-wrapper .card-label {
    padding: 6px 12px;
  }

  .rule-list .rule-list-item .card-content-wrapper .card-label .number {
    font-size: 24px;
    line-height: 30px;
  }

  .rule-list .rule-list-item .img-wrapper {
    max-height: 40px;
  }

  .rule-list .rule-list-item .rule-content {
    font-size: 14px;
    line-height: 1.65em;
  }

  ul.rule-list {
    margin: 40px 0 0 0;
  }

  .rule-list .rule-list-item .card-content-wrapper {
    padding: 20% 0 10% 0;
    max-width: 320px;
  }

  .person-with-magnifier {
    width: 300px;
  }

  .reason-section,
  .broadcast-section {
    padding: 60px 20px 0 20px;
  }
}
/* 576 lg */
@media (max-width: 576px) {
  h1 {
    font-size: 26px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 16px;
  }

  .main-content {
    padding: 70px 0 0 0;
  }

  /deep/.btn-xl {
    padding: 8px 24px;
    font-size: 14px;
  }

  .banner-bg-wrapper {
    bottom: 250px;
  }

  .promotion-feature-banner-section .promotion-section {
    padding: 80px 0;
  }

  .promotion-feature-banner-section .promotion-content {
    font-size: 14px;
    margin: 20px 0;
  }

  .white-center-section {
    padding: 60px 32px;
  }

  .reason-text-content {
    height: fit-content;
    padding: 0;
  }

  .illustration-wrapper > img {
    width: 300px;
  }

  .reason-text-content .reason-list-item {
    font-size: 14px;
  }

  .reason-section .person-with-computer {
    width: 300px;
  }

  .reason-section .white-window {
    position: absolute;
    width: 120px;
    transform: translate(-192px, -57px);
  }

  .reason-section .logo-window {
    position: absolute;
    width: 150px;
    transform: translate(-120px, -98px);
  }

  .reason-section .logo-window .mini-logo {
    left: 40px;
    top: 36px;
    width: 75px;
  }

  .reason-section,
  .broadcast-section {
    padding: 10px 20px 0 20px;
  }

  section .main-text-content {
    font-size: 15px;
    width: 80%;
  }

  .white-center-section .sub-title {
    font-size: 15px;
  }

  .procedure-progress .one-step .step-order {
    width: 40px;
    height: 40px;
    font-size: 15px;
    border: 2px solid var(--nb-blue-grey);
  }

  .procedure-progress .one-step .step-title {
    font-size: 14px;
  }

  .procedure-progress .one-step + .one-step::before {
    top: 20px;
  }

  .partner-section .brand-logo-list {
    margin: 16px 0 0 0;
  }

  .rule-list .rule-list-item .card-content-wrapper {
    padding: 28% 0 10% 0;
    width: 100%;
    max-width: 320px;
  }
}

@keyframes upDown {
  0% {
    bottom: 40px;
  }
  50% {
    bottom: 30px;
  }
  100% {
    bottom: 40px;
  }
}

@keyframes float {
  0% {
    transform: scale(1.02);
    bottom: 15px;
    right: -21%;
  }
  50% {
    transform: scale(0.85);
    bottom: 5px;
    right: -18.2%;
  }
  100% {
    transform: scale(1.02);
    bottom: 15px;
    right: -21%;
  }
}
</style>
