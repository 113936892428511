<template>
  <div>
    <!--Services-->
    <div class="widget clearfix widget-categories">
      <h4 class="widget-title">服務選單</h4>
      <div class="list-group">
        <template v-for="(tab, key) in adminTab">
          <router-link :to="{name: tab.pathName}"
                       :key="key"
                       @click.native="scrollToTop"
                       class="list-group-item list-group-item-action"
                       :class="{'active': checkPath(key)}">{{tab.tabName}}
          </router-link>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Const from "@/const";
import { mapState } from "vuex";
import { utilMixin } from "@/mixins/utilMixin";

export default {
  name: "SideBarServices",
  mixins: [utilMixin],
  computed:{
    ...mapState('adminStore',['adminTab'])
  },
  methods: {
    checkPath(tab){
      if(this.$route.fullPath == `/${Const.MANAGEMENT_LABEL}`){
        this.$router.push({
          name: this.adminTab.userList.pathName
        })
      }
      return this.$route.meta.tab == tab
    },
  }
}
</script>

<style scoped>

</style>
