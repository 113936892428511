import axios from "@/plugins/axios";

// client 相關的 api
let baseUrl = '/'

if(process.env.VUE_APP_baseUrl) {
    baseUrl = process.env.VUE_APP_baseUrl
}

baseUrl += 'api/internal/admin/client'


// add new client
export function createClient(clientCreateDTO){
    return axios.post(baseUrl, clientCreateDTO, { headers: {'Content-Type': 'application/json'} })
}

// find client list
export function findClientList(query){
    return axios.get(`${baseUrl}/list?${query}`).then(res=>res.data)
}

// regenerate clientId and clientSecret
export function regenerateClientIdAndClientSecret(id){
    return axios.put(`${baseUrl}/regenerate/${id}`)
}

// update client name
export function updateName(clientUpdateDTO){
    return axios.put(`${baseUrl}/name`, clientUpdateDTO, { headers: {'Content-Type': 'application/json'} })
}

// update client isActive status
export function deleteClientById(id){
    return axios.delete(`${baseUrl}/${id}`)
}
