<template>
  <div :ref="modalId" class="modal fade" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header text-bold text-center">
          提示
        </div>
        <div class="modal-body row text-break">
          <i class="col-4 fas fa-exclamation fa-5x align-self-center text-center"></i>
          <div id="modalHintContent" class="col-8 align-self-center text-bold text-secondary">
            <div>是否要刪除？</div>
            <div class="text-primary mt-2">Client</div>
            {{ name }}
            <div class="text-red mt-2">請注意：一旦刪除後，無法復原</div>
          </div>
        </div>
        <div class="modal-footer border-0">
          <button type="button" class="btn btn-info" @click="hide">取消</button>
          <button type="button" class="btn btn-primary" @click="deleteClient">確定</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import ModalList from "@/const/modal";
import { mapMutations } from "vuex";
import { utilMixin } from "@/mixins/utilMixin";
import { deleteClientById } from "@/service/api/internal/admin/client";

export default {
  name: "ClientDeleteModal",
  mixins: [utilMixin],
  data(){
    return {
      modalId: ModalList.CLIENT_DELETE_MODAL,
      id: '',
      name: '',
      execFunc: null
    }
  },
  methods: {
    ...mapMutations({showModal:'showModal'}),
    show(data){
      this.id = data.id ? data.id : ''
      this.name = data.name ? data.name : ''
      this.execFunc = data.execFunc ? data.execFunc : null

      $(this.$refs[this.modalId]).modal('show')
    },
    hide(){
      $(this.$refs[this.modalId]).modal('hide')
    },
    async deleteClient(){
      await deleteClientById(this.id).then(res => {
        this.hide()
        if(res.status == 204){
          if(this.execFunc){
            this.execFunc()
          }
          this.showSuccessModal({ maxCountDownSec: 3, content: `已成功刪除 ${this.name}` })
        }else{
          this.showErrorModal()
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
