<template>
  <!-- userInfo -->
  <div>
    <div class="card">
      <div class="card-header">
        <span class="h4">資料設定：公開資訊</span>
        <p class="text-muted">在發布文章之前，請先填寫您的新聞稿主要公開聯繫資訊，方便新聞稿刊登時留下聯繫方式。</p>
      </div>
      <div class="card-body">
        <validation-observer v-slot="{invalid}">
          <div>
            <div class="form-row">
              <!-- 發佈單位 -->
              <div class="form-group col-md-12">
                <label for="publisher"><small class="m-1 text-red">*</small> 發佈單位</label>
                <validation-provider name="publisher" rules="publisher|required" v-slot="{ errors }">
                  <input v-model="meta.publisher" id="publisher" type="text" class="form-control" placeholder="e.g. XX股份有限公司">
                  <span class="text-red text-bold m-2">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <!-- 新聞聯絡人 -->
              <div class="form-group col-md-12">
                <label for="contactName"><small class="m-1 text-red">*</small> 新聞聯絡人</label>
                <validation-provider name="contactName" rules="contactName|required" v-slot="{ errors }">
                  <input v-model="meta.contactName" id="contactName" type="text" class="form-control" placeholder="e.g. Alice Wang 先生/小姐 （請填確切名字）">
                  <span class="text-red text-bold m-2">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <!-- 聯絡電話 -->
              <div class="form-group col-md-12">
                <label for="contactPhone"><small class="m-1 text-red">*</small> 聯絡電話</label>
                <label>臺灣市話格式：02-12345678</label>
                <label>臺灣手機格式：0912-345678</label>
                <label>國際電話格式：+44 1234567（ + 號開頭，國際冠碼與電話號碼中間有空格）</label>
                <label>以上數字及符號皆為半形。</label>
                <validation-provider name="contactPhone" rules="contactPhone|required" v-slot="{ errors }">
                  <input v-model="meta.contactPhone" id="contactPhone" class="form-control" type="tel" placeholder="e.g. 0912-345678">
                  <span class="text-red text-bold m-2">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <!-- 聯絡信箱 -->
              <div class="form-group col-md-12">
                <label for="contactEmail"><small class="m-1 text-red">*</small> 聯絡信箱</label>
                <validation-provider name="contactEmail" rules="email|required" v-slot="{ errors }">
                  <input v-model="meta.contactEmail" id="contactEmail" type="email" class="form-control" placeholder="e.g. hi@aotter.net">
                  <span class="text-red text-bold m-2">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <!-- 社群連結設定 -->
              <div class="form-group col-md-12 mt-2">
                <label>社群連結設定(非必填)</label>
              </div>
              <!-- FaceBook -->
              <div class="form-group col-md-12">
                <label for="twitter">FaceBook</label>
                <div ref="facebookArea"  class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">https://facebook.com/</span>
                  </div>
                  <input v-model="meta.contactFacebook" id="facebook" type="text" class="form-control no-left-border" placeholder="輸入帳號">
                </div>
              </div>
              <!-- Twitter -->
              <div class="form-group col-md-12">
                <label for="twitter">Twitter</label>
                <div ref="twitterArea"  class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">https://twitter.com/</span>
                  </div>
                  <input v-model="meta.contactTwitter" id="twitter" type="text" class="form-control no-left-border" placeholder="輸入帳號">
                </div>
              </div>
              <!-- Instagram -->
              <div class="form-group col-md-12">
                <label for="instagram">Instagram</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">https://www.instagram.com/</span>
                  </div>
                  <input v-model="meta.contactInstagram" id="instagram" type="text" class="form-control no-left-border" placeholder="輸入帳號">
                </div>
              </div>
            </div>
            <!-- 單位簡介 -->
            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="description">單位簡介</label>
                <textarea v-model="meta.description" id="description" name="txtMsg" class="form-control" placeholder="單位簡介，非必填。可以寫入您的單位介紹，讓新聞稿更具可靠度。" style="width: 100%; min-height: 160px;"></textarea>
              </div>
            </div>
            <!-- 預設封面 -->
            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="contactName"><small class="m-1 text-red">*</small> 文章預設封面</label>
                <label class="ml-2">建議大小 1200 * 628 (5 MB 內)</label>
                <validation-provider name="croppedCover" rules="required" v-slot="{ errors }">
                  <file-uploader
                      v-model="croppedCover"
                      :spec="{
                        sizeLimit: 5000,
                        title: '預設封面',
                        isCrop: true,
                        autoCropWidth: 1200,
                        autoCropHeight: 628,
                        fixedNumber: [1200, 628],
                        frameWidth: 100
                      }"/>
                  <span class="text-red text-bold m-2">{{ errors[0] }}</span>
                </validation-provider>
              </div>
            </div>
            <!-- 同意政策 -->
            <div class="form-group">
              <validation-provider ref="conditionValidator" name="policyCheck" rules="policyCheck" v-slot="{ errors }">
                <div class="custom-control custom-checkbox">
                  <input v-model="condition" type="checkbox" id="terms_conditions" class="custom-control-input">
                  <label class="custom-control-label" for="terms_conditions">我已閱讀並同意
                    <router-link to="/policy/publish" target="_blank">刊登政策</router-link>、
                    <router-link to="/policy/tou" target="_blank">服務條款</router-link>以及
                    <router-link to="/policy/privacy" target="_blank">隱私權政策</router-link>
                    。</label>
                </div>
                <span class="text-red text-bold m-2">{{ errors[0] }}</span>
              </validation-provider>
            </div>
            <button @click="save(invalid)" :disabled="invalid" class="btn btn-lg btn-block m-t-30 mt-3">儲存並更新</button>
          </div>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>

import ModalList from "@/const/modal";
import { mapMutations } from "vuex";
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import RuleGroup from "@/rules/ruleGroup";
import FileUploader from "@/components/common/FileUploader";
import { utilMixin } from "@/mixins/utilMixin";
import { createDefaultCover, getDefaultCover } from "@/service/api/internal/me/post";
import { getUser, updateMeta } from '@/service/api/internal/me/user';

Object.keys(RuleGroup).forEach((rule) => {
  extend(rule, RuleGroup[rule]);
});

export default {
  name: "UserInfo",
  mixins: [utilMixin],
  components:{
    ValidationObserver,
    ValidationProvider,
    FileUploader
  },
  data(){
    return{
      meta:{
      },
      croppedCover: null,
      condition: false
    }
  },
  metaInfo(){
    return {
      script:this.script
    }
  },
  watch:{
    condition(){
      this.$nextTick(this.$refs.conditionValidator.validate)
    }
  },
  created() {
    this.getUserInfo()
    this.initDefaultCover()
  },
  methods:{
    ...mapMutations(['showModal', 'hideModal']),
    async getUserInfo(){
      await getUser().then(user =>{
        this.meta = user.meta
      })
    },
    async initDefaultCover(){
      this.croppedCover = null
      let res = await getDefaultCover()
      if(res.status == 200){
        if(res.data){
          this.croppedCover = res.data.cover
        }
      }
    },
    async save(invalid){
      if(!invalid){
        this.showModal({
          modalId: ModalList.LOADING_MODAL,
        })
        let updateMetaResponse = await updateMeta(JSON.stringify(this.meta))
        let saveDefaultCoverResponse = await this.saveDefaultCover()
        this.hideModal()
        if(updateMetaResponse.status == 200 && saveDefaultCoverResponse.status == 200){
          this.scrollToTop()
          this.condition = false
          this.showSuccessModal({ maxCountDownSec: 3, content: `已成功修改公開資訊` })
        }else {
          this.showErrorModal()
        }
      }
    },
    async saveDefaultCover(){
      if(this.croppedCover instanceof File){
        const formData = new FormData()
        formData.append('file', this.croppedCover)
        return await createDefaultCover(formData)
      }else {
        return { status: 200 }
      }
    }
  },
}
</script>

<style scoped>
.input-group-text {
  border-right-color: rgb(0 0 0 / 0%);
  background-color: white;
  margin-right: -10px !important;
}

.no-left-border{
  border-left-color: rgb(0 0 0 / 0%);
  padding-left: 0 !important;
}
</style>
