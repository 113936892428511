<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-12">
          <span class="h4">新聞稿推廣管理</span>
          <hr>
          <div v-if="isReviewing" class="flex justify-between">
            <div  class="w-50">
              <div class="h6">選擇開始推廣日期</div>
              <input type="datetime-local" class="form-control" v-model="formatTempFromTime">
            </div>
            <div style="display: flex; align-items: end">
              <button v-if="promotion.state == 'REVIEWING'" type="button" class="btn btn-warning btn-sm"
                      @click="showRejectPromotionModal(promotion.id)">審核未通過</button>
              <button type="button" class="btn btn-primary btn-sm" @click="showApprovePromotionModal(promotion.id)">開始推廣</button>
            </div>
          </div>
          <div v-else style="display: flex; align-items: end">
            <a target="_blank" :href="'https://trek.aotter.net'+ promotion.trekDraftUrl" class="btn btn-info btn-sm">前往 Trek 廣告草稿頁面</a>
            <a target="_blank" :href="'https://trek.aotter.net/advertiser/show/adset?setId='+promotion.adSetId" class="btn btn-info btn-sm">前往 Trek 正式廣告頁面(需在Trek切換為相應組織)</a>
          </div>
        </div>
      </div>
    </div>
    <div class="review">
      <div class="space-root-children bg-neutral-lightest p-3">
        <div class="suprlink-title">
          <h3 class="suprlink-title__grade-1" style="color: #1f2f5b;">基本資訊</h3>
        </div>
        <hr>
        <div class="row space-root-children">
          <div class="col-12">
            <div class="suprlink-title">
              <h3 class="suprlink-title__grade-2" style="color: #1f2f5b;">狀態與日期</h3>
            </div>
          </div>

          <div class="col-12">
            <span class="p-1 text-neutral-lightest" :class="getAdminPromotionStateClass(promotion.state)">{{getAdminPromotionStateName(promotion.state)}}</span>
          </div>

          <div class="col-6">
            <form class="form-group">
              <label class="form-group__label">建立日期</label>
              <div class="form-group__control">
                <div class="input-group">
                  <div class="input-group__main">
                    <input :value="promotion.createTime | timeString('YYYY-MM-DD')" readonly="readonly" style="padding-bottom: 0.5rem; padding-left: 1rem; height: 42px; width: 150px;">
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="col-6">
            <form class="form-group">
              <label class="form-group__label">付款日期</label>
              <div class="form-group__control">
                <div class="input-group">
                  <div class="input-group__main">
                    <input :value="promotion.paidDate | timeString('YYYY-MM-DD')" readonly="readonly" style="padding-bottom: 0.5rem; padding-left: 1rem; height: 42px; width: 150px;">
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="col-6">
            <form class="form-group">
              <label class="form-group__label">開始推廣日期</label>
              <div class="form-group__control">
                <div class="input-group">
                  <div class="input-group__main">
                    <input v-if="promotion.trekFromTime" :value="promotion.trekFromTime | timeString('YYYY-MM-DD')" readonly="readonly" style="padding-bottom: 0.5rem; padding-left: 1rem; height: 42px; width: 150px;">
                    <input v-else value="排程中" readonly="readonly" style="padding-bottom: 0.5rem; padding-left: 1rem; height: 42px; width: 150px;">
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="col-6">
            <form class="form-group">
              <label class="form-group__label">預計結束日期</label>
              <div class="form-group__control">
                <div class="input-group">
                  <div class="input-group__main">
                    <input v-if="promotion.trekToTime" :value="promotion.trekToTime | timeString('YYYY-MM-DD')" readonly="readonly" style="padding-bottom: 0.5rem; padding-left: 1rem; height: 42px; width: 150px;">
                    <input v-else value="排程中" readonly="readonly" style="padding-bottom: 0.5rem; padding-left: 1rem; height: 42px; width: 150px;">
                  </div>
                </div>
              </div>
            </form>
          </div>

        </div>
        <hr>
        <div class="suprlink-title">
          <h3 class="suprlink-title__grade-2" style="color: #1f2f5b;">目標網址</h3>
        </div>
        <form class="form-group">
          <label class="form-group__label">縮址</label>
          <div class="form-group__control">
            <div class="input-group">
              <div class="input-group__main">
                <input class="w-100" :value="promotion.suprLink" readonly="readonly" style="padding-bottom: 0.5rem; padding-left: 1rem; height: 42px;">
              </div>
            </div>
          </div>
        </form>

        <form class="form-group">
          <label class="form-group__label">預設網址</label>
          <div class="form-group__control">
            <div class="input-group">
              <div class="input-group__main">
                <input class="w-100" :value="promotion.originalLink" readonly="readonly" style="padding-bottom: 0.5rem; padding-left: 1rem; height: 42px;">
              </div>
              <a target="_blank" :href="promotion.originalLink" class="btn-transparent">
                <i class="icon-share" style="font-size: 20px"></i>
              </a>
            </div>
          </div>
        </form>

        <hr>
        <div class="row space-root-children">
          <div class="col-12">
            <div class="suprlink-title">
              <h3 class="suprlink-title__grade-2" style="color: #1f2f5b;">擁有者資料</h3>
            </div>
          </div>
          <div class="col-6">
            <form class="form-group">
              <label class="form-group__label">原始創建人</label>
              <div class="form-group__control">
                <div class="input-group">
                  <div class="input-group__main">
                    <input :value="promotion.userEmail" readonly="readonly" style="padding-bottom: 0.5rem; padding-left: 1rem; height: 42px; width: 150px;">
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="col-6">
            <form class="form-group">
              <label class="form-group__label">所屬組織</label>
              <div class="form-group__control">
                <div class="input-group">
                  <div class="input-group__main">
                    <input :value="promotion.publisher? promotion.publisher:'無'" readonly="readonly" style="padding-bottom: 0.5rem; padding-left: 1rem; height: 42px; width: 150px;">
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <hr>
        <div class="row space-root-children">
          <div class="col-12">
            <div class="suprlink-title">
              <h3 class="suprlink-title__grade-2" style="color: #1f2f5b;">統計資料</h3>
            </div>
          </div>
          <div class="col-6">
            <form class="form-group">
              <label class="form-group__label">已消耗/總預算</label>
              <div class="form-group__control">
                <div class="input-group">
                  <div class="chart-progress pt-3">
                    <div class="progress-wrapper">
                      <progress max="100" class="progress is-small is-success" :value="getPercentage(promotion.budget, promotion.consumedBudget)">
                        {{ getPercentage(promotion.budget, promotion.consumedBudget) }}</progress>
                    </div>
                    <p class="chart-progress__text">{{promotion.consumedBudget ? promotion.consumedBudget : '無資料'}} / ${{promotion.budget}}</p>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="col-6">
            <form class="form-group">
              <label class="form-group__label">有效/總點擊數</label>
              <div class="form-group__control">
                <div class="input-group">
                  <div class="chart-progress pt-3">
                    <div class="progress-wrapper">
                      <progress max="100" class="progress is-small is-success" :value="getPercentage(promotion.click, promotion.rclick)">{{getPercentage(promotion.click, promotion.rclick)}}</progress>
                    </div>
                    <p class="chart-progress__text">{{promotion.rclick ? promotion.rclick : '無資料'}} / {{promotion.click ? promotion.click : '無資料'}}</p>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="col-12" v-if="!isReviewing">
            <form class="form-group">
              <label class="form-group__label">數據頁</label>
              <div class="form-group__control">
                <div class="input-group">
                  <div class="input-group__main">
                    <input class="w-100" :value="promotion.reportLink" readonly="readonly" style="padding-bottom: 0.5rem; padding-left: 1rem; height: 42px;">
                  </div>
                  <a target="_blank" :href="promotion.reportLink" class="btn-transparent">
                    <i class="icon-share" style="font-size: 20px"></i>
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>

        <hr>
        <div class="suprlink-title">
          <h3 class="suprlink-title__grade-2" style="color: #1f2f5b;">相關 ID</h3>
          <small class="suprlink-title__desc">
            <i class="icon-alert-circle"></i>
            Trek 廣告 ID 是 Supr.Link 先產生後再發送 Trek，如果上刊失敗，有可能是相關ID與現有資料庫有重複，請點選下方「重新產生」後再重新上刊
          </small>
        </div>
        <form class="form-group">
          <label class="form-group__label">推廣 ID</label>
          <div class="form-group__control">
            <div class="input-group">
              <div class="input-group__main">
                <input class="w-100" :value="promotion.id" readonly="readonly" style="padding-bottom: 0.5rem; padding-left: 1rem; height: 42px;">
              </div>
            </div>
          </div>
        </form>
        <form class="form-group">
          <label class="form-group__label">Trek Campaign ID</label>
          <div class="form-group__control">
            <div class="input-group">
              <div class="input-group__main">
                <input class="w-100" :value="promotion.campId" readonly="readonly" style="padding-bottom: 0.5rem; padding-left: 1rem; height: 42px;">
              </div>
              <i class="icon-shuffle pointIcon" data-toggle="tooltip" data-placement="top" title="重新產生"
                 @click="isReviewing ? showUpdatePromotionConfirmModal('info','campId') :''"></i>
            </div>
          </div>
        </form>
        <form class="form-group">
          <label class="form-group__label">Trek AdSet ID</label>
          <div class="form-group__control">
            <div class="input-group">
              <div class="input-group__main">
                <input class="w-100" :value="promotion.adSetId" readonly="readonly" style="padding-bottom: 0.5rem; padding-left: 1rem; height: 42px;">
              </div>
              <i class="icon-shuffle pointIcon" data-toggle="tooltip" data-placement="top" title="重新產生"
                 @click="isReviewing ? showUpdatePromotionConfirmModal('info','adSetId') :''"></i>
            </div>
          </div>
        </form>
        <form class="form-group">
          <label class="form-group__label">Trek AdUnit ID</label>
          <div class="form-group__control">
            <div class="input-group">
              <div class="input-group__main">
                <input class="w-100" :value="promotion.adUnitId" readonly="readonly" style="padding-bottom: 0.5rem; padding-left: 1rem; height: 42px;">
              </div>
              <i class="icon-shuffle pointIcon" data-toggle="tooltip" data-placement="top" title="重新產生"
                 @click="isReviewing ? showUpdatePromotionConfirmModal('info','adUnitId') :''"></i>
            </div>
          </div>
        </form>
      </div>

      <div>
        <div class="space-root-children bg-neutral-lightest p-3">
          <div class="flex justify-between">
            <div class="suprlink-title">
              <h3 class="suprlink-title__grade-1" style="color: #1f2f5b;">推廣內容</h3>
            </div>
            <div class="switcher">
              <label for="editMode" class="switcher__label">
                <input id="editMode" type="checkbox" v-model="isEdit" :disabled="!isReviewing">
                <div class="switcher__slider"></div>
              </label>
            </div>
          </div>
          <hr>

          <div class="form-group">
            <label class="form-group__label">推廣圖片</label>
            <div class="vue-component-cropper">
              <file-uploader
                  ref="imgMainUploader"
                  class="w-100"
                  v-model="promotion.imgMain"
                  :spec="{
                      title: '推廣圖片',
                      isCrop: true,
                      autoCropWidth: 1200,
                      autoCropHeight: 628,
                      fixedNumber: [1200, 628],
                      frameWidth: 80
                  }"
                  :is-preview="!isEdit"/>

              <template v-if="isEdit">
                <button class="btn-submit-sm mt-2" @click="$refs.imgMainUploader.showUploadDialog">選擇圖片</button>
              </template>
            </div>
          </div>
          <div class="form-group">
            <label class="form-group__label">推廣圖片(正方形)</label>
            <div class="vue-component-cropper">
              <file-uploader
                  ref="imgIconHdUploader"
                  class="w-100"
                  v-model="promotion.imgIconHd"
                  :spec="{
                      title: '推廣圖片（正方形）',
                      isCrop: true,
                      autoCropWidth: 300,
                      autoCropHeight: 300,
                      fixedNumber: [300, 300],
                      frameWidth: 45
                  }"
                  :is-preview="!isEdit"/>
              <template v-if="isEdit">
                <button class="btn-submit-sm mt-2" @click="$refs.imgIconHdUploader.showUploadDialog">選擇圖片</button>
              </template>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label>
                <small class="m-1 text-red">*</small>推廣標題
                <small class="ml-1 text-gray">3 - 25字</small>
              </label>
              <input type="text" class="form-control" name="推廣標題" placeholder="請輸入推廣標題" v-model="promotion.name" :disabled="!isEdit">
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label>
                <small class="m-1 text-red">*</small>推廣內容
                <small class="ml-1 text-gray">3 - 35字</small>
              </label>
              <textarea type="text" class="form-control" name="推廣內容" placeholder="請輸入推廣內容" v-model="promotion.text" :disabled="!isEdit">
              </textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <form class="form-group">
                <label class="form-group__label">推廣預算</label>
                <div class="form-group__control">
                  <div class="input-group">
                    <div class="input-group__main">
                      <input v-model="promotion.budget" style="padding-bottom: 0.5rem; padding-left: 1rem; height: 42px; width: 130px" :disabled="!isEdit">
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-6">
              <form class="form-group">
                <label class="form-group__label">推廣走期</label>
                <div class="form-group__control">
                  <div class="input-group">
                    <div class="input-group__main">
                      <input v-model="promotion.day" style="padding-bottom: 0.5rem; padding-left: 1rem; height: 42px; width: 130px" :disabled="!isEdit">
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <button class="btn-submit-block" :disabled="!isEdit" @click="showUpdatePromotionConfirmModal('content')">
            {{ isReviewing ? '更新推廣資料' : '推廣已開始，無法更新' }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import ModalList from "@/const/modal";
import moment from 'moment'
import FileUploader from "@/components/common/FileUploader";
import { mapGetters, mapMutations } from 'vuex'
import { getPromotionReviewing } from "@/service/api/internal/admin/promotion";

export default {
  name: "PromotionReview",
  components: {
    FileUploader
  },
  data(){
    return{
      isEdit: false,
      formatTempFromTime:'',
      promotionId:'',
      promotion:{}
    }
  },
  created() {
    if(this.$route.query.id){
      this.promotionId = this.$route.query.id
      this.getPromotionReview()
    }else {
      this.$router.push('/admin/all-promotion-list')
    }
  },
  computed: {
    ...mapGetters('adminStore', ['getAdminPromotionStateName', 'getAdminPromotionStateClass']),
    isReviewing(){
      let result = true
      switch (this.promotion.state) {
        case 'PENDING':
        case 'PROGRESS':
        case 'COMPLETED':
          result = false;
      }
      return result
    }
  },
  mounted() {
    $('[data-toggle="tooltip"]').tooltip()
  },
  methods: {
    ...mapMutations(['showModal']),
    async getPromotionReview(){
      await getPromotionReviewing(this.promotionId)
          .then(result =>{
            this.isEdit = false
            this.promotion = result
            this.formatTempFromTime = this.getFormatTempFromTime(this.promotion.tempFromTime)
          })
    },
    getFormatTempFromTime(){
      let date = new Date()
        if(this.promotion.tempFromTime){
          date = new Date(this.promotion.tempFromTime)
        }
      return moment(date).format('YYYY-MM-DD[T]HH:mm')
    },
    showUpdatePromotionConfirmModal(type,field){
      let updateData = {}
      switch (type) {
        case 'content':
          updateData.id = this.promotion.id
          updateData.imgMain = this.promotion.imgMain
          updateData.imgIconHd = this.promotion.imgIconHd
          updateData.text = this.promotion.text
          updateData.name = this.promotion.name
          updateData.budget = this.promotion.budget
          updateData.day = this.promotion.day
          break;
        case 'info':
          updateData.id = this.promotion.id
          updateData.field = field
      }

      this.showModal({
        modalId: ModalList.PROMOTION_UPDATE_CONFIRM_MODAL,
        payload: {
          type: type,
          updateData: updateData,
          execFunc: this.getPromotionReview
        }})
    },
    showRejectPromotionModal(id){
      this.showModal({
        modalId: ModalList.PROMOTION_REJECT_MODAL,
        payload: {
          id: id,
          title: this.promotion.name,
          execFunc: this.getPromotionReview
        }})
    },
    showApprovePromotionModal(id){
      let newDate= new Date(Date.parse(this.formatTempFromTime))
      let content = newDate.toLocaleDateString() +'  '+ newDate.toLocaleTimeString()

      this.showModal({
        modalId: ModalList.PROMOTION_APPROVE_MODAL,
        payload: {
          id: id,
          content: content,
          promotion: this.promotion,
          execFunc: this.getPromotionReview
        }})
    },
    getPercentage(budget, consumedBudget){
      return this.round(consumedBudget/budget)
    },
    round(num) {
      let m = Number((Math.abs(num) * 100).toPrecision(15));
      return Math.round(m) * Math.sign(num);
    }
  }
}
</script>

<style scoped>

* {
  font-size: 1rem;
}

.bg-vip {
  background-color: #f5b400;
}

.bg-danger {
  background-color: #d62929;
}

.review {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.form-group {
  width: 100%;
  margin-bottom: 0.5rem;
}

.form-group__control {
  display: flex;
}

.input-group {
  position: relative;
  flex-grow: 1;
  display: flex;
  border-radius: 3px;
}

input:read-only {
  background: transparent;
  box-shadow: none;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #6b6b6b;
  padding-left: 0;
  color: #6b6b6b;
  font-size: calc(1rem * 1.125);
}

.text-neutral-lightest {
  color: #fff;
}

.suprlink-title__grade-2 {
  font-size: 1.5rem;
  color: #1f2f5b;
  font-weight: 700;
}

.modal__content--full>:not(:last-child), .modal__content>:not(:last-child), .space-root-children>:not(:last-child) {
  margin-bottom: calc(1rem * 1);
}

.form-group__label:not(:last-child) {
  margin-bottom: calc(1rem * 0.5);
}

.form-group__label {
  display: flex;
  align-items: center;
}

.form-group__label {
  font-size: calc(1rem * 1.125);
  color: #1f2f5b;
  font-weight: 700;
}

select[readonly] {
  background: transparent;
  box-shadow: none;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #6b6b6b;
  padding-left: 0;
  color: #6b6b6b;
  font-size: calc(1rem * 1.125);
  pointer-events: none;
}

.input-group__main {
  flex-grow: 1;
  position: relative;
}

.input-group>* {
  border-radius: 0;
}

.input-group>:last-child:not([readonly]) {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.btn-transparent {
  color: #768aaf;
  background: transparent;
  border: 1px solid transparent;
}

select {
  cursor: pointer;
  -webkit-appearance: none;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='var(--color-neutral-darker)' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center/8px 10px;
  background-color: #fff;
  padding-right: 2rem;
}

.svg-inline--fa {
   display: inline-block;
   font-size: inherit;
   height: 1em;
   overflow: visible;
   vertical-align: -0.125em;
}

.svg-inline--fa.fa-w-16 {
  width: 1em;
}

.switcher {
  display: flex;
  align-items: center;
}

.switcher__label {
  display: inline-block;
  height: 34px;
  position: relative;
  width: 60px;
  margin: 0;
}

.switcher input {
  display: none;
}

input[type=checkbox] {
  position: relative;
  width: 1.4rem;
  height: 1.4rem;
  color: #000;
  border: 1px solid #e0e4eb;
  box-shadow: 0 0 4.5px .5px rgba(0,0,0,.1);
  background: #fff;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  cursor: pointer;
  transition: background 175ms cubic-bezier(.1,.1,.25,1);
}

.switcher__slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
  border-radius: 34px;
}

.switcher__slider:before {
  content: "";
  border-radius: 50%;
  background-color: #fff;
  bottom: 4px;
  height: 26px;
  left: 4px;
  position: absolute;
  transition: .4s;
  width: 26px;
}

.suprlink-title__grade-1 {
  font-size: 2rem;
  color: #1f2f5b;
  font-weight: 700;
}

.justify-between {
  justify-content: space-between;
}

.switcher input:checked+.switcher__slider:before {
  transform: translateX(26px);
}

.switcher input:checked+.switcher__slider {
  background-color: #00c79c;
}

.btn-submit-sm {
  color: #fff;
  background: #3e5787;
  border: 1px solid #3e5787;
  padding: 0.25rem 0.25rem !important;
}

.btn-cancel-sm {
  color: #fff;
  background: #aaa;
  border: 1px solid #aaa;
  padding: 0.25rem 0.25rem !important;
}

.btn-submit-block {
  color: #fff;
  background: #3e5787;
  border: 1px solid #3e5787;
  display: block;
  width: 100%;
}

input:disabled, select:disabled, textarea:disabled {
  cursor: not-allowed;
  background: #d1d1d1;
  opacity: .7;
}

input, select, textarea {
  display: block;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  color: #1f2f5b;
  border: 1px solid #e0e4eb;
  border-radius: 3px;
  box-shadow: 0 0 4.5px .5px rgba(0,0,0,.1);
}

.btn-cancel-sm, .btn-primary, .btn-submit-block, .btn-submit-sm,.btn-transparent, .btn-warning {
  display: inline-block;
  text-align: center;
  padding: 0.5rem 0.75rem;
  border-radius: 3px;
  transition: background-color .15s;
  white-space: nowrap;
  cursor: pointer;
}

.input-group>:first-child:not([readonly]) {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.input-group>:last-child:not([readonly]) {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.hidden {
  display: none;
}

.chart-progress {
  width: 100%;
}

.chart-progress .progress-wrapper {
  margin-bottom: 0;
}

.progress-wrapper:not(:last-child) {
  margin-bottom: 1.5rem;
}

.progress-wrapper {
  position: relative;
  overflow: hidden;
}

.chart-progress__text {
  text-align: right;
  font-size: 0.75rem;
}

.progress-wrapper .progress {
  margin-bottom: 0;
}

.progress.is-small {
  height: .75rem;
}

.progress {
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  border-radius: 290486px;
  display: block;
  height: 1rem;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

progress {
  vertical-align: baseline;
}

canvas, embed, iframe, img, object, svg, video {
  display: block;
  vertical-align: middle;
}

*, :after, :before {
  box-sizing: border-box;
  border: 0 solid;
}

.btn-cancel-block:disabled, .btn-cancel-sm:disabled, .btn-cancel:disabled, .btn-danger-block:disabled, .btn-danger-sm:disabled, .btn-danger:disabled, .btn-important-block:disabled, .btn-important-sm:disabled, .btn-important:disabled, .btn-outline-block:disabled, .btn-outline-sm:disabled, .btn-outline:disabled, .btn-primary-block:disabled, .btn-primary-sm:disabled, .btn-primary:disabled, .btn-role-block:disabled, .btn-role-sm:disabled, .btn-role:disabled, .btn-submit-block:disabled, .btn-submit-sm:disabled, .btn-submit:disabled, .btn-success-block:disabled, .btn-success-sm:disabled, .btn-success:disabled, .btn-transparent-block:disabled, .btn-transparent-sm:disabled, .btn-transparent:disabled, .btn-warning-block:disabled, .btn-warning-sm:disabled, .btn-warning:disabled, .form-group__submit:disabled {
  opacity: .65;
  cursor: not-allowed;
}

.modal__content--full>:not(:last-child), .modal__content>:not(:last-child), .space-root-children>:not(:last-child) {
  margin-bottom: 1rem;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0,0,0,.1);
}

hr {
  border: none;
  display: block;
  height: 2px;
  margin: 1.5rem 0;
}

code, hr {
  background-color: #f5f5f5;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

.flex {
  display: flex;
}

button, input, select, textarea {
  padding: 0;
  line-height: inherit;
  color: inherit;
}

.is-success{
  background-color:#23d160;
  border-color:transparent;
  color:#fff
}

.pointIcon{
  margin: auto;
  cursor: pointer;
  color: #768aaf
}


</style>
