<template>
  <div>
    <section id="page-title" class="text-light background-theme" style="padding:40px 0px;"><!-- 這個背景未來要能上稿Cover -->
      <div class="container">
        <div class="page-title">
          <h1>新聞稿自助吧</h1>
          <p>新聞稿的好去處，三分鐘上稿完成，最快接觸最多讀者的方案！</p>
          <template v-if="isLogin">
            <router-link to="/me/post-edit" class="btn btn-light btn-sm btn-shadow">立即刊登</router-link>
          </template>
          <template v-else>
            <a @click="toLoginPage('homepage')" class="btn btn-light btn-sm btn-shadow">立即註冊</a>
          </template>
          <a href="/help-guide" class="btn btn-light btn-sm btn-shadow ml-2">立即了解</a>
        </div>
      </div>
    </section>

    <section id="page-content" class="sidebar-right">
      <div class="container">
        <div class="row">
          <!-- post content -->
          <div class="content col-lg-9">
            <!--Post Carousel -->
            <pin-posts v-if="type == 'latest'"/>
            <div class="line"></div>
            <post-list :key="postListReloadTrigger" :type="type"/>
          </div>
          <!-- end: post content -->
          <!-- Sidebar-->
          <div class="sidebar sticky-sidebar col-lg-3">
            <button type="button" @click="publish"
                    class="btn btn-primary btn-creative btn-icon-holder btn-shadow btn-light-hover w-100-sm">
              按我刊登新聞稿<i class="icon-edit"></i>
            </button>
            <hr>
            <!-- 廣告版位 -->
            <div class="widget">
              <div class="adset-rightsidebar">
                <div :id="selectorId" :data-place="uuid"></div>
              </div>
            </div>
            <!-- 廣告版位 -->
            <!--Tabs with Posts-->
            <side-bar-category-list/>
            <hot-tags/>
            <!--widget newsletter-->
            <div class="widget widget-newsletter">
              <h4 class="widget-title">想自動接收新聞稿？</h4>
              <a class="btn btn-block" data-target="#modal" data-toggle="modal" @click="subscribeViaRss">立即訂閱</a>
            </div>
            <!--end: widget newsletter-->
          </div>
          <!-- end: Sidebar-->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PinPosts from '@/components/public/PinPosts.vue'
import PostList from '@/components/public/PostList.vue'
import SideBarCategoryList from '@/components/public/SideBarCategoryList.vue'
import HotTags from '@/components/public/HotTags.vue'
import Const from '@/const'
import ModalList from "@/const/modal";
import { mapMutations } from "vuex";

export default {
  name: "PublicPostList",
  metaInfo(){
    return {
      meta: this.$store.state.postListMetaInfo
    }
  },
  components: {
    PinPosts,
    PostList,
    SideBarCategoryList,
    HotTags
  },
  data(){
    return {
      postListReloadTrigger: 0,
      isLogin: false,
      loginUrl: ''
    }
  },
  mounted() {
    this.loginCheck()
    if(Const.PROD_SERVER_URL_BASE.includes(window.location.origin)) {
      let selector = `#${this.selectorId}`;
      let invokeScript = document.createElement("script");
      invokeScript.innerHTML = `
              AotterTrek('suprAd', {
                  selector: '${selector}',
                  place: '${this.uuid}',
                  onAdLoad: () => {
                      console.log("${this.selectorId} success")
                  },
                  onAdFail: () => {
                      console.log("${this.selectorId} fail")
                  }
              })`

      document.body.appendChild(invokeScript);
    }
  },
  watch: {
    '$route.path'(){
      if([Const.TYPE_CATEGORY,Const.TYPE_SUBCATEGORY,Const.TYPE_TAG].includes(this.type)){
        this.postListReloadTrigger++
      }
    },
    '$route.query.q'(){
      if([Const.TYPE_TAG].includes(this.type)){
        this.postListReloadTrigger++
      }
    },
  },
  computed: {
    type(){
      let pathType = this.$route.meta['type']
      let type = ""

      switch(pathType){
        case Const.TYPE_LATEST:
          type = Const.TYPE_LATEST
          break
        case Const.TYPE_TAG:
          type = Const.TYPE_TAG
          break
        case Const.TYPE_SEARCH:
          type = Const.TYPE_SEARCH
          break
        case Const.TYPE_CATEGORY: {
          let hasValidParam = this.$route.params.main
          type = hasValidParam ? Const.TYPE_CATEGORY : Const.TYPE_LATEST
          break
        }
        case Const.TYPE_SUBCATEGORY: {
          let hasValidParam = this.$route.params.main && this.$route.params.sub
          type = hasValidParam ? Const.TYPE_SUBCATEGORY : Const.TYPE_LATEST
          break
        }
        case Const.TYPE_PUBLISHER: {
          type = Const.TYPE_PUBLISHER
          break
        }
        default:
          type = Const.TYPE_LATEST
      }
      return type
    },
    uuid(){
      if(this.$isMobile()){
        return Const.HOMEPAGE_SIDEBAR_MOBILE_AD_UUID
      }else {
        return Const.HOMEPAGE_SIDEBAR_DESKTOP_AD_UUID
      }
    },
    selectorId(){
      if(this.$isMobile()){
        return Const.HOMEPAGE_SIDEBAR_MOBILE_AD
      }else {
        return Const.HOMEPAGE_SIDEBAR_DESKTOP_AD
      }
    }
  },
  methods:{
    ...mapMutations(['showModal']),
    subscribeViaRss(){
      let rssLink = window.location.origin + '/feed'
      const input = document.createElement('input')
       document.body.appendChild(input)
       input.value = rssLink
       input.select()
       document.execCommand('copy')
       document.body.removeChild(input)
       this.showModal({
         modalId: ModalList.CHECK_MODAL,
         payload: {
           title: '成功',
           content: '已複製RSS連結',
           url: rssLink
         }
       })
    },
    publish(){
      if(this.isLogin){
        this.$router.push('/me/post-edit')
      }else {
        this.toLoginPage('postEdit')
      }
    },
    toLoginPage(redirect){
      document.cookie = 'quarkus-redirect-location=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      document.cookie = `quarkus-redirect-location=${redirect};`
      document.location = this.loginUrl
    },
    loginCheck(){
      fetch(`/login/check`)
          .then(res => {
            return res.json()
          }).then(result => {
        if(result.userId){
          this.isLogin = true
        }else {
          this.getLoginUrl()
        }
      })
    },
    getLoginUrl(){
      fetch(`/login/url`)
          .then(res => {
            return res.json()
          }).then(result => {
        if(result.loginUrl){
          this.loginUrl = result.loginUrl
        }
      })
    }
  }
}
</script>


<style scoped>
@media only screen and (max-width: 576px) {
  .w-100-sm {
    min-width: 100% !important;
  }
}
</style>
