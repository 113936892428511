<template>
  <div :ref="modalId" class="modal fade" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header pb-0 text-bold">
          <div style="font-size: 1.3rem;" class="mb-3">使用者</div>
        </div>

        <div class="modal-body text-bold m-2">
          <div class="mb-2">
            <div class="text-primary">使用者編號</div>
            <div>{{ userData.id }}</div>
          </div>
          <div class="mb-2">
            <div class="text-primary">電子信箱</div>
            <div>{{ userData.email }}</div>
          </div>
          <div class="mb-2">
            <div class="text-primary">角色</div>
            <div>{{ getRoleNames(userData.role) }}</div>
          </div>
          <div class="mb-2 d-flex flex-column">
            <div class="text-primary font-weight-bold">公開資訊</div>
            <div>{{ userData.meta.publisher }}</div>
            <div class="d-inline-flex mt-1">
              <div><i class="icon-user"></i></div>
              <div class="ml-3">{{ userData.meta.contactName }}</div>
            </div>
            <div class="d-inline-flex mt-1">
              <div><i class="icon-phone"></i></div>
              <div class="ml-3">{{ userData.meta.contactPhone }}</div>
            </div>
            <div class="d-inline-flex mt-1">
              <div><i class="icon-mail"></i></div>
              <div class="ml-3">{{ userData.meta.contactEmail }}</div>
            </div>
            <div class="mt-1">{{ userData.meta.description }}</div>
          </div>
        </div>
        <div class="modal-footer border-0">
          <button type="button" class="btn btn-secondary" @click="hide">確定</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import ModalList from "@/const/modal";
import Const from '@/const'
import { findUser } from "@/service/api/internal/admin/user";

export default {
  name: "UserPreviewModal",
  data(){
    return {
      modalId: ModalList.USER_PREVIEW_MODAL,
      userData: {
        meta: {}
      }
    }
  },
  methods: {
    async show(data){
      if(data.userId){
        await findUser(data.userId)
          .then(data => {
            if(data){
              this.userData = data
              $(this.$refs[this.modalId]).modal('show')
            }
          })
      }
    },
    hide(){
      $(this.$refs[this.modalId]).modal('hide')
    },
    getRoleNames(roleKeyArr){
      return (roleKeyArr ? roleKeyArr : []).map(this.getRoleName).join(",")
    },
    getRoleName(roleKey){
      let result = ''

      switch(roleKey){
        case Const.ROLE_ADMIN:
          result = '管理者'
          break
        case Const.ROLE_NORMAL:
          result = '使用者'
          break
        default:
          result = '使用者'
      }
      return result
    }
  }
}
</script>

<style scoped>

</style>
