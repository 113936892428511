export default {
    TYPE_LATEST: "latest",
    TYPE_CATEGORY: "category",
    TYPE_SUBCATEGORY: "subCategory",
    TYPE_TAG: "tag",
    TYPE_SEARCH: "search",
    TYPE_PUBLISHER: "publisher",

    TYPE_PUBLISH: "publish",
    TYPE_TOU: "tou",
    TYPE_PRIVACY: "privacy",

    TYPE_INFO: "info",
    TYPE_POST_LIST: "post-list",
    TYPE_POST_EDIT: "post-edit",
    TYPE_PROMOTION_LIST: "promotion-list",
    TYPE_TRANSACTION_LIST: "transaction-list",
    TYPE_USER_LIST: "user-list",
    TYPE_RSS_LIST: "rss-list",
    TYPE_PIN_POST: "pin-post",
    TYPE_SELECTED_POST: "selected-post",
    TYPE_SPECIAL_SELECTED_POST: "special-selected-post",
    TYPE_HOT_TAG: "hot-tag",
    TYPE_ALL_POST_LIST: "all-post-list",
    TYPE_ALL_PROMOTION_LIST: "all-promotion-list",
    TYPE_SITE_CONFIG: "site-config",

    PUBLIC_POST_PATH: "/api/public/post",

    PROD_SERVER_URL_BASE: ["https://nb.aotter.net","https://nb-next.aotter.net"],

    MANAGEMENT_LABEL: "management",
    USER_LABEL: "me",

    HOMEPAGE_SIDEBAR_DESKTOP_AD: "homepageSidebarDesktopAD",
    HOMEPAGE_SIDEBAR_MOBILE_AD: "homepageSidebarMobileAD",
    POST_SIDEBAR_DESKTOP_AD: "postSidebarDesktopAD",
    POST_SIDEBAR_MOBILE_AD: "postSidebarMobileAD",
    ADMIN_SIDEBAR_RIGHT_DESKTOP_AD: "adminSidebarRightDesktopAD",
    ADMIN_SIDEBAR_RIGHT_MOBILE_AD: "adminSidebarRightMobileAD",
    ADMIN_SIDEBAR_LEFT_DESKTOP_AD: "adminSidebarLeftDesktopAD",
    ADMIN_SIDEBAR_LEFT_MOBILE_AD: "adminSidebarLeftMobileAD",

    HOMEPAGE_SIDEBAR_DESKTOP_AD_UUID: "35811a64-aab8-4d7e-9e4a-853a25feac67",
    HOMEPAGE_SIDEBAR_MOBILE_AD_UUID: "966e1bdc-17f1-4b8c-8b4f-55dee8f40f98",
    POST_SIDEBAR_DESKTOP_AD_UUID: "34b936c1-11a1-4500-bfaa-f1d95bff4212",
    POST_SIDEBAR_MOBILE_AD_UUID: "1811c5d7-74cc-4d12-867b-2be1bbe14462",
    ADMIN_SIDEBAR_RIGHT_DESKTOP_AD_UUID: "d4bd1eed-8898-4fb8-a33f-2fcedc5760e7",
    ADMIN_SIDEBAR_RIGHT_MOBILE_AD_UUID: "256096eb-3c23-48b5-920b-5d0ac204e757",
    ADMIN_SIDEBAR_LEFT_DESKTOP_AD_UUID: "dd704511-1b9b-4ca7-a26f-eebc5883c44e",
    ADMIN_SIDEBAR_LEFT_MOBILE_AD_UUID: "9cccbc2d-1a9c-47a9-88da-e698efda4f81",

    ROLE_ADMIN: "ADMIN",
    ROLE_NORMAL: "NORMAL",

    USER_STATE_DEACTIVATED: "DEACTIVATED",
    USER_STATE_ACTIVE: "ACTIVE",

    ACTIVE: "ACTIVE",
    ARCHIVED: "ARCHIVED",

}
