import axios from "@/plugins/axios";

// user 相關的 api
let baseUrl = '/'

if(process.env.VUE_APP_baseUrl) {
    baseUrl = process.env.VUE_APP_baseUrl
}

baseUrl += 'api/internal/me'

// get user info
export function getUser() {
    return axios.get(baseUrl).then(res=>res.data)
}

// get user's public contact information
export function getMeta() {
    return axios.get(`${baseUrl}/meta`).then(res=>res)
}

// update user's public contact information
export function updateMeta(metaUpdateDTO) {
    return axios.put(`${baseUrl}/meta`, metaUpdateDTO, { headers: {'Content-Type': 'application/json'} })
}
