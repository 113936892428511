<template>
  <div style="text-align:left">
    <!--Post Carousel -->
    <template v-if="pinPostList.length > 2">
      <h4 class="mb-4">精選新聞稿</h4>
      <div class="carousel" data-items="3" data-lightbox="gallery">
        <template v-for="pinPost in pinPostList">
          <!-- portfolio item -->
          <div class="portfolio-item img-zoom ct-photography ct-marketing ct-media" :key="pinPost.post.id">
            <div class="portfolio-item-wrap">
              <div class="portfolio-image">
                <router-link :to="'/post/'+pinPost.post.id"><img height="628" width="1200" :src="pinPost.post.cover" alt=""></router-link>
              </div>
              <div class="portfolio-description">
                <router-link :to="'/post/'+pinPost.post.id">
                  <h3>{{getFixedLengthTitle(pinPost.post.title)}}</h3>
                  <router-link :to="pinPost.category?  `/category/${pinPost.category[0].category}` : '/category/News'" class="badge badge-primary text-white">
                    {{pinPost.category ? pinPost.category[0].categoryZh : '新聞'}}</router-link>
                  <p class="small">{{ pinPost.post.publishedTime | timeString('YYYY/MM/DD') }}</p>
                </router-link>
              </div>
            </div>
          </div>
          <!-- end: portfolio item -->
        </template>
      </div>
      <div class="line"></div>
    </template>
    <!--end: Post Carousel -->
  </div>
</template>

<script>
import $ from "jquery";
import { findPinPosts } from '@/service/api/public';

export default {
  name: "PinPosts",
  data(){
    return{
      pinPostList: []
    }
  },
  mounted() {
    this.findPinPostList()
  },
  methods: {
    async findPinPostList() {
      await findPinPosts().then(pinPosts => {
        this.pinPostList = pinPosts ? pinPosts : []
        this.$nextTick(() => {
          window.INSPIRO.slider.carousel($('.carousel'))
        })
      })
    },
    getFixedLengthTitle(title){
      let result = title

      if(typeof title == "string" && title.length > 23){
        result = `${title.substring(0,23)}...`
      }
      return result
    }
  }
}
</script>

<style scoped>

</style>
