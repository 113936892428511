<template>
  <div :ref=modalId class="modal fade" role="dialog" aria-labelledby="imageModal" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="imageModal">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <div class="mb-2 text-center text-secondary">請利用拖曳、縮放、旋轉功能調整圖片</div>
          <vue-croppie
              ref="croppie"
              :key="reloadTrigger"
              class="center-block"
              :viewport="viewport"
              :boundary="boundary"
              :enforceBoundary="true"
              :enableResize="false"
              :enableOrientation="true"
              :croppieInitialized="initCroppie">
          </vue-croppie>
          <div class="d-flex justify-content-center" style="font-size: medium;">
            <button type="button" class="btn btn-light" @click="rotate(-90)">
              <i class="icon-corner-up-left"></i>
            </button>
            <button type="button" class="btn btn-light" @click="rotate(90)">
              <i class="icon-corner-up-right"></i>
            </button>
          </div>
        </div>
        <div class="modal-footer border-0 center" >
          <button type="button" class="btn btn-secondary" @click="hide">取消</button>
          <button type="button" class="btn btn-primary" @click="save">確定</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery"
import ModalList from "@/const/modal"
import { mapMutations } from "vuex"

export default {
  name: "CroppieModal",
  data(){
    return {
      modalId: ModalList.CROPPIE_MODAL,
      modal: null,
      reloadTrigger: 0,
      data: {
        url: null,
        title: null,
        ratio: null,
        width: null,
        height: null,
        targetId: null,
        prevModalId: null
      },
      returnData: {},
      cropped: null,
      showPrevModalWithData: false,
      isSave: false
    }
  },
  mounted() {
    this.modal = $(this.$refs[this.modalId])
    this.modal.on('hidden.bs.modal', () => {
      if(this.data.prevModalId){
        if(this.showPrevModalWithData){
          this.showModal({
            modalId: this.data.prevModalId,
            payload: {
              showPrevData: true,
              ...this.returnData
            }
          })
        } else {
          this.showModal({
            modalId: this.data.prevModalId,
            payload: {
              showPrevData: true
            }
          })
        }
      }

      if(this.data.targetId && this.isSave){
        this.setUploaderData({
          id: this.data.targetId,
          data: this.returnData
        })
      }
    })

    this.modal.on('shown.bs.modal', () => {
      this.bind()
    })
  },
  methods: {
    ...mapMutations(['showModal','setUploaderData']),
    initCroppie(){
      if(this.data.url != null){
        this.modal.modal('show')
      }
    },
    show(data){
      this.data.url = data.url ? data.url : null
      this.data.title = data.title ? data.title : null
      this.data.ratio = data.ratio ? data.ratio : null
      this.data.width = data.width ? data.width : null
      this.data.height = data.height ? data.height : null
      this.data.targetId = data.id ? data.id : null
      this.data.prevModalId = data.from ? data.from : null

      this.returnData = {}
      this.isSave = false

      if(this.data.url != null){
        this.reloadTrigger++
      }
    },
    hide(){
      this.showPrevModalWithData = false
      this.modal.modal('hide')
    },
    save(){
      this.showPrevModalWithData = true
      this.isSave = true

      this.crop().then(result => {
        this.returnData = result
        this.modal.modal('hide')
      })
    },
    async crop() {
      let options = {
        type: 'blob',
        size: { width: this.width, height: this.height },
        quality: 0.9,
        format: 'jpeg'
      }

      let result = this.$refs['croppie'].result(options)
      let compressTimes = 0

      result = await this.compress(compressTimes, result)

      return {
        file: new File([result], "image.jpeg"),
        url: URL.createObjectURL(result)
      }
    },
    async compress(compressTimes, blob) {
      const fileSize = blob.size
      if (compressTimes < 3 && fileSize > 1024 * 150) {
        compressTimes++
        const result = await this.$refs['croppie'].result({
          type: 'blob',
          size: { width: this.width, height: this.height },
          quality: Math.pow(0.95, compressTimes),
          format: 'jpeg'
        })
        return await this.compress(compressTimes, result)
      } else {
        return blob
      }
    },
    bind(){
      if(this.data.url){
        this.$refs['croppie'].bind({ url: this.data.url})
      }
    },
    rotate(angle){
      this.$refs['croppie'].rotate(angle)
    }
  },
  computed: {
    title(){
      return this.data.title ? this.data.title : "上傳推廣圖片"
    },
    ratio(){
      return typeof this.data.ratio == 'number' ? this.data.ratio : 1
    },
    width(){
      return this.data.width ? this.data.width : 300
    },
    height(){
      return this.data.height ? this.data.height : 300
    },
    minZoom(){
      return typeof this.data.ratio == 'number' ? this.data.ratio : 0.1
    },
    viewport(){
      return {
        width: this.width * this.ratio,
        height: this.height * this.ratio
      }
    },
    boundary(){
      return {
        width: (this.width * this.ratio + 40),
        height: (this.height * this.ratio + 40)
      }
    }
  }
}
</script>

<style scoped>

</style>
