import axios from "@/plugins/axios";

// media 相關的 api
let baseUrl = '/'
if(process.env.VUE_APP_baseUrl) {
    baseUrl = process.env.VUE_APP_baseUrl
}

baseUrl += 'api/internal/me/media'

//  upload post relative image
export function uploadPostRelativeImage(field, formData){
    return axios.post(`${baseUrl}/post/${field}`, formData, { headers: {'Content-Type': 'multipart/form-data'} }).then(res=>res.data)
}

// ckeditor upload
export function ckupload(formData){
    return axios.post(`${baseUrl}/ckupload`, formData, { headers: {'Content-Type': 'multipart/form-data'} }).then(res=>res.data)
}
