<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-9">
          <span class="h4">新聞稿推廣</span>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="form-inline my-2 my-lg-0" style="justify-content: center">
        <input class="form-control mr-sm-2 w-size" placeholder="輸入標題關鍵字" v-model="search.title">
        <button class="btn btn-outline-success m-position" @click="searchByQuery(getUserPromotionList, {page: 1, ...search})">搜尋推廣新聞稿</button>
        <button class="btn btn-danger m-position" @click="resetSearch">重設搜尋</button>
      </div>
      <div class="mt-2 d-flex flex-wrap flex-md-nowrap justify-content-end">
        <div class="d-flex">
          <div class="text-secondary align-self-center" id="resultCount">
            共<span class="mr-1 ml-1">{{totalData}}</span>筆
          </div>

          <div class="ml-3">
            <select name="state" class="pr-2 pl-2 border-0 font-weight-bold text-primary mr-2"
                    v-model="search.state" @change="searchByQuery(getUserPromotionList, {page: 1, ...search})">
              <option value="">所有推廣</option>
              <option value="REVIEWING">審核中</option>
              <option value="SCHEDULED">等待開始</option>
              <option value="PROGRESS">進行中</option>
              <option value="COMPLETED">已結束</option>
              <option value="REJECT">審核未通過</option>
            </select>
          </div>
        </div>
      </div>
      <div class="table-outter wrapper">
        <table v-if="currentPageDataList.length > 0" id="dataTable" class="table table-hover table-responsive vertical-middle text-center mt-4">
          <thead>
          <tr>
            <th scope="col" class="col-75" width="14%">狀態</th>
            <th scope="col" class="col-100" width="10%">封面</th>
            <th scope="col" class="col-200" width="20">標題</th>
            <th scope="col" class="col-75" width="20%">成效</th>
            <th scope="col" class="col-75" width="13%">開始時間</th>
            <th scope="col" class="col-75" width="13%">截止時間</th>
            <th scope="col" class="col-50" width="10%">詳情</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(promotion, promotionIndex) in currentPageDataList">
            <tr :key="`${promotion.id}-1`">
              <!-- 狀態 -->
              <td class="col-75" ><span :class="getUserPromotionStateStyle(promotion.state)">{{getUserPromotionStateName(promotion.state)}}</span></td>
              <!-- 封面 -->
              <td class="col-100"><img :src="promotion.imgIconHd ? promotion.imgIconHd : ''" class="img-fluid"></td>
              <!-- 標題 -->
              <td class="col-200">{{promotion.name}}</td>
              <!-- 成效 -->
              <td class="col-75">
                <template v-if="promotion.state == 'PROGRESS' || promotion.state == 'COMPLETED'">
                  <a :href="'/promotion-report?id='+promotion.id" target="_blank">
                    <span class="badge badge-pill" :class="getUserPromotionStateBadge(promotion.state)">+ {{promotion.click? promotion.click : 0}} clicks</span>
                    <br>
                    <small class="text-muted">NT${{promotion.consumedBudget}}元</small>
                  </a>
                </template>
                <template v-else>
                  <span class="badge badge-pill badge-secondary">+ 0 clicks</span>
                  <br>
                  <small class="text-muted">NT$0元</small>
                </template>
              </td>
              <!-- 開始時間 -->
              <td class="col-75" v-if="promotion.fromTime">
                <small>{{ promotion.fromTime | timeString('YYYY-MM-DD') }}
                  <br>{{ promotion.fromTime | timeString('hh:mm:ss') }}</small>
              </td>
              <td class="col-75" v-else><small></small></td>
              <!-- 截止時間 -->
              <td class="col-75" v-if="promotion.endTime">
                <small>{{ promotion.endTime | timeString('YYYY-MM-DD') }}
                  <br>{{ promotion.endTime | timeString('hh:mm:ss') }}
                </small>
              </td>
              <td class="col-75" v-else><small></small></td>
              <!-- 詳情 -->
              <td class="col-50"><i class="icon-monitor pointIcon" @click="toggleDetail(`collapse-${promotionIndex}`, promotion.id)"></i></td>
            </tr>
            <tr :key="`${promotion.id}-2`" :ref="`collapse-${promotionIndex}`" class="collapse" data-parent="#dataTable">
              <td colspan="6">
                <div class="m-1">
                  <!-- 新聞稿編號 & 前往新聞稿 & 檢視 -->
                  <div class="mb-2 text-left">
                    <div class="text-primary">
                      <span class="mr-1">新聞稿編號</span>
                      <router-link :to="'/post/'+ promotion.postId" target="_blank">前往<i class="ml-1 icon-chevrons-right"></i></router-link>
                      <a class="ml-1 text-primary" style="cursor: pointer;"
                         @click="showModal({modalId: ModalList().PREVIEW_MODAL, payload: { type: 'userCurrentPost', postId: promotion.postId }})">檢視<i class="ml-1 icon-eye"></i></a>
                    </div>
                    <div>{{promotion.postId}}</div>
                  </div>
                  <!-- 推廣編號 -->
                  <div class="mb-2 text-left">
                    <div class="text-primary">
                      <span class="mr-1">推廣編號</span>
                      <a class="ml-1 text-primary" style="cursor: pointer;"
                         @click="showModal({modalId: ModalList().PROMOTION_MODAL, payload: { step: 1, mode: 'display',promotion: promotion}})">檢視<i class="ml-1 icon-eye"></i></a>
                    </div>
                    <div>{{promotion.id}}</div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
        <div v-else id="result-hint" class="d-flex justify-content-center">
          <template v-if="isEmptyList">尚無資料</template>
          <template v-else><loading/></template>
        </div>
      </div>
      <!-- Pagination -->
      <ul v-if="currentPageDataList.length > 0" class="pagination justify-content-center">
        <li class="page-item">
          <a class="page-link" @click="currentPage != 1 ? getPreviousPage(currentPage, getUserPromotionList):''"><i class="fa fa-angle-left"></i></a>
        </li>
        <template v-for="page in range">
          <li class="page-item" :class="{'active': page === currentPage}" :key="page">
            <a class="page-link" @click="currentPage != page ? toTargetPage(page, getUserPromotionList):''" style="cursor: default;">{{page}}</a>
          </li>
        </template>
        <li class="page-item">
          <a class="page-link" @click="currentPage != totalPage ? toNextPage(currentPage, totalPage, getUserPromotionList):''"><i class="fa fa-angle-right"></i></a>
        </li>
      </ul>
      <!-- end: Pagination -->
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Loading from "@/components/common/Loading";
import { mapGetters, mapMutations } from "vuex";
import { utilMixin } from "@/mixins/utilMixin";
import { findPromotionList } from '@/service/api/internal/me/promotion';

export default {
  name: "PromotionList",
  mixins: [utilMixin],
  components: {
    Loading
  },
  data(){
    return{
      search:{
        title: '',
        state: ''
      }
    }
  },
  computed: {
    ...mapGetters([
      'getUserPromotionStateName', 'getUserPromotionStateStyle', 'getUserPromotionStateBadge'
    ])
  },
  mounted() {
    this.initSearchParam()
    this.getUserPromotionList(this.currentPage)
  },
  methods: {
    ...mapMutations(['showModal']),
    initSearchParam(){
      let search = JSON.parse(JSON.stringify(this.search))
      this.search = this.initSearchData(search)
      if(!this.search.state){
        this.search.state= ''
      }
    },
    async getUserPromotionList(page){
      let currentPage = this.checkPageValid(page, this.currentPage, this.totalPage)
      this.currentPage = currentPage
      let query = this.getSearchQuery(this.currentPage, this.show, this.search)
      let response = await findPromotionList(query)
      this.currentPageDataList = response.data
      this.totalPage = response.totalPage
      this.totalData = response.totalElement
      if(this.currentPageDataList.length == 0){
        this.isEmptyList = true
      }
    },
    resetSearch(){
      let state = this.search.state
      this.search = {}
      this.search.state = state
      this.search.title = ""
      if(window.location.search) {
        this.searchByQuery(this.getUserPromotionList, {page: 1, ...this.search})
      }
    },
    toggleDetail(refId){
      if(this.$refs[refId]){
        $(this.$refs[refId]).collapse('toggle')
      }
    }
  }
}
</script>

<style scoped>
.pointIcon{
  cursor: pointer;
  color: rgb(24 135 255)
}

.table-outter {
  overflow-x: scroll;
}

.col-200 {
  min-width: 200px;
}

.col-100 {
  min-width: 100px;
}

.col-75 {
  min-width: 75px;
}

.col-50 {
  min-width: 50px;
}

@media only screen and (max-width: 576px) {
  .m-position{
    margin-top: 0.5rem!important;
    margin-bottom: 0.5rem!important;
  }
}

@media only screen and (min-width: 576px) {
  .w-size {
    width: 50% !important;
  }

  .m-position{
    margin-bottom: 0 !important;
  }
}
</style>
