import axios from '../../../plugins/axios.js';

// Login 相關的 api

let baseUrl = '/'

if(process.env.VUE_APP_baseUrl) {
    baseUrl = process.env.VUE_APP_baseUrl
}

baseUrl += 'login'


// check is login alive
export function isLoginAlive() {
    return axios.get(`${baseUrl}/check`).then(res=>res.data)
}

// get aotter account login url
export function getAotterAccountLoginUrl() {
    return axios.get(`${baseUrl}/url`).then(res=>res.data)
}
