<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-12">
          <span class="h4">全站設定</span>
          <div class="mt-2 d-flex flex-wrap flex-md-nowrap justify-content-end">
            <div class="d-flex">
              <div class="text-secondary align-self-center" id="resultCount">
                上次編輯時間 <span class="mr-1 ml-1">{{siteConfig.modifyTime | timeString('YYYY-MM-DD HH:mm:ss')}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <json-forms v-bind="{ data: siteConfig, schema, uischema, ...jsonformsConfig }" @change="onChange" />
      <div class="row">
        <div class="col-md-6">
          <button @click="showSiteConfigConfirmModal('cancel')" class="btn btn-lg btn-block btn-secondary mt-4">取消</button>
        </div>
        <div class="col-md-6">
          <button @click="showSiteConfigConfirmModal('save')" class="btn btn-lg btn-block mt-4" :disabled="!isDataValid">儲存</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { JsonForms } from '@jsonforms/vue2'
import { useJsonforms } from '@aotter/jsonforms-aotter-ui-renderers'
import { mapMutations } from "vuex";
import ModalList from "@/const/modal";
import { findSiteConfig } from "@/service/api/internal/admin/siteConfig";

const jsonformsConfig = useJsonforms()
const schema = {
  type: 'object',
  required: ['name','description','contactEmail','tou','privacyPolicy','publishPolicy'],
  properties: {
    name: {
      type: 'string',
      title: '網站名稱',
      description: '顯示於瀏覽器頁籤',
    },
    description: {
      type: 'string',
      title: '網站描述',
      description:'SEO描述(Google關鍵字搜尋)'
    },
    contactEmail: {
      type: 'string',
      title: '客服信箱'
    },
    tou: {
      type: 'string',
      title: '服務條款網址'
    },
    privacyPolicy: {
      type: 'string',
      title: '隱私權政策網址'
    },
    publishPolicy: {
      type: 'string',
      title: '刊登政策'
    }
  }
}
const uischema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Control',
      scope: '#/properties/name',
      options: {
        placeholder: '顯示於瀏覽器頁籤',
      }
    },
    {
      type: 'Control',
      scope: '#/properties/description',
      options: {
        multi: true,
        placeholder: 'SEO描述(Google關鍵字搜尋)'
      }
    },
    {
      type: 'Control',
      scope: '#/properties/contactEmail',
      options: {
        placeholder: "聯絡我們"
      }
    },
    {
      type: 'Control',
      scope: '#/properties/tou',
      options: {
        placeholder: "服務條款網址"
      }
    },
    {
      type: 'Control',
      scope: '#/properties/privacyPolicy',
      options: {
        placeholder: "隱私權政策網址"
      }
    },
    {
      type: 'Control',
      scope: '#/properties/publishPolicy',
      options: {
        multi: true,
        placeholder: "刊登政策"
      }
    }
  ]
}

export default {
  name: 'SiteConfig',
  components: { JsonForms },
  data(){
    return{
      isInit: false,
      isChanged: false,
      siteConfig: {},
      jsonformsConfig,
      schema,
      uischema,
    }
  },
  mounted(){
    this.getSiteConfig()
  },
  methods: {
    ...mapMutations({
      showModal:'showModal'
    }),
    async getSiteConfig(){
      await findSiteConfig().then(result =>{
        this.siteConfig = result
        this.$nextTick(() => {
          this.isInit = true
        })
      })
    },
    showSiteConfigConfirmModal(type){
      if(this.isChanged){
        this.showModal({
          modalId: ModalList.SITE_CONFIG_CONFIRM_MODAL,
          payload: {
            type: type,
            siteConfig: this.siteConfig,
            execFunc: this.getSiteConfig
          }
        })
      }
    },
    onChange(event) {
      if(this.isInit){
        this.isChanged = true
        this.siteConfig = event.data
      }
    }
  },
  computed:{
    isDataValid() {
      const validate = this.jsonformsConfig.ajv.compile(this.schema)
      const isValid = validate(this.siteConfig)
      return isValid
    }
  }
}
</script>

<style scoped>

</style>
