var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:_vm.modalId,staticClass:"modal fade",attrs:{"role":"dialog","aria-labelledby":"myModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-confirm"},[_c('div',{staticClass:"modal-content"},[_vm._m(0),_c('div',{staticClass:"modal-body"},[_c('form',{staticClass:"form-validate"},[_c('div',{staticClass:"form-group col-md-12"},[_c('file-uploader',{attrs:{"spec":{
                sizeLimit: 5000,
                title: '預設封面',
                isCrop: true,
                autoCropWidth: 1200,
                autoCropHeight: 628,
                fixedNumber: [1200, 628],
                frameWidth: 100
              },"prev-modal-id":_vm.modalId},on:{"hide-prev-modal":_vm.hide},model:{value:(_vm.croppedCover),callback:function ($$v) {_vm.croppedCover=$$v},expression:"croppedCover"}})],1),_c('div',{staticClass:"modal-footer border-0"},[_c('div',{staticClass:"text-danger"},[_vm._v("建議大小 1200 * 628 (5 MB 內)")]),_c('button',{staticClass:"btn btn-info",attrs:{"type":"button"},on:{"click":_vm.hide}},[_vm._v("取消")]),_c('button',{staticClass:"btn",attrs:{"type":"button"},on:{"click":_vm.save}},[_vm._v("確定")])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-header pb-0 text-bold"},[_c('div',{staticClass:"mb-3",staticStyle:{"font-size":"1.3rem"},attrs:{"id":"myModalLabel"}},[_vm._v("設定預設封面")])])}]

export { render, staticRenderFns }