<template>
  <div :ref="modalId" class="modal fade" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header pb-0 text-bold">
          <div style="font-size: 1.3rem;" class="mb-3">詳細資訊</div>
        </div>

        <div class="modal-body">
          <div class="mb-2">
            <div class="text-primary text-bold">Client Id</div>
            <div>{{ clientId }}</div>
          </div>
          <div class="mb-2">
            <div class="text-primary text-bold">Client Secret</div>
            <div class="text-break">{{ clientSecret }}</div>
          </div>
        </div>

        <div class="modal-footer border-0">
          <button type="button" class="btn btn-danger" @click="regenerate">重設</button>
          <button type="button" class="btn btn-secondary" @click="hide">關閉</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import ModalList from "@/const/modal";
import { mapMutations } from "vuex";

export default {
  name: "ClientInfoModal",
  data(){
    return {
      modalId: ModalList.CLIENT_INFO_MODAL,
      clientId: '',
      clientSecret: '',
      id: '',
      name: '',
      execFunc: null
    }
  },
  methods: {
    ...mapMutations({
      showModal:'showModal'
    }),
    show(data){
      this.clientId = data.clientId ? data.clientId : ''
      this.clientSecret = data.clientSecret ? data.clientSecret : ''
      this.id = data.id ? data.id : ''
      this.name = data.name ? data.name : ''
      this.execFunc = data.execFunc ? data.execFunc : null

      $(this.$refs[this.modalId]).modal('show')
    },
    hide(){
      $(this.$refs[this.modalId]).modal('hide')
    },
    regenerate(){
      this.hide()
      this.showModal({modalId: ModalList.CLIENT_ID_REGENERATE_CONFORM_MODAL,
        payload: { id: this.id, name: this.name, execFunc: this.execFunc }})
    }
  }
}
</script>

<style scoped>

</style>
