export default {
    CHECK_MODAL: "check-modal",
    DEFAULT_COVER_MODAL: "default-cover-modal",
    CROPPIE_MODAL: "croppie-modal",
    CROPPER_MODAL: "cropper-modal",
    PROMOTION_MODAL: "promotion-modal",
    PROMOTION_REJECT_MODAL: "promotion-reject-modal",
    PROMOTION_APPROVE_MODAL: "promotion-approve-modal",
    PROMOTION_UPDATE_CONFIRM_MODAL: "promotion-update-confirm-modal",
    PREVIEW_MODAL: "preview-modal",
    LOADING_MODAL: "loading-modal",
    SUCCESS_MODAL: "success-modal",
    FAIL_MODAL: "fail-modal",
    POST_DELETE_MODAL: "post-delete-modal",
    TRANSACTION_RESULT_MODAL: "transaction-result-modal",
    TRANSACTION_PENDING_MODAL: "transaction-pending-modal",
    CLIENT_CREATE_MODAL: "client-create-modal",
    CLIENT_EDIT_MODAL: "client-edit-modal",
    CLIENT_INFO_MODAL: "client-info-modal",
    CLIENT_DELETE_MODAL: "client-delete-modal",
    CLIENT_ID_REGENERATE_CONFORM_MODAL: "client-id-regenerate-confirm-modal",
    USER_PREVIEW_MODAL: "user-preview-modal",
    UPDATE_ADMIN_ROLE_MODAL: "update-admin-role-modal",
    UPDATE_USER_STATE_MODAL: "update-user-state-modal",
    UPDATE_USER_IDENTIFY_VERIFY_MODAL: "update-user-identify-verify-modal",
    MANUAL_SELECTED_MODAL: "manual-selected-modal",
    MANUAL_SELECTED_DELETE_MODAL: "manual-selected-delete-modal",
    SITE_CONFIG_CONFIRM_MODAL: "site-config-confirm-modal",

}
