import axios from 'axios'


axios.defaults.baseURL = window.location.origin; // 域名

// Error Handle
const errorHandle = (response) => {
    switch (response.status) {
        case 400:
            break;
        case 401: {
            let loginUrl = window.location.origin
            if (response.headers.login) {
                loginUrl = response.headers.login
            }
            document.location = loginUrl
            break;
        }
        default:
            break;
    }
}

// doing something with the response
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const {response} = error;
        if (response) {
            // 成功發出請求且收到 response, 但有 error
            errorHandle(response);
            return response;
        } else {
            // 成功發出請求但沒收到 response
            return Promise.reject(error);
        }
    }
);

export default axios
