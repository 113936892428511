const user = {
    namespaced: true,
    state: {
        userTab: {
            postEdit: {
                tabName: "新增新聞稿",
                pathName: "PostEdit",
                path: '/me/post-edit'
            },
            postList: {
                tabName: "新聞稿管理",
                pathName: "PostList",
                path: '/me/post-list'
            },
            info: {
                tabName: "資料設定",
                pathName: "UserInfo",
                path: '/me/info'
            },
            promotionList: {
                tabName: "新聞稿推廣",
                pathName: "PromotionList",
                path: '/me/promotion-list'
            },
            transactionList: {
                tabName: "我的訂單管理",
                pathName: "TransactionList",
                path: '/me/transaction-list'
            },
        }
    },
    getters: {
        getUserTabName: (state) => (tab) => {
            let name = ''
            for (const [key, value] of Object.entries(state.userTab)) {
                if(key == tab){
                    name =  value.tabName
                }
            }
            return name
        },
    }
}

export default {
    user
}
