<template>
  <div>
    <div v-if="type == 'publish'"  class="container mt-5" style="min-height: 400px">
      <div v-html="policy.publishPolicy"></div>
    </div>
    <div v-if="type == 'tou'">
      <iframe frameborder="0" style="min-height: 800px" :src="policy.touPolicy"></iframe>
    </div>
    <div v-if="type == 'privacy'" class="second-row">
      <iframe frameborder="0" style="min-height: 800px" :src="policy.privacyPolicy"></iframe>
    </div>
  </div>
</template>

<script>
import Const from "@/const";
import { findAllPolicy } from '@/service/api/public';

export default {
  name: "PublicPolicy",
  data(){
    return{
      policy:{}
    }
  },
  computed: {
    type(){
      let pathType = this.$route.params.type
      let type = ""

      switch(pathType){
        case Const.TYPE_PUBLISH:
          type = Const.TYPE_PUBLISH
          break
        case Const.TYPE_TOU:
          type = Const.TYPE_TOU
          break
        case Const.TYPE_PRIVACY:
          type = Const.TYPE_PRIVACY
          break
        default:
          type = Const.TYPE_PUBLISH
      }
      return type
    }
  },
  created() {
    this.getPolicyContent()
  },
  watch:{
    '$route.params.type':{
      handler: function() {
        window.scrollTo(0, 0);
      },
      deep: true,
      immediate: true
    }
  },
  methods:{
    async getPolicyContent(){
      await findAllPolicy().then(policy =>{
        this.policy = policy
      })
    }
  }
}
</script>

<style scoped>

</style>
