<template>
  <div>
    <section id="page-content" class="sidebar-both">
      <div class="container" style="max-width: 1500px">
        <div class="row">
          <!-- Sidebar-->
          <div class="sidebar col-lg-3">
            <side-bar-services/>
          </div>
          <!-- end: Sidebar-->
          <!-- content -->
          <div class="content" :class="tab == Const().TYPE_INFO ? 'col-lg-6' : 'col-lg-9'">
            <router-view/>
          </div>
          <!-- end: content -->
          <!-- Sidebar-->
          <div v-if="tab == Const().TYPE_INFO" class="sidebar col-lg-3">
            <div class="widget">
              <h4 class="widget-title"><i class="icon-toggle-left"></i> 隱私權說明</h4>
              <p>您所填寫的資訊將會公開於文章中，請詳實填寫並確認資訊的正確性。請確保您發布的新聞稿內容屬實。若貴單位涉及散布不實資訊或任何不法行為，平台將保留追訴權，並配合相關調查單位提供貴單位的相關資訊。</p>
            </div>
            <!-- 廣告版位 -->
            <div class="widget">
              <h4 class="widget-title">贊助</h4>
              <!-- adset-rightsidebar-01 -->
              <div class="adset-rightsidebar">
                <div :id="selectorId" :data-place="uuid"></div>
              </div>
            </div>
            <!-- 廣告版位 -->
          </div>
          <!-- end: sidebar-->
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import SideBarServices from '@/components/internal/user/SideBarServices'
import Const from "@/const";

export default {
  name: "UserManagement",
  components: {
    SideBarServices,
  },
  mounted() {
    if(Const.PROD_SERVER_URL_BASE.includes(window.location.origin)) {
      let selector = `#${this.selectorId}`;
      let invokeScript = document.createElement("script");
      invokeScript.innerHTML = `
              AotterTrek('suprAd', {
                  selector: '${selector}',
                  place: '${this.uuid}',
                  onAdLoad: () => {
                      console.log("${this.selectorId} success")
                  },
                  onAdFail: () => {
                      console.log("${this.selectorId} fail")
                  }
              })`

      document.body.appendChild(invokeScript);
    }
  },
  computed: {
    tab(){
      return this.$route.meta.tab? this.$route.meta.tab: Const.TYPE_INFO
    },
    uuid(){
      if(this.$isMobile()){
        return Const.ADMIN_SIDEBAR_RIGHT_MOBILE_AD_UUID
      }else {
        return Const.ADMIN_SIDEBAR_RIGHT_DESKTOP_AD_UUID
      }
    },
    selectorId(){
      if(this.$isMobile()){
        return Const.ADMIN_SIDEBAR_RIGHT_MOBILE_AD
      }else {
        return Const.ADMIN_SIDEBAR_RIGHT_DESKTOP_AD
      }
    }
  },
  methods:{
    Const(){
      return Const
    }
  }
}
</script>

<style scoped>

</style>
