<template>
  <div :ref="modalId" class="modal fade" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header pb-0 text-bold">
          <div style="font-size: 1.3rem;" class="mb-3">{{ title }}</div>
        </div>

        <div class="modal-body">
          <validation-observer v-slot="{invalid}">
            <div class="form-validate">
              <template v-if="isTagType">
                <validation-provider name="tag" rules="required" v-slot="{ errors }" slim>
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <label for="tag"><small class="m-1 text-red">*</small>標籤</label>
                      <input id="tag" type="text" class="form-control" v-model="tag" placeholder="ex: 振興券" required/>
                      <div class="col-sm-12 text-danger has-error">{{ errors[0] }}</div>
                      <div class="mt-1">熱門標籤：
                        <template v-for="(tag, index) in hotTagList">
                          <span :key="index">{{tag.name}}{{isShowComma(index)?'、':''}}</span>
                        </template>
                      </div>
                    </div>
                  </div>
                </validation-provider>
              </template>
              <template v-else>
                <validation-provider name="postId" rules="required" v-slot="{ errors }" slim>
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <label for="postId"><small class="m-1 text-red">*</small>新聞稿ID</label>
                      <input id="postId" type="text" class="form-control" v-model="postId" placeholder="ex: 62d764dbf998a880cdd9d1a9" required/>
                      <div class="col-sm-12 text-danger has-error">{{ errors[0] }}</div>
                    </div>
                  </div>
                </validation-provider>
              </template>

              <div class="form-row">
                <validation-provider name="startTime" :rules="`required${isCreateMode ? '|afterNow' : ''}`" v-slot="{ errors }" slim>
                  <div class="form-group col-md-6">
                    <label for="startTime"><small class="m-1 text-red">*</small>開始時間</label>
                    <input id="startTime" type="datetime-local" class="form-control" v-model="formatStartTime" :min="minStartTime" required/>
                    <div class="col-sm-12 text-danger has-error">{{ errors[0] }}</div>
                  </div>
                </validation-provider>
                <validation-provider name="endTime" rules="required|isAfter:@startTime" v-slot="{ errors }" slim>
                  <div class="form-group col-md-6">
                    <label for="endTime"><small class="m-1 text-red">*</small>結束時間</label>
                    <input id="endTime" type="datetime-local" class="form-control" v-model="formatEndTime" :min="minEndTime" required/>
                    <div class="col-sm-12 text-danger has-error">{{ errors[0] }}</div>
                  </div>
                </validation-provider>
              </div>
              <div class="modal-footer border-0">
                <button type="button" class="btn btn-info" @click="hide">取消</button>
                <button type="button" class="btn btn-primary" @click="save" :disabled="invalid">存檔</button>
              </div>
            </div>
          </validation-observer>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import ModalList from "@/const/modal";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import RuleGroup from "@/rules/ruleGroup";
import moment from 'moment';
import { mapGetters, mapMutations } from "vuex";
import { utilMixin } from "@/mixins/utilMixin";
import { getHotTagList } from '@/service/api/public';
import { createManualSelected, updateManualSelected } from "@/service/api/internal/admin/manualSelected";

Object.keys(RuleGroup).forEach((rule) => {
  extend(rule, RuleGroup[rule]);
});

export default {
  name: "ManualSelectedModal",
  mixins: [utilMixin],
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data(){
    return {
      modalId: ModalList.MANUAL_SELECTED_MODAL,
      mode: 'create',
      type: '',
      id: '',
      tag: '',
      postId: '',
      startTime: '',
      formatStartTime: '',
      endTime: '',
      formatEndTime: '',
      minStartTime: null,
      now: null,
      execFunc: null,
      hotTagList:[]
    }
  },
  methods: {
    ...mapMutations({
      showModal: 'showModal'
    }),
    show(data){
      if(this.isNotValidType(data.type)){
        return
      }
      this.mode = this.isValidMode(data.mode) ? data.mode : 'create'
      this.type = data.type
      this.id = data.id ? data.id : ''
      this.startTime = data.startTime ? data.startTime : null
      this.formatStartTime = this.getFormatTime(this.startTime)
      this.endTime = data.endTime ? data.endTime : null
      this.formatEndTime = this.getFormatTime(this.endTime)
      this.now = this.getFormatTime()
      this.minStartTime = this.isCreateMode ? this.now : this.formatStartTime
      this.execFunc = data.execFunc ? data.execFunc : null

      if(this.isTagType){
        this.tag = data.name ? data.name : ''
        this.getCurrentHotTagList()
      } else {
        this.postId = data.postId ? data.postId : ''
      }
      $(this.$refs[this.modalId]).modal('show')
    },
    hide(){
      $(this.$refs[this.modalId]).modal('hide')
    },
    getFormatTime(time){
      let date = new Date()
      if(time){
        date = new Date(time)
      }
      return moment(date).format('YYYY-MM-DD[T]HH:mm')
    },
    save(){
      if(this.isCreateMode){
        this.create()
      } else if(this.isEditMode){
        this.update()
      }
    },
    async create(){
      await createManualSelected(this.type, this.getBodyData())
          .then(res => {
            this.hide()
            if(res.status == 200){
              if(this.execFunc){
                this.execFunc()
                let payload
                if(this.isTagType){
                  payload = { maxCountDownSec: 3, content: `已成功新增標籤：${this.tag} 為${this.getManualSelectedName(this.type)}` }
                }else {
                  payload = { maxCountDownSec: 3, content: `已成功新增新聞稿編號：${this.postId} 為${this.getManualSelectedName(this.type)}` }
                }
                this.showSuccessModal(payload)
              }
            }else{
              this.showErrorModal()
            }
          })
    },
    async update(){
      await updateManualSelected(this.type, this.id, this.getBodyData())
        .then(res => {
          this.hide()
          if(res.status == 200){
            if(this.execFunc){
              this.execFunc()
              let payload
              if(this.isTagType){
                payload = { maxCountDownSec: 3, content: `${this.getManualSelectedName(this.type)} 已成功修改標籤：${this.tag}` }
              }else {
                payload = { maxCountDownSec: 3, content: `${this.getManualSelectedName(this.type)} 已成功修改新聞稿編號：${this.postId}` }
              }
              this.showSuccessModal(payload)
            }
          }else{
            this.showErrorModal()
          }
        })
    },
    async getCurrentHotTagList(){
      await getHotTagList(30, 20).then(list =>{
        this.hotTagList = list
      })
    },
    isShowComma(index){
      return index < this.hotTagList.length -1
    },
    getBodyData(){
      let data = {
        startTime: new Date(this.formatStartTime).getTime(),
        endTime: new Date(this.formatEndTime).getTime()
      }

      if(this.isTagType){
        data.tag = this.tag
      } else {
        data.postId = this.postId
      }

      return JSON.stringify(data)
    },
    isValidMode(mode){
      let isValid = false

      switch (mode) {
        case 'create':
        case 'edit':
          isValid = true
          break
        default:
          isValid = false
      }

      return isValid
    },
    isNotValidType(type){
      let isValid = false

      switch(type){
        case 'pin':
        case 'selected':
        case 'special-selected':
        case 'tag':
          isValid = true
          break
        default:
          isValid = false
      }

      return !isValid
    }
  },
  computed: {
    ...mapGetters(['getManualSelectedName']),
    isTagType(){
      return this.type == 'tag'
    },
    title(){
      let title = ""
      if(this.isCreateMode){
        title = `新增${this.getManualSelectedName(this.type)}`
      } else if(this.isEditMode){
        title = `編輯${this.getManualSelectedName(this.type)}`
      }

      return title
    },
    isCreateMode(){
      return this.mode == 'create'
    },
    isEditMode(){
      return this.mode == 'edit'
    },
    minEndTime(){
      return this.now && this.formatStartTime ? this.formatStartTime : this.now
    }
  }
}
</script>

<style scoped>

</style>
