<template>
  <div>
    <section id="page-content" class="sidebar-left">
      <div class="container" >
        <h1>糟糕！</h1>
        <h2>404 此頁面不存在</h2>
        <router-link to="/" class="btn btn-lg">返回首頁</router-link>
      </div>
    </section>
  </div>

</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>

</style>