<template>
  <div :ref="modalId" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content p-25" style="font-size: 1.2rem;">
        <div class="pb-2">
          <div class="col">訂單編號</div>
          <div class="col font-weight-500" id="orderId">{{data.orderId}}</div>
        </div>
        <div class="pb-2">
          <div class="col">購買服務</div>
          <div class="col font-weight-500" id="txnItem">{{data.item}}</div>
        </div>
        <div class="pb-2">
          <div class="col">金額</div>
          <div class="col font-weight-500" id="txnAmount">NT {{data.amount}} 元</div>
        </div>
        <div class="pb-2">
          <div class="col">訂單狀態</div>
          <div class="col font-weight-500" :class="getStateStyle(data.state)" id="txnState">{{getStateName(data.state)}}</div>
        </div>
        <div class="align-self-end">
          <button type="button" class="btn btn-secondary" @click="hide">確定</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import ModalList from "@/const/modal";
import { checkAotterPayStatus } from "@/service/api/internal/me/transaction";

export default {
  name: "TransactionResultModal",
  data(){
    return {
      modalId: ModalList.TRANSACTION_RESULT_MODAL,
      token: null,
      updateTransactionList: null,
      data: {
        orderId:'',
        item:'',
        amount:0,
        state:''
      }
    }
  },
  methods: {
    show(data){
      data = data ? data : {}
      this.token = data.token ? data.token : ''
      this.updateTransactionList = data.updateTransactionList ?  data.updateTransactionList : null
      if(this.token){
        this.$nextTick(function () {
          this.getTransaction()
        });
      }
      $(this.$refs[this.modalId]).modal('show')
    },
    hide(){
      this.$router.replace({'query': null});
      if(this.updateTransactionList){
        this.updateTransactionList(1)
      }
      $(this.$refs[this.modalId]).modal('hide')
    },
    async getTransaction(){
      await checkAotterPayStatus(this.token)
          .then( transaction =>{
            this.data = transaction
          })
    },
    getStateName(state){
      let stateName = ''
      switch (state) {
        case "PENDING":
          stateName = '等待付款'
          break;
        case "COMPLETED":
          stateName = '訂單成立'
          break;
        case "CANCELED":
          stateName = '訂單取消：請再重新操作一次付款流程'
          break;
        case "FAILED":
          stateName = '付款失敗：請再重新操作一次付款流程'
          break;
      }
      return stateName
    },
    getStateStyle(state){
      let style = ''
      switch (state) {
        case "PENDING":
          style = 'text-danger'
          break;
        case "COMPLETED":
          style = 'text-success'
          break;
        case "CANCELED":
          style = 'text-danger'
          break;
        case "FAILED":
          style = 'text-danger'
          break;
      }
      return style
    }
  }
}
</script>

<style scoped>

</style>
