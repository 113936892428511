<template>

  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-9">
          <span class="h4">RSS匯入管理</span>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="mt-2 d-flex flex-wrap flex-md-nowrap justify-content-between">
        <div class="ml-2 pointIcon" @click="createRSSClient()">新增</div>
        <div class="text-secondary align-self-center" id="resultCount">
          共<span class="mr-1 ml-1">{{totalData}}</span>筆
        </div>
      </div>
      <div class="table-outter wrapper">
        <table v-if="currentPageDataList.length > 0" id="dataTable" class="table table-hover table-responsive vertical-middle text-center mt-4">
          <thead>
          <tr>
            <th scope="col" class="col-50" width="20%">公開資訊</th>
            <th scope="col" class="col-75" width="35%">名稱</th>
            <th scope="col" class="col-50" width="10%">詳情</th>
            <th scope="col" class="col-50" width="10%">編輯</th>
            <th scope="col" class="col-50" width="10%">刪除</th>
            <th scope="col" class="col-100" width="30%">更新時間</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="data in currentPageDataList">
            <tr :key="data.id">
              <td class="col-50">
                <i style="font-size: 1.3rem;" class="icon-user pointIcon" @click="showRSSClient(data)"></i>
              </td>
              <td class="col-75">{{ data.name }}</td>
              <td class="col-50">
                <i class="ml-2 icon-eye pointIcon" @click="showRSSClientInfo(data)"></i>
              </td>
              <td class="col-50">
                <i class="ml-2 icon-edit pointIcon" @click="editRSSClient(data)"></i>
              </td>
              <td class="col-50">
                <i class="icon-trash-2 pointIcon" @click="deleteRSSClient(data)"></i>
              </td>
              <td class="col-100">{{ data.modifyTime | timeString('YYYY-MM-DD hh:mm:ss')}}</td>
            </tr>

          </template>
          </tbody>
        </table>
        <div v-else id="result-hint" class="d-flex justify-content-center">
          <template v-if="isEmptyList">尚無資料</template>
          <template v-else><loading/></template>
        </div>
      </div>
<!--       Pagination -->
      <ul v-if="currentPageDataList.length > 0" class="pagination justify-content-center">
        <li class="page-item">
          <a class="page-link" @click="currentPage != 1 ? getPreviousPage(currentPage, getClientList):''"><i class="fa fa-angle-left"></i></a>
        </li>
        <template v-for="page in range">
          <li class="page-item" :class="{'active': page === currentPage}" :key="page">
            <a class="page-link" @click="currentPage != page ? toTargetPage(page, getClientList):''" style="cursor: default;">{{page}}</a>
          </li>
        </template>
        <li class="page-item">
          <a class="page-link" @click="currentPage != totalPage ? toNextPage(currentPage, totalPage, getClientList):''"><i class="fa fa-angle-right"></i></a>
        </li>
      </ul>
      <!-- end: Pagination -->
    </div>
  </div>

</template>

<script>
import ModalList from "@/const/modal";
import Loading from "@/components/common/Loading";
import { utilMixin } from "@/mixins/utilMixin";
import { findClientList } from "@/service/api/internal/admin/client";

export default {
  name: "RSSList",
  mixins: [utilMixin],
  components: {
    Loading
  },
  data(){
    return {
      search: {}
    }
  },
  mounted(){
    this.initSearchParam()
    this.getClientList(this.currentPage)
  },
  methods: {
    initSearchParam(){
      let search = JSON.parse(JSON.stringify(this.search))
      this.search = this.initSearchData(search)
    },
    async getClientList(page){
      let currentPage = this.checkPageValid(page, this.currentPage, this.totalPage)
      this.currentPage = currentPage
      let query = this.getSearchQuery(this.currentPage, this.show, this.search)
      let response = await findClientList(query)
      this.currentPageDataList = response.data
      this.totalPage = response.totalPage
      this.totalData = response.totalElement
      if(this.currentPageDataList.length == 0){
        this.isEmptyList = true
      }
    },
    createRSSClient(){
      this.showModal({
        modalId: ModalList.CLIENT_CREATE_MODAL,
        payload: { execFunc: this.getClientList }
      })
    },
    showRSSClient(data){
      this.showModal({
        modalId: ModalList.USER_PREVIEW_MODAL,
        payload: data
      })
    },
    showRSSClientInfo(data){
      this.showModal({
        modalId: ModalList.CLIENT_INFO_MODAL,
        payload: {...data, execFunc: this.getClientList}
      })
    },
    editRSSClient(data){
      this.showModal({
        modalId: ModalList.CLIENT_EDIT_MODAL,
        payload: {...data, execFunc: this.getClientList}
      })
    },
    deleteRSSClient(data){
      this.showModal({
        modalId: ModalList.CLIENT_DELETE_MODAL,
        payload: {...data, execFunc: this.getClientList}
      })
    }
  }
}
</script>

<style scoped>
.pointIcon{
  cursor: pointer;
  color: rgb(24 135 255)
}

.table-outter {
  overflow-x: scroll;
}

.col-100 {
  min-width: 100px;
}

.col-75 {
  min-width: 75px;
}

.col-50 {
  min-width: 50px;
}
</style>
