<template>
  <div :ref="modalId" class="modal fade" role="dialog" aria-hidden="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header text-bold text-center">
          提示
        </div>
        <div class="modal-body row text-break">
          <i class="col-4 fas fa-exclamation fa-5x align-self-center text-center"></i>
          <div id="modalHintContent" class="col-8 align-self-center text-bold text-secondary">
            <div>是否要刪除{{getManualSelectedName(this.type)}}</div>
            <template v-if="isTagType">
              <div class="text-primary mt-2">標籤名稱</div>
              <div>{{this.tagName}}</div>
            </template>
            <template v-else>
              <div class="text-primary mt-2">新聞稿編號</div>
              {{this.postId}}
              <div class="text-primary mt-2">新聞稿標題</div>
              <div>{{this.title}}</div>
            </template>
          </div>
        </div>
        <div class="modal-footer border-0">
          <button type="button" class="btn btn-info" data-dismiss="modal" @click="hide">取消</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" @click="confirmDelete">確定</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import ModalList from "@/const/modal";
import { utilMixin } from "@/mixins/utilMixin";
import { mapGetters, mapMutations } from "vuex";
import { deleteManualSelected } from "@/service/api/internal/admin/manualSelected";

export default {
  name: "ManualSelectedDeleteModal",
  mixins: [utilMixin],
  data(){
    return {
      modalId: ModalList.MANUAL_SELECTED_DELETE_MODAL,
      id: '',
      type: '',
      postId: '',
      title: '',
      tagName: '',
      execFunc: () => {}
    }
  },
  methods: {
    ...mapMutations({
      showModal:'showModal'
    }),
    show(data){
      data = data ? data : {}
      this.id = data.id ? data.id : ''
      this.type = data.type ? data.type : ''
      this.postId = data.postId ? data.postId : ''
      this.title = data.post && data.post.title ? data.post.title : ''
      this.tagName = this.isTagType ? data.name : ''
      this.execFunc = data.execFunc ? data.execFunc : null

      $(this.$refs[this.modalId]).modal('show')
    },
    hide(){
      $(this.$refs[this.modalId]).modal('hide')
    },
    async confirmDelete(){
      await deleteManualSelected(this.type, this.id)
      .then(res =>{
            this.hide()
            if(res.status == 204){
              if(this.execFunc){
                this.execFunc()
                this.showSuccessModal({ maxCountDownSec: 3, content: `已成功刪除該${this.getManualSelectedName(this.type)}` })
              }
            }else{
              this.showErrorModal()
            }
      })
    }

  },
  computed:{
    ...mapGetters(['getManualSelectedName']),
    isTagType(){
      return this.type == 'tag'
    },
  }
}
</script>

<style scoped>

</style>
