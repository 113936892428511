<template>
<div>
  <div :ref='`${baseModalId}-${CONST.STEP_1}`' class="modal fade" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header pb-0 text-bold">
          <div style="font-size: 1.3rem;" id="myModalLabel">新聞稿推廣</div>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
          <div class="text-secondary mb-2 mt-2">透過廣告聯播網，高度曝光新聞稿，加強推廣給更多的潛在客戶</div>
        </div>
        <div class="modal-body">
          <validation-observer v-slot="{invalid}">
            <div class="form-validate" id="promotionForm">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <h5 id="postTitle" class="font-weight-bold text-center">{{ postTitle }}</h5>
                </div>
                <div class="form-group col-md-12">
                  <label>
                    <small class="m-1 text-red">*</small>推廣圖片
                    <small class="ml-1 text-gray">建議大小 1200 * 628 (150 KB 內)</small>
                  </label>
                  <file-uploader
                      v-model="data.imgMain"
                      @sync-image-url="url => { data.imgMainUrl = url }"
                      :spec="{
                        title: '推廣圖片',
                        isCrop: true,
                        autoCropWidth: 1200,
                        autoCropHeight: 628,
                        fixedNumber: [1200, 628],
                        frameWidth: 100
                      }"
                      :is-preview="isDisplayMode"
                      :prev-modal-id="baseModalId"
                      @hide-prev-modal="hide"/>
                  <div class="col-sm-12 text-danger has-error" :class="{'d-none': isImgMainExist}">請上傳圖片</div>
                </div>
                <div class="form-group col-md-12">
                  <label>
                    <small class="m-1 text-red">*</small>推廣圖片（正方形）
                    <small class="ml-1 text-gray">建議大小 300 * 300 (150 KB 內)</small>
                  </label>
                  <file-uploader
                      v-model="data.imgIconHd"
                      @sync-image-url="url => { data.imgIconHdUrl = url }"
                      :spec="{
                        title: '推廣圖片（正方形）',
                        isCrop: true,
                        autoCropWidth: 300,
                        autoCropHeight: 300,
                        fixedNumber: [300, 300],
                        frameWidth: 50
                      }"
                      :is-preview="isDisplayMode"
                      :prev-modal-id="baseModalId"
                      @hide-prev-modal="hide"/>
                  <div class="col-sm-12 text-danger has-error" :class="{'d-none': isImgIconHdExist}">請上傳圖片</div>
                </div>
                <div class="form-group col-md-12">
                  <validation-provider name="title" rules="required|promotionTitle" v-slot="{ errors }">
                    <label>
                      <small class="m-1 text-red">*</small>推廣標題
                      <small class="ml-1 text-gray">3 - 25字</small>
                    </label>
                    <input ref="title" type="text" class="form-control" name="推廣標題" placeholder="請輸入推廣標題" v-model="data.title" :disabled="isDisplayMode">
                    <div class="col-sm-12 text-danger has-error">{{ errors[0] }}</div>
                  </validation-provider>
                </div>
                <div class="form-group col-md-12">
                  <validation-provider name="summary" rules="required|promotionSummary" v-slot="{ errors }">
                    <label>
                      <small class="m-1 text-red">*</small>推廣內容
                      <small class="ml-1 text-gray">3 - 35字</small>
                    </label>
                    <textarea ref="summary" type="text" class="form-control" style="height: 60px" name="推廣內容" placeholder="請輸入推廣內容" v-model="data.summary" :disabled="isDisplayMode"></textarea>
                    <div class="col-sm-12 text-danger has-error">{{ errors[0] }}</div>
                  </validation-provider>
                </div>
                <div class="form-group col-md-12">
                  <validation-provider name="budget" rules="budget" v-slot="{ errors }">
                    <label>
                      <small class="m-1 text-red">*</small>推廣金額
                      <small class="ml-1 text-gray">範圍 NT 1,000 - 30,000 元</small>
                    </label>
                    <input type="number" class="form-control" name="推廣金額" v-model="data.budget" min="1000" max="30000" step="100" :disabled="isDisplayMode">
                    <div class="col-sm-12 text-danger has-error">{{ errors[0] }}</div>
                  </validation-provider>
                </div>
                <div class="form-group col-md-12">
                  <validation-provider ref="dayValidator" name="day" rules="day" v-slot="{ errors }">
                    <label>
                      <small class="m-1 text-red">*</small>走期
                      <small class="ml-1 text-gray">受推廣金額限制，至多 7 天</small>
                    </label>
                    <select class="form-control" name="走期" v-model="data.day" :disabled="isDisplayMode">
                      <option :value="day" v-for="(day, index) in promotionDay" :key="index" >{{day}}</option>
                    </select>
                    <div class="col-sm-12 text-danger has-error">{{ errors[0] }}</div>
                    <input class="d-none" type="text" :value="isDayValid">
                  </validation-provider>
                </div>
              </div>
              <div class="modal-footer border-0">
                <template v-if="isEditMode">
                  <span v-show="invalid" id="formErrorHint" class="font-weight-bold text-red mr-2">請再檢查看看</span>
                  <button type="button" class="btn btn-secondary" @click="hide">取消</button>
                  <button type="button" class="mr-1 btn btn-primary" @click="next" :disabled="invalid || !isImgMainExist || !isImgIconHdExist">下一步</button>
                </template>
                <template v-else-if="isDisplayMode">
                  <button type="button" class="btn btn-secondary" @click="hide">確認</button>
                </template>
                <template v-else>
                  <button type="button" class="btn btn-secondary" @click="hide">關閉</button>
                </template>
              </div>
            </div>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>

  <div :ref='`${baseModalId}-${CONST.STEP_2}`' class="modal fade" role="dialog" aria-labelledby="myPreviewModal" aria-hidden="true" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myPreviewModal">新聞稿推廣</h4>
          <button type="button" class="close" @click="hide"><span aria-hidden="true">×</span></button>
        </div>
        <div class="modal-body">
          <div class="border-0 card shadow-none">

            <div class="bg-info card-header d-flex justify-content-between text-white" style="font-size: 1.5rem">
              <div class="" style="font-size: 1.5rem"><i class="icon-user"></i></div>

              <div class="">預覽畫面</div>
              <div class=""><i class="icon-search"></i></div>
            </div>

            <div class="border border-info card-body d-flex flex-column p-0">
              <div class="mb-4" style="border: 5px solid red">
                <img id="pxnMainImg" style="width: 100%" :src="data.imgMainUrl? data.imgMainUrl: data.imgMain">
                <h3 id="pxnMainName">{{data.title}}</h3>
              </div>

              <div class="d-flex mb-2">
                <a href="https://supr.one/" target="_blank" class="w-25">
                  <div class="align-self-center">
                    <img class="img-thumbnail" src="/assets/images/suprone_logo.jpeg">
                  </div>
                </a>
                <div class="w-75 ml-2">
                  <h4 class="mb-0">Supr One 創作者平台</h4>
                  <p class="mb-1">透過 supr.one 建置各式付款訂閱機制頁吧！特別適合想要：群眾募資、建置訂閱內容服務、想要透過直播或寫文章收費、遠端補習、教學等</p>
                  <span class="text-secondary">Aotter 電獺 | 2020-10-17</span>
                </div>
              </div>

              <div class="d-flex mb-2" style="border: 5px solid red">
                <div class="w-25 align-self-center">
                  <img id="pxnIconImg" class="img-thumbnail" :src="data.imgIconHdUrl">
                </div>
                <div class="w-75 ml-2">
                  <h4 id="pxnIconName" class="mb-0">{{data.title}}</h4>
                  <p id="pxnIconText" class="mb-1">{{data.summary}}</p>
                  <span class="text-secondary">NewsBuffet 新聞稿自助吧 | {{ Date.now() | timeString('YYYY-MM-DD') }}</span>
                </div>
              </div>

              <div class="d-flex mb-2">
                <a href="https://console.supr.link/" target="_blank" class="w-25">
                  <div class="align-self-center">
                    <img class="img-thumbnail" src="/assets/images/suprlink_logo.jpeg">
                  </div>
                </a>
                <div class="w-75 ml-2">
                  <h4 class="mb-0">Supr.Link 超級連結</h4>
                  <p class="mb-1">功能超全面的縮址服務，分流、裝置判斷、資訊上鎖、自帶變數、數據分析一應俱全卻有簡單上手</p>
                  <span class="text-secondary">Aotter 電獺 | 2020-10-17</span>
                </div>
              </div>
            </div>

          </div>
          <div class="right">
            <button type="button" class="btn btn-danger" @click="hide">取消</button>
            <button type="button" class="btn btn-secondary" @click="previous">上一步</button>
            <button type="button" class="mr-1 btn btn-primary" @click="saveAndPay">前往付款</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import $ from "jquery";
import ModalList from "@/const/modal";
import FileUploader from "@/components/common/FileUploader";
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import RuleGroup from "@/rules/ruleGroup";
import { mapMutations } from "vuex";
import { createPromotionAndGetPaymentPageUrl } from '@/service/api/internal/me/promotion';
import { uploadPostRelativeImage } from '@/service/api/internal/me/media';

Object.keys(RuleGroup).forEach((rule) => {
  extend(rule, RuleGroup[rule]);
});

export default {
  name: "PromotionModal",
  components:{
    FileUploader,
    ValidationObserver,
    ValidationProvider,
  },
  data(){
    return{
      baseModalId: ModalList.PROMOTION_MODAL,
      step: '',
      mode: '',
      isShowNext: false,
      isShowPrev: false,
      postId: '',
      postTitle:'',
      data: {
        imgMain: null,
        imgMainUrl: '',
        imgIconHd: null,
        imgIconHdUrl: '',
        title: '',
        summary: '',
        budget: 1000,
        day: 2
      },
      CONST: {
        STEP_1: 1,
        STEP_2: 2,
        MODE_EDIT: "edit",
        MODE_DISPLAY: "display",
      },
      promotion:{},
      isLoadingModalOpen: false
    }
  },
  mounted() {
    this.init()
  },
  watch:{
    isDayValid(){
      this.$nextTick(this.$refs.dayValidator.validate)
    },
  },
  computed:{
    modalId(){
      let step = this.isValidStep(this.step) ? this.step : this.CONST.STEP_1

      return `${this.baseModalId}-${step}`
    },
    promotionDay(){
      let days = 7
      if(this.data.budget < 3500){
        days = parseInt(this.data.budget / 500)
      }
      return days
    },
    isDayValid(){
      return this.data.day > 0
    },
    currentMode(){
      return this.isValidMode(this.mode) ? this.mode : this.CONST.MODE_EDIT
    },
    isEditMode(){
      return this.currentMode == this.CONST.MODE_EDIT
    },
    isDisplayMode(){
      return this.currentMode == this.CONST.MODE_DISPLAY
    },
    isImgMainCoverNeedUpload(){
      return this.data.imgMain instanceof File
    },
    isImgIconHdCoverNeedUpload(){
      return this.data.imgIconHd instanceof File
    },
    isImgMainExist(){
      return this.data.imgMain != null
    },
    isImgIconHdExist(){
      return this.data.imgIconHd != null
    }
  },
  methods: {
    ...mapMutations(['showModal','hideModal']),
    parsedHtml (summary) {
      const parser = new DOMParser();
      const elem = parser.parseFromString(summary, 'text/html');
      return elem.body.firstChild.textContent;
    },
    init(){
      let modalIdList = [
          `${this.baseModalId}-${this.CONST.STEP_1}`,
          `${this.baseModalId}-${this.CONST.STEP_2}`,
      ]

      modalIdList.forEach(modalId => {
        $(this.$refs[modalId]).on('hidden.bs.modal', () => {
          if(this.isShowNext){
            window.setTimeout(this.showNext,200)
          }
          if(this.isShowPrev){
            window.setTimeout(this.showPrevious,200)
          }
        })
      })
    },
    show(data){
      data = data ? data : {}

      if(!data.showPrevData){
        this.step = this.isValidStep(data.step) ? parseInt(data.step) : this.CONST.STEP_1
        this.mode = this.isValidMode(data.mode) ? data.mode : this.CONST.MODE_EDIT

        if(!this.isShowNext && !this.isShowPrev){
          this.setPromotionData(data.promotion)
          this.$nextTick(()=>{
            this.$refs.summary.dispatchEvent(new InputEvent('input', {data: this.data.summary}))
            this.$refs.title.dispatchEvent(new InputEvent('input', {data: this.data.title}))
          })
        }

        this.isShowNext = false
        this.isShowPrev = false
      }

      $(this.$refs[this.modalId]).modal('show')
    },
    hide(){
      $(this.$refs[this.modalId]).modal('hide')
    },
    next(){
      if(this.isValidStep(this.step + 1)){
        this.isShowNext = true
        this.hide()
      } else {
        console.log("No next step")
      }
    },
    previous(){
      if(this.isValidStep(this.step - 1)){
        this.isShowPrev = true
        this.hide()
      } else {
        console.log("No previous step")
      }
    },
    showNext(){
      let data = {
        ...this.data,
        step: ++this.step
      }

      this.show(data)
    },
    showPrevious(){
      let data = {
        ...this.data,
        step: --this.step
      }

      this.show(data)
    },
    isValidStep(step){
      let isValid = false
      let stepNum = parseInt(step)

      switch(stepNum){
        case this.CONST.STEP_1:
        case this.CONST.STEP_2:
          isValid = true
          break
        default:
          isValid = false
      }

      return isValid
    },
    isValidMode(mode){
      let isValid = false

      switch(mode){
        case this.CONST.MODE_EDIT:
        case this.CONST.MODE_DISPLAY:
          isValid = true
          break
        default:
          isValid = false
      }

      return isValid
    },
    setPromotionData(promotionSrc){
      let promotion = promotionSrc ? promotionSrc : {}
      let post = promotion.post ? promotion.post : {}

      this.postTitle =  post.title ? post.title : ''
      this.data.imgMain = promotion.imgMain ? promotion.imgMain : null
      this.data.imgMainUrl = this.data.imgMain instanceof String ? this.data.imgMain : ''
      this.data.imgIconHd = promotion.imgIconHd ? promotion.imgIconHd : null
      this.data.imgIconHdUrl = this.data.imgIconHd instanceof String ? this.data.imgIconHd : ''
      this.postId = promotion.postId ? promotion.postId : ''
      this.data.title = promotion.name ? promotion.name : ''
      this.data.summary = promotion.text ? this.parsedHtml(promotion.text) : ''
      this.data.budget = promotion.budget ? promotion.budget : 1000
      this.data.day = promotion.day ? promotion.day : 2
    },
    async saveAndPay(){
      if(!this.isLoadingModalOpen){
        this.showLoadingModal()
      }

      if(this.isImgMainCoverNeedUpload){
        await this.uploadCover('imgMain', this.saveAndPay)
      } else if(this.isImgIconHdCoverNeedUpload){
        await this.uploadCover('imgIconHd', this.saveAndPay)
      } else {
        let dto = {
          name: this.data.title,
          text: this.data.summary,
          budget: this.data.budget,
          day: this.data.day,
          imgMain: this.data.imgMain,
          imgIconHd: this.data.imgIconHd
        }
        await createPromotionAndGetPaymentPageUrl(this.postId, JSON.stringify(dto))
            .then( redirectVO =>{
              let url = redirectVO.redirect
              document.location.href = url
            })
      }
    },
    async uploadCover(type,callback){
      const formData = new FormData()
      formData.append('file', this.data[type])

      await uploadPostRelativeImage(type, formData)
          .then(data => {
            if(data){
              this.data[type] = data.url
              callback()
            }
          })
    },
    showLoadingModal(){
      this.hide()

      this.showModal({
        modalId: ModalList.LOADING_MODAL,
        payload: {
          content: "將於120秒內前往付款頁面"
        }
      })

      this.isLoadingModalOpen = true
    }
  }
}
</script>

<style scoped>
.modal { overflow: auto !important; }

</style>
