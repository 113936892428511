<template>
  <div :ref="modalId" class="modal fade" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header pb-0 text-bold">
          <div style="font-size: 1.3rem;" class="mb-3">新增RSS匯入</div>
        </div>

        <div class="modal-body">
          <validation-observer v-slot="{invalid}">
            <div class="form-validate">
              <validation-provider name="name" rules="required|name" v-slot="{ errors }">
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="client"><small class="m-1 text-red">*</small>名稱<small class="ml-3 text-gray">3 - 25字</small></label>
                    <input id="client" type="text" class="form-control" name="client" v-model="name" maxlength="35" minlength="3" placeholder="請輸入名稱" required/>
                    <div class="col-sm-12 text-danger has-error">{{ errors[0] }}</div>
                  </div>
                </div>
              </validation-provider>
              <validation-provider name="userId" rules="required|userId" v-slot="{ errors }">
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="userId"><small class="m-1 text-red">*</small>使用者編號<small class="ml-1 text-gray"></small></label>
                    <input id="userId" type="text" class="form-control" name="userId" v-model="userId" pattern="[a-zA-Z0-9]{24}" placeholder="請輸入使用者編號" required/>
                    <div class="col-sm-12 text-danger has-error">{{ errors[0] }}</div>
                  </div>
                </div>
              </validation-provider>
              <div class="modal-footer border-0">
                <button type="button" class="btn btn-info" @click="hide">取消</button>
                <button type="button" class="btn btn-primary" @click="save" :disabled="invalid">確定</button>
              </div>
            </div>
          </validation-observer>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import ModalList from "@/const/modal";
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import RuleGroup from "@/rules/ruleGroup";
import { mapMutations } from "vuex";
import { utilMixin } from "@/mixins/utilMixin";
import { createClient } from "@/service/api/internal/admin/client";

Object.keys(RuleGroup).forEach((rule) => {
  extend(rule, RuleGroup[rule]);
});

export default {
  name: "ClientCreateModal",
  mixins: [utilMixin],
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data(){
    return {
      modalId: ModalList.CLIENT_CREATE_MODAL,
      modal: null,
      name: '',
      userId: '',
      execFunc: null
    }
  },
  mounted() {
    this.modal = $(this.$refs[this.modalId])
  },
  methods: {
    ...mapMutations({
      showModal:'showModal'
    }),
    show(data){
      this.name = ''
      this.userId = ''
      this.execFunc = data.execFunc ? data.execFunc : null

      this.modal.modal('show')
    },
    hide(){
      this.modal.modal('hide')
    },
    async save(){
      await createClient(JSON.stringify({
        name: this.name,
        userId: this.userId
      })).then(res => {
        this.hide()
        if(res.status == 200){
          if(this.execFunc){
            this.execFunc()
          }
          this.showSuccessModal({ maxCountDownSec: 3, content: `已成功新增 ${this.name}` })
        }else{
          this.showErrorModal()
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
