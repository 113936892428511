<template>

<div :ref="modalId" class="modal fade" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body d-flex flex-column m-2">
        <h3 class="text-info text-center" >請勿關閉視窗，請稍候</h3>
        <div v-if="data.content" class="text-secondary text-center text-bold">{{ data.content }}</div>
        <div class="d-flex flex-row justify-content-center m-2">
          <loading></loading>
        </div>
      </div>
    </div>
  </div>
</div>

</template>

<script>
import $ from "jquery";
import Loading from "@/components/common/Loading";
import ModalList from "@/const/modal";

export default {
  name: "LoadingModal",
  components: {
    Loading
  },
  data(){
    return {
      modalId: ModalList.LOADING_MODAL,
      data: {
        content: '',
      }
    }
  },
  methods: {
    show(data){
      data = data ? data : {}
      this.data.content = data.content ? data.content : ''
      $(this.$refs[this.modalId]).modal('show')
    },
    hide(){
      $(this.$refs[this.modalId]).modal('hide')
    },
  }
}
</script>

<style scoped>

</style>
