// Important! not create the same function name or data name in component

import { isLoginAlive, getAotterAccountLoginUrl } from '@/service/api/internal/login'
import { mapMutations } from "vuex";
import ModalList from "@/const/modal";
import Const from "@/const";

export const utilMixin = {
    data(){
        return {
            isLogin: false,
            loginUrl: '',
            contactEmail: '',
            totalPage: 0,
            totalData: 0,
            currentPage: 0,
            currentPageDataList: [],
            isEmptyList: false,
        }
    },
    computed:{
        isMobile(){
            return this.$isMobile()
        },
        startPage(){
            let startPage = 1
            if(this.currentPage > 5){
                startPage = this.currentPage - 4
            }
            return startPage
        },
        endPage(){
            let endPage = 1

            if(this.startPage + 9 < this.totalPage){
                endPage = this.startPage + 9
            } else {
                endPage = this.totalPage
            }
            return endPage
        },
        range(){
            let list =[]
            for(let i = this.startPage; i <= this.endPage; i++){
                list.push(i)
            }
            return list
        },
    },
    mounted(){
        this.currentPage = parseInt(this.$route.query.page ? this.$route.query.page : 1)
    },
    methods: {
        ...mapMutations(['showModal']),
        ModalList(){
            return ModalList
        },
        Const(){
            return Const
        },
        checkLoginStatusAndRedirect(targetPath, afterLoginRedirectPath){
            isLoginAlive().then( result => {
                if(result.userId){
                    this.isLogin = true
                    document.location = targetPath
                }else {
                    if(this.loginUrl){
                        this.toLoginPage(afterLoginRedirectPath)
                    }else {
                        this.getLoginUrl().then(()=>{
                            this.toLoginPage(afterLoginRedirectPath)
                        })
                    }
                }
            })
        },
        toLoginPage(redirect){
            document.cookie = 'quarkus-redirect-location=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            document.cookie = `quarkus-redirect-location=${redirect};`
            document.location = this.loginUrl
        },
        async getLoginUrl(){
            await getAotterAccountLoginUrl().then(result => {
                if(result.loginUrl){
                    this.loginUrl = result.loginUrl
                }
            })
        },
        checkPageValid(page, currentPage, totalPage){
            if(page == 0){
                currentPage = 1
            }else if(totalPage > 0 && page > totalPage){
                currentPage = totalPage
            } else if(page == undefined){
                currentPage = 1
            }else {
                currentPage = page
            }
            return currentPage
        },
        searchByQuery(getListFunc, query){
            this.isEmptyList = false
            this.currentPageDataList = []
            this.scrollToTop()
            const url = new URL(window.location);
            let queryKeyArr = []
            url.searchParams.forEach((_, key) => {
                queryKeyArr.push(key)
            });
            queryKeyArr.forEach(key => {
                url.searchParams.delete(key)
            })

            Object.keys(query ?? {}).forEach(key => {
                if(typeof query[key] == "boolean"  || query[key]){
                    url.searchParams.set(key, query[key]);
                }
            })

            history.pushState({}, "", url);

            let page = 1
            getListFunc(page)
        },
        getPreviousPage(currentPage, getListFunc){
            if(currentPage !== 1){
                this.isEmptyList = false
                this.currentPageDataList = []
                this.scrollToTop()
                let prevPage = --currentPage

                const url = new URL(window.location);
                url.searchParams.set("page", `${prevPage}`);
                history.pushState({}, "", url);

                getListFunc(prevPage)
            }
        },
        toNextPage(currentPage, totalPage, getListFunc){
            if(currentPage !== totalPage){
                this.isEmptyList = false
                this.currentPageDataList = []
                this.scrollToTop()
                let nextPage = ++currentPage

                const url = new URL(window.location);
                url.searchParams.set("page", `${nextPage}`);
                history.pushState({}, "", url);

                getListFunc(nextPage)
            }
        },
        toTargetPage(page, getListFunc){
            this.isEmptyList = false
            this.currentPageDataList = []
            this.scrollToTop()
            const url = new URL(window.location);
            url.searchParams.set("page", `${page}`);
            history.pushState({}, "", url);

            getListFunc(page)
        },
        scrollToTop() {
            window.scrollTo(0,0);
        },
        initSearchData(search){
            let data = {}
            if(search){
                Object.keys(search).forEach(key => {
                    if(this.$route.query[key]){
                        data[key] = this.$route.query[key]
                    }
                })
            }
            return data
        },
        getSearchQuery(page, show, search){
            let query = ''
            if(page){
                query += `&page=${page}`
            }
            if(show){
                query += `&show=${show}`
            }
            for (const [key, value] of Object.entries(search)) {
                if(typeof value == "boolean" || value) {
                    query += `&${key}=${value}`
                }
            }
            return query
        },
        getNewOrder(list) {
            let order = 100
            if (list.length > 0) {
                order = list[0].order + 100
            }
            return order
        },
        async moveUpItem(type, item, saveItemFunc, reOrderListFunc, getListFunc){
            let targetIndex = this.currentPageDataList.indexOf(item)
            if (targetIndex <= 0) {
                return
            } else {
                //start count newOrder
                let newOrder
                let diff
                if (targetIndex == 1) {
                    newOrder = this.currentPageDataList[0].order + 100
                } else {
                    let beforeOneOrder = this.currentPageDataList[targetIndex - 1].order
                    let beforeTwoOrder = this.currentPageDataList[targetIndex - 2].order
                    diff = Math.floor((beforeTwoOrder - beforeOneOrder) / 2)
                    newOrder = diff + beforeOneOrder
                }
                //get newOrder and save item
                await saveItemFunc(type, item, newOrder, getListFunc)
                // reOrder all order by type
                if (diff <= 2) {
                    await reOrderListFunc(type)
                }
            }
        },
        async moveDownItem(type, item, saveItemFunc, reOrderListFunc, getListFunc){
            let targetIndex = this.currentPageDataList.indexOf(item)
            let lastIndex = this.currentPageDataList.length -1
            if (targetIndex == lastIndex || targetIndex < 0) {
                return
            } else {
                //start count newOrder
                let newOrder
                let afterOneOrder = this.currentPageDataList[targetIndex + 1].order
                let diff
                if (targetIndex == lastIndex - 1) {
                    diff = Math.floor((afterOneOrder) / 2)
                    newOrder = diff
                } else {
                    let afterTwoOrder = this.currentPageDataList[targetIndex + 2].order
                    diff = Math.floor((afterOneOrder - afterTwoOrder) / 2)
                    newOrder = diff + afterTwoOrder
                }
                //get newOrder and save item
                await saveItemFunc(type, item, newOrder, getListFunc)
                // reOrder all order by type
                if (diff <= 2) {
                    await reOrderListFunc(type)
                }
            }
        },
        showErrorModal(){
            this.showModal({
                modalId: ModalList.FAIL_MODAL
            })
        },
        showSuccessModal(data){
            this.showModal({
                modalId: ModalList.SUCCESS_MODAL,
                payload: data
            })
        }
    },
    filters: {
        activeString(active){
            return active ? '是' : ''
        }
    }
}
