<template>
  <div :ref="modalId" class="modal fade" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header text-bold text-center">
          即將更新推廣內容
        </div>
        <div class="modal-body row text-break">
          <i class="col-4 fas fa-exclamation fa-5x align-self-center text-center"></i>
          <div id="modalHintContent" class="col-8 align-self-center text-bold text-secondary">
            <div>確定要以管理員權限更新推廣內容嗎？</div>
          </div>
        </div>
        <div class="modal-footer border-0">
          <button type="button" class="btn btn-info" @click="hide">取消</button>
          <button type="button" class="btn btn-primary" @click="updatePromotion">確定</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import $ from "jquery";
import ModalList from "@/const/modal";
import { utilMixin } from "@/mixins/utilMixin";
import { uploadPostRelativeImage } from "@/service/api/internal/me/media";
import { updatePromotion } from "@/service/api/internal/admin/promotion";

export default {
  name: "PromotionUpdateConfirmModal",
  mixins: [utilMixin],
  data(){
    return {
      modalId: ModalList.PROMOTION_UPDATE_CONFIRM_MODAL,
      type: '',
      updateData: {},
      execFunc: null
    }
  },
  computed:{
    ...mapGetters(['getTrekIdName']),
    isImgMainCoverNeedUpload(){
      return this.updateData.imgMain instanceof File
    },
    isImgIconHdCoverNeedUpload(){
      return this.updateData.imgIconHd instanceof File
    },
  },
  methods: {
    ...mapMutations({
      showModal:'showModal'
    }),
    show(data){
      this.type = data.type ? data.type : ''
      this.updateData = data.updateData ? data.updateData : {}
      this.execFunc = data.execFunc ? data.execFunc : null

      $(this.$refs[this.modalId]).modal('show')
    },
    hide(){
      $(this.$refs[this.modalId]).modal('hide')
    },
    async updatePromotion(){
      if(this.type == 'content'){
        if(this.isImgMainCoverNeedUpload){
          await this.uploadCover('imgMain',this.updatePromotion)
          return
        } else if(this.isImgIconHdCoverNeedUpload){
          await this.uploadCover('imgIconHd', this.updatePromotion)
          return
        }
      }
      await updatePromotion(this.type, this.updateData.id, JSON.stringify(this.updateData))
        .then(res =>{
          this.hide()
          if(res.status == 200){
            if(this.execFunc){
              this.execFunc()
              let content = ''
              switch (this.type) {
                case 'content':
                  content = `已修改推廣內容 ${this.updateData.name}`
                  break;
                case 'info':
                  content = `已更新${this.getTrekIdName(this.updateData.field)}`
              }
              this.showSuccessModal({ maxCountDownSec: 3, content: content })
            }
          }else{
            this.showErrorModal()
          }
        })
    },
    async uploadCover(type,callback){
      const formData = new FormData()
      formData.append('file', this.updateData[type])

      await uploadPostRelativeImage(type, formData)
          .then(data => {
            if(data){
              this.updateData[type] = data.url
              callback()
            }
          })
    },
  }
}
</script>

<style scoped>

</style>
